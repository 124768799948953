import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {MedicalService} from "../../../../../@core/medical.service";
import {
	DynamicFormControlModel,
	DynamicFormLayout,
	DynamicFormService
} from "@ng-dynamic-forms/core";
import {FormValueBuilder} from "../../../../partial/forms/form-tamplate";
import {FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {DynamicRadioGroupModel} from "../../../../../../@util/ui-material/form-controls";

@Component({
	selector: 'app-source-variation',
	templateUrl: './source-variation.component.html',
	styleUrls: ['./source-variation.component.css']
})
export class SourceVariationComponent implements OnInit {
	title = 'New Medical Record';
	formGroup: FormGroup;
	formModel: DynamicFormControlModel[];
	formLayout: DynamicFormLayout;
	form = {
		type: DynamicRadioGroupModel({
			id: 'type',
			label: '',
			value: 'DOCTOR',
			options: [
				{value: 'DOCTOR', label: 'DOCTOR'},
				{value: 'HOSPITAL', label: 'HOSPITAL'},
				{value: 'OTHER', label: 'OTHER'},
			], editableFor: ["CLIENT", "EXECUTIVE_STAFF"]
		})
	};

	constructor(private formService: DynamicFormService, private medicalService: MedicalService,
				@Inject(MAT_DIALOG_DATA) private sslgId: string,
				private dialogRef: MatDialogRef<SourceVariationComponent>, private router: Router) {
		this.formModel = FormValueBuilder.buildFormModel(this.form);
		this.formGroup = formService.createFormGroup(this.formModel);
	}

	async ngOnInit() {

	}

	onChange($event) {

	}

	closeVisitForm() {
		this.dialogRef.close();
	}

	saveVisitForm() {

		switch (this.form.type.value) {
			case 'DOCTOR':
				this.router.navigate([`/claimants/${this.sslgId}/visit-medical-source/DOCTOR`]);
				break;
			case 'HOSPITAL':
				this.router.navigate([`/claimants/${this.sslgId}/visit-medical-source/HOSPITAL`]);
				break;
			case 'OTHER':
				this.router.navigate([`/claimants/${this.sslgId}/visit-medical-source/OTHER`]);
				break;
		}
		this.dialogRef.close();
	}

	getUrl() {
		return this.router.url.indexOf('?') !== -1 ?
			this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
	}
}
