import { HttpClient } from '@angular/common/http';
import * as conf from '../@config/api';
import { UserInfoService } from "./user-info.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user-info.service";
export class DashboardService {
    constructor(http, userInfoService) {
        this.http = http;
        this.userInfoService = userInfoService;
        this.config = conf.DASHBOARD_CONFIG;
    }
    getCardsInfo() {
        return this.http.get(this.config.header.cardInfo);
    }
    getInfo() {
        return this.http.post(this.config.header.info, { userName: this.getUserName(), });
    }
    getAdjusterCardsInfo() {
        return this.http.get(this.config.header.adjusterCardInfo);
    }
    getClaimantHistory() {
        return this.http.get(this.config.header.claimantHistory);
    }
    getUserName() {
        return this.userInfoService.getCurrentUserName();
    }
}
DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserInfoService)); }, token: DashboardService, providedIn: "root" });
