import {Injectable} from '@angular/core';
import {Insurer} from '../@models/system-managment/insurer';
import {INSURERS_CONFIG} from '../@config/api';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Contact} from '../@models/contact';
import {Note} from '../@models/system-managment/note';
import {Social} from '../@models/system-managment/social';
import {Functions} from '../../@util/functions';
import {FileUploadingService} from "../../@util/file-uploading-progress/file-uploading.service";

@Injectable({
	providedIn: 'root'
})
export class InsurancesService {

	private config = INSURERS_CONFIG;

	constructor(private http: HttpClient, private fileUploadingService: FileUploadingService) {
	}

	getInsurers() {
		return this.http.get<Insurer[]>(this.config.COMPANIES.getAll);
	}

	addInsurer(data: Insurer) {
		return this.http.post<Insurer>(this.config.COMPANIES.addCompany, data);
	}

	setInsurerContact(insuraceId, value: Contact) {
		value.press = Functions.checkPhone(value.press);
		value.fax = Functions.checkPhone(value.fax);
		value.phone = Functions.checkPhone(value.phone);
		return this.http.post<Insurer>(this.config.COMPANIES.setContact(insuraceId), value);
	}

	setInsurerNote(insuraceId, val: Note) {
		return this.http.post<Insurer>(this.config.COMPANIES.setNote(insuraceId), val);
	}

	getCurrentInsurance(insuraceId) {
		return this.http.get<Insurer>(this.config.COMPANIES.byId(insuraceId));
	}

	setSocial(insuraceId, value: Social) {
		Social.checkLinck(value);
		return this.http.post(this.config.COMPANIES.setSocial(insuraceId), value);
	}

	setBasic(value: Insurer) {
		return this.http.post(this.config.COMPANIES.setBasic(value.id), value);
	}

	setWebSite(id, website) {
		return this.http.post(this.config.COMPANIES.setWebSite(id), website);
	}

	uploadInsurerLogo(id, file: File) {
		return this.fileUploadingService.uploadFile(this.config.COMPANIES.uploadLogo(id), {file: file, fileInfo: null});
	}

	removeLogo(id) {
		return this.http.post(this.config.COMPANIES.removeLogo(id), {});
	}
}
