import {Component, ElementRef, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {IrisTableTabsService} from "../../../app/@core/iris-table-tabs.service";
import {ChatAnswersModel} from "../../../app/@models/prospects/chat-answers.model";
import {FIELD_TYPES} from "../../../app/@config/prospects/fieldTypes.config";
import {DATA_MASKS} from "../../../app/@config/dataMasks.config";
import {FormBuilder, FormGroup} from "@angular/forms";
import {DateService} from "../../../app/@core/functions/date.service";
import {PromotionsService} from "../../../app/@core/promotions.service";
import {LoadingService} from "../../loading/loading.service";
import {PERMANENT_TABS_ID} from "../../../app/@config/prospects/permanentTabs";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-iris-table-tabs',
  templateUrl: './iris-table-tabs.component.html',
  styleUrls: ['./iris-table-tabs.component.scss']
})
export class IrisTableTabsComponent implements OnInit {
  @Input() userId;
  activeUserId = null;
  questionsForm = FormGroup;

  activeId = null;
  requestedId = null;
  groupEnd = false;

  DATA_MASKS = DATA_MASKS;
  MESSAGING_TAB_ID = PERMANENT_TABS_ID.messaging;

  private _tabs: any;

  @Input() set tabs(value: any) {
    this._tabs = value;
    if (value !== null) {
      if (this.route.snapshot.queryParams.messaging) {
        this.changeActiveTab(this.MESSAGING_TAB_ID);
      } else {
        this.changeActiveTab(value[0].id);
      }
    }
  }

  get tabs(): any  {
    return this._tabs;
  }

  content = [];
  initialData = [];
  changes = [];

  @ViewChildren('answerListItemInput', {read: ElementRef}) answerListItemInput: QueryList <ElementRef>;

  constructor(private irisTableTabsService: IrisTableTabsService,
              private formBuilder: FormBuilder,
              private promotionsService: PromotionsService,
              private loadingService: LoadingService,
              private router: Router,
              private route: ActivatedRoute,
              private dateService: DateService) {
    this.irisTableTabsService.content.subscribe(val => {
      if (val === 'reset' && this.activeUserId !== this.userId) {
        this.content = [];
        this.initialData = [];
        this.changes = [];
      } else if (val && val.result) {
        this.activeUserId = this.userId;
        this.activeId = this.requestedId;
        this.content = [];
        this.initialData = val.result;
        this.changes = [];
        this.groupEnd = false;
        this.updateContent(val.result);
        this.requestedId = null;
      }
    });
  }

  updateContent(data?) {
    this.content = [];
    this.groupEnd = false;
    const dataArray = data ? data : this.initialData;

    if (this.activeId === this.MESSAGING_TAB_ID) {
      this.content = dataArray;
    } else {
      this.setDynamicTabsContent(dataArray);
    }
  }

  setDynamicTabsContent(dataArray) {
    dataArray.forEach(el => {
      const answer: ChatAnswersModel = el;

      if (!this.groupEnd) {

        this.displayChoicesQuestion(el);

        if (el.fieldType === FIELD_TYPES.text || el.fieldType === FIELD_TYPES.textMessage || el.fieldType === FIELD_TYPES.textarea ||
          el.fieldType === FIELD_TYPES.number || el.fieldType === FIELD_TYPES.phone ||
          el.fieldType === FIELD_TYPES.dollar) {

          this.content.push(el);

          if (!el.answer && el.fieldType !== FIELD_TYPES.textMessage) {
            this.groupEnd = true;
          }
        }

        if (el.fieldType === FIELD_TYPES.textList) {
          if (!el.answerList) {
            el.answerList = [{id: 'new', answer_id: 'new', title: '', tempId: 1}];
          }

          this.content.push(el);

          if (!el.answer || !el.answerList.length) {
            this.groupEnd = true;
          }
        }
      }
    });
    // console.log(this.initialData, '1', this.content, '2');
  }

  displayChoicesQuestion(el) {
    if (el.fieldType === FIELD_TYPES.select || el.fieldType === FIELD_TYPES.radio) {
      const itemAnswer = el.choices.find(choice => +choice.value === +el.answer);
      this.content.push(Object.assign({}, el));

      if (itemAnswer) {
        if (itemAnswer.groupEnd) {
          this.groupEnd = true;
        }

        if (itemAnswer.newElement) {
          const newElement = Object.assign({}, itemAnswer.newElement);
          newElement.questionId = el.questionId;
          newElement.questionTitle = newElement.title;
          newElement.answer = el.answerElement ? el.answerElement : null;
          this.content.push(newElement);
          if (!el.answerElement) {
            this.groupEnd = true;
          }
        }

        if (itemAnswer.newElements) {
          itemAnswer.newElements.forEach(newEl => {
            if (!this.groupEnd) {
              const newElement = Object.assign({}, newEl);

              newElement.questionId = el.questionId;
              newElement.questionTitle = newEl.title;
              newElement.answer = newEl.answer ? newEl.answer : '';
              newElement.isNewElementsItem = true;

              this.content.push(newElement);
              if (!newEl.answer) {
                this.groupEnd = true;
              }
            }
          });
        }
      } else {
        this.groupEnd = true;
      }
    }
  }

  addItemToContent(question, answer, type?) {
    this.content.push({
      question,
      answer,
      type
    });
  }



  ngOnInit() {
  }

  changeActiveTab(categoryId) {
    this.requestedId = categoryId;
    this.irisTableTabsService.requestContent(this.userId, categoryId);
  }

  isDollarType(fieldType) {
    return fieldType === FIELD_TYPES.dollar;
  }

  isPhoneType(fieldType) {
    return fieldType === FIELD_TYPES.phone;
  }

  isDateType(fieldType) {
    return fieldType === FIELD_TYPES.date;
  }

  isNumberType(fieldType) {
    return fieldType === FIELD_TYPES.number;
  }

  isTextType(fieldType) {
    return fieldType === FIELD_TYPES.text;
  }

  isTextareaType(fieldType) {
    return fieldType === FIELD_TYPES.textarea;
  }

  isRadioType(fieldType) {
    return fieldType === FIELD_TYPES.radio;
  }

  isCheckBoxType(fieldType) {
    return fieldType === FIELD_TYPES.checkbox;
  }

  isSelectType(fieldType) {
    return fieldType === FIELD_TYPES.select;
  }

  isTextListType(fieldType) {
    return fieldType === FIELD_TYPES.textList;
  }

  isTextMessageType(fieldType) {
    return fieldType === FIELD_TYPES.textMessage;
  }


  removeAnswerListItem(item, answerId, tempId) {
    const answerList = this.content.find(el => el.questionId === item.questionId).answerList;
    const newAnswer = answerList.filter(listItem => (String(listItem.id) !== String(answerId) && !listItem.tempId) || (String(listItem.tempId) !== String(tempId) && listItem.tempId));

    if (newAnswer.length) {
      this.content.find(el => el.questionId === item.questionId).answerList = newAnswer;
    } else {
      this.content.find(el => el.questionId === item.questionId).answerList = [{id: 'new', tempId: 1, answer_id: answerList[0].answer_id, title: ''}];
      this.initialData.find(el => el.questionId === item.questionId).answer = '';
    }
    this.updateContent();

  }

  onAnswerListItemInputChange(event, item, answer) {
    const value = event.target.value;
    if (value) {
      this.initialData.find(el => String(el.questionId) === String(item.questionId)).answer = 'done';
      this.content.find(el => el.questionId === item.questionId).answer = 'done';
    }
    this.content.find(el => el.questionId === item.questionId).answerList.find(listItem => (String(listItem.id) === String(answer.id) && !listItem.tempId) || (String(listItem.tempId) === String(answer.tempId) && listItem.tempId)).title = value;
    this.updateContent();
  }

  addAnswerListItem(item) {
    let tempId = 1;
    const answerList = this.content.find(el => el.questionId === item.questionId).answerList;
    this.content.find(el => el.questionId === item.questionId).answerList.filter(el => el.id === 'new').forEach(el => {
      if (el.tempId >= tempId) {
        tempId = el.tempId + 1;
      }
    });

    this.content.find(el => el.questionId === item.questionId).answerList.push({id: 'new', tempId, answer_id: answerList[0].answer_id, title: ''});
  }

  disableAddAnswerListItem(questionId) {
     let result = false;
     if (this.answerListItemInput) {
       this.answerListItemInput.forEach(el => {
         if (+el.nativeElement.getAttribute('data-question-id') === +questionId) {
           if (!el.nativeElement.value || !el.nativeElement.value.trim()) {
             result = true;
           }
         }
       });
     }

     return result;
  }

  identifyQuestion(index, item) {
    return item.questionId;
  }

  identifyAnswer(index, item) {
    return item.id;
  }

  onSelectChange($event, item) {
    let value = $event.value.value;
    this.setNewValue(item, value);
  }

  getSelectValue(item) {
    const answer = item.choices.find(choice => +choice.value === +item.answer);
    return answer;
  }

  saveChanges() {
    const sendChanges = [];
    const userId = this.userId;
    const categoryId = this.activeId;

    this.initialData.forEach(el => {
      if (el.fieldType === FIELD_TYPES.textList) {
        const noTempAnswer = [];
        el.answerList.forEach(answer => {
          if (answer.title) {
            noTempAnswer.push(Object.assign({}, answer));
          }
        });
        noTempAnswer.forEach(answer => {
          delete answer.tempId;
        });
        const newEl = Object.assign({}, el);
        newEl.answerList = noTempAnswer;
        if (newEl.answerList.length) {
          newEl.answer = 'done';
        }
        sendChanges.push(newEl);
      } else {
        sendChanges.push(el);
      }
    });
    // console.log(sendChanges, this.initialData, this.content);

    this.promotionsService.updateChatAnswers(userId, categoryId, sendChanges).subscribe(data => {
      this.loadingService.showError([data['message']], true);
      if (data['status']) {
        this.changeActiveTab(categoryId);
      }
    });
  }

  isRadioChecked(item, option) {
    return +item.answer === +option.value;
  }

  datePick($event, item) {
    let newAnswer;
    if ($event) {
      const newDate = new Date($event);
      newAnswer = `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
    } else {
      newAnswer = '';
    }

    this.content.find(el => (+el.questionId === +item.questionId) && (!item.optionId || (item.optionId && +el.optionId === +item.optionId))).answer = newAnswer;
    this.setNewValue(item, newAnswer);
  }

  getNumberMask(length) {
    let mask = '0';
    for (let i = 1; i < length; i++) {
      mask += '0';
    }
    return mask;
  }

  onRadioChange(event, item) {
    let value = event.value;
    this.setNewValue(item, value);
  }

  onInputChange(event, item) {
    let value = event.target.value;
    if (value[0] === '$') {
      value = value.slice(1);
    }
    this.setNewValue(item, value);
  }

  setNewValue(item, value) {
    if (item.isNewElementsItem) {
      const answer = this.initialData.find(question => question.questionId === item.questionId).answer;
      this.initialData.find(question => question.questionId === item.questionId).choices.find(choise => +choise.value === +answer).newElements.find(newEl => newEl.optionId === item.optionId).answer = value;
      // console.log(this.initialData, value);
    } else if (item.title && item.questionTitle) {
      this.initialData.find(question => question.questionId === item.questionId).answerElement = value;
    } else {
      this.initialData.find(question => question.questionId === item.questionId).answer = value;
    }
    this.updateContent();
  }

  isMessagingTab() {
    return this.activeId === this.MESSAGING_TAB_ID;
  }

  addMessage() {
    const returnUrl = this.getUrl();
    this.router.navigate(['/prospects/initial/new'], {queryParams: {returnUrl: returnUrl, id: this.promotionsService.activeUser}});
  }

  getUrl() {
    return this.router.url.indexOf('?') !== -1 ?
      this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
  }
}
