import {BrowserModule} from '@angular/platform-browser';
import {NgModule, Type, LOCALE_ID} from '@angular/core';
import {AppComponent} from './app.component';
import {UserIdleConfig} from './auth/user-idle/user-idle-config';
import {AuthConfig} from './auth/_services/auth-config';
import {AuthModule} from './auth/auth.module';
import {AppRoutingModule} from './app.routing';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {LoadingModule} from '../@util/loading/loading.module';
import {AuthGuard} from './auth/_guards/auth.guard';
import {MaskModule} from '../@util/mask/mask.module';
import {AUTH_CONFIG} from './@config/api';
import {CoreModule} from './@core/core.module';
import {FileUploadingProgressModule} from '../@util/file-uploading-progress/file-uploading-progress.module';
import {LoadingConfig} from '../@util/loading/loading-config';
import {NG_VALIDATORS, Validator} from '@angular/forms';
import {TextMaskModule} from 'angular2-text-mask/dist/angular2TextMask';
import {emailValidator, ICD_10Validator} from '../@util/dynamic-form/validators';
import {
	DYNAMIC_VALIDATORS, DynamicFormControlLayout, DynamicFormControlModel, DynamicFormsCoreModule, DynamicInputModel,
	ValidatorFactory
} from '@ng-dynamic-forms/core';
import {CustomIrisNotificationModule} from '../@util/custom-iris-notification/custom-iris-notification.module';
import {PdfViewComponent} from "./pdf-view/pdf-view.component";
import {DoctorsReportComponent} from './pdf-view/visits-report/doctors-report/doctors-report.component';
import {PdfViewBlocksDoctorsComponent} from './pdf-view/pdf-view-blocks/pdf-view-blocks-doctors/pdf-view-blocks-doctors.component';
import {PdfViewBlocksHeaderComponent} from './pdf-view/pdf-view-blocks/pdf-view-blocks-header/pdf-view-blocks-header.component';
import {PdfViewBlocksMedicalTestComponent} from './pdf-view/pdf-view-blocks/pdf-view-blocks-medical-test/pdf-view-blocks-medical-test.component';
import {PdfViewBlocksMedicationsComponent} from './pdf-view/pdf-view-blocks/pdf-view-blocks-medications/pdf-view-blocks-medications.component';
import {PdfViewBlocksHospitalsComponent} from './pdf-view/pdf-view-blocks/pdf-view-blocks-hospitals/pdf-view-blocks-hospitals.component';
import {PdfViewBlocksOtherComponent} from './pdf-view/pdf-view-blocks/pdf-view-blocks-other/pdf-view-blocks-other.component';
import {HospitalsReportComponent} from './pdf-view/visits-report/hospitals-report/hospitals-report.component';
import {OthersReportComponent} from './pdf-view/visits-report/others-report/others-report.component';
import {ReportHeaderComponent} from './pdf-view/visits-report/report-header/report-header.component';
import {MaterialModule} from "./material/material.module";
import {MedicalSourceModule} from "./@view/pages/claimants/medical-source/medical-source.module";
import {SourceVariationComponent} from "./@view/pages/claimants/medical-source/source-variation/source-variation.component";
import {MedicalConditionsComponent} from "./@view/pages/claimants/medical-source/new-source/medical-conditions/medical-conditions.component";
import {VisitDialogComponent} from "./@view/pages/claimants/medical-source/visit-dialog/visit-dialog.component";
import {ClaimGuard} from "./auth/_guards/claim.guard";
import {ImageEditorModule} from "../@util/image-editor/image-editor.module";
import {TitleRouterResolver} from "./title-router-resolver";
import {HIDDEN_MATCHER, REQUIRED_MATCHER} from "@ng-dynamic-forms/core";
import {FormChangesAttentionComponent} from "../@util/form-changes-attention/form-changes-attention.component";
import {PartialModule} from "./@view/partial/partial.module";
import {AuditLogComponent} from "./@view/menu/menu-desktop/audit-log/audit-log.component";
import {DoctorFormNewComponent} from "./@view/pages/claimants/medical-treating-sources/doctors/doctor-form-new/doctor-form-new.component";
import {HospitalFormNewComponent} from "./@view/pages/claimants/medical-treating-sources/hospitals/hospital-form-new/hospital-form-new.component";
import {OtheFormNewComponent} from "./@view/pages/claimants/medical-treating-sources/othe/othe-form-new/othe-form-new.component";
import {ExportModule} from "./export/export.module";
import {GlobalSaveCancelComponent} from "./@view/global-save/global-save-cancel/global-save-cancel.component";
import {GlobalSaveComponent} from "./@view/global-save/global-save/global-save.component";
import {CompleteFollowUpComponent} from "./@view/pages/claimants/follow-ups/pending/complet-follow-up/complete-follow-up.component";
import {GlobalSaveContinueClaimantUpdateComponent} from "./@view/global-save/global-save-continue-claimant-update/global-save-continue-claimant-update.component";
import {GlobalSaveMissingFollowUpsComponent} from "./@view/global-save/global-save-missing-follow-ups/global-save-missing-follow-ups.component";
import {GlobalSaveLoginFormErrorComponent} from "./@view/global-save/global-save-login-form-error/global-save-login-form-error.component";
import {GlobalSaveErrorTableComponent} from "./@view/global-save/global-save-error-table/global-save-error-table.component";
import {GlobalSaveFollowUpsPastDuesComponent} from "./@view/global-save/global-save-follow-ups-past-dues/global-save-follow-ups-past-dues.component";
import {NotificationConfirmationsComponent} from "./@view/notification/confirmations/notification-confirmations.component";
import {GoogleMapComponent} from "./@view/google-map/google-map.component";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';

import { NgxCurrencyModule } from "ngx-currency";
//import {NgxMaskModule} from 'ngx-mask';

import {ClaimantNotificationsAlertDetailsComponent} from "./@view/pages/claimants/notifications/claimant-notifications-alerts/claimant-notifications-alert-details/claimant-notifications-alert-details.component";

import { DragDropModule } from '@angular/cdk/drag-drop';
import {TempUsersGuard} from "./auth/_guards/tempUsers.guard";
import {MedicalTreatingSourcesModule} from "./@view/pages/claimants/medical-treating-sources/medical-treating-sources.module";
import {ClaimantMedicationsNewComponent} from "./@view/pages/claimants/medical-treating-sources/claimant-medications/claimant-medications-new/claimant-medications-new.component";
import {MedicalTreatingReportPageComponent} from "./@view/medical-treating-report-page/medical-treating-report-page.component";
import {MedicalTreatingReportHeaderComponent} from "./@view/medical-treating-report-page/medical-treating-report-header/medical-treating-report-header.component";
import {MedicalTreatingReportMedicationsComponent} from "./@view/medical-treating-report-page/medical-treating-report-medications/medical-treating-report-medications.component";
import {MedicalTreatingReportMtsComponent} from "./@view/medical-treating-report-page/medical-treating-report-mts/medical-treating-report-mts.component";


const idleConfig: UserIdleConfig = {idle: 900, timeout: 60, ping: 180, showIdleDialog: true};

export const authConfig: AuthConfig = {
	authUserNameUrl: AUTH_CONFIG.sendUserName,
	authUrl: AUTH_CONFIG.login,
	refreshTokenUrl: AUTH_CONFIG.refresh,
	loginRoute: '/login',
	restoreRoute: '/restore',
	checkUser: AUTH_CONFIG.checkUser,
	restoreUrl: AUTH_CONFIG.restore,
};
export const loadingConfig: LoadingConfig = {
	showErrors: true,
	showSpinner: true,
	// showSpinner: false,
	ignoreUrls: ['/api/notifications', '/api/notifications/alerts']
};


@NgModule({
	declarations: [
		AppComponent,
		PdfViewComponent,
		DoctorsReportComponent,
		HospitalsReportComponent,
		OthersReportComponent,
		ReportHeaderComponent,
		FormChangesAttentionComponent,
		AuditLogComponent,
		PdfViewBlocksDoctorsComponent,
		PdfViewBlocksHeaderComponent,
		PdfViewBlocksMedicalTestComponent,
		PdfViewBlocksMedicationsComponent,
		PdfViewBlocksHospitalsComponent,
		PdfViewBlocksOtherComponent,
		GlobalSaveCancelComponent,
		GlobalSaveComponent,
		CompleteFollowUpComponent,
		GlobalSaveContinueClaimantUpdateComponent,
		GlobalSaveMissingFollowUpsComponent,
		GlobalSaveLoginFormErrorComponent,
		GlobalSaveErrorTableComponent,
		GlobalSaveFollowUpsPastDuesComponent,
		NotificationConfirmationsComponent,
		GoogleMapComponent,
		//NotificationsAlertDetailsComponent,
		ClaimantNotificationsAlertDetailsComponent,
		DoctorFormNewComponent,
		HospitalFormNewComponent,
		OtheFormNewComponent,
		ClaimantMedicationsNewComponent,
		MedicalTreatingReportPageComponent,
		MedicalTreatingReportHeaderComponent,
		MedicalTreatingReportMedicationsComponent,
		MedicalTreatingReportMtsComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AuthModule.forRoot(authConfig, {currentUserRoles: '/api/session/roles'}),
		LoadingModule.forRoot(loadingConfig),
		AppRoutingModule,
		CoreModule.forRoot(),
		DynamicFormsCoreModule.forRoot(),
		ImageEditorModule.forRoot(),
		MaskModule,
		FileUploadingProgressModule,
		CustomIrisNotificationModule.forRoot(),
		MaterialModule,
		MedicalSourceModule,
		PartialModule,
		ExportModule,
		NgxCurrencyModule,
		DragDropModule,
		TextMaskModule,
		MedicalTreatingSourcesModule
	],
	providers: [
		AuthGuard,
		ClaimGuard,
		TempUsersGuard,
		TitleRouterResolver,
		// { provide: DateAdapter, useClass: CustomDateAdapter },

		// { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
		{provide: NG_VALIDATORS, useValue: emailValidator, multi: true},
		{
			provide: DYNAMIC_VALIDATORS,
			useValue: new Map<string, Validator | ValidatorFactory>([
				['emailValidator', emailValidator],
				['ICD_10Validator', ICD_10Validator],
			])
		},
		{provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
		HIDDEN_MATCHER,
		REQUIRED_MATCHER
		// {
		//     provide: DYNAMIC_FORM_CONTROL_MAP_FN,
		//     useValue: (model: DynamicFormControlModel): Type<DynamicFormControl> | null => {
		//
		//         switch (model.type) {
		//
		//         }
		//     }
		// }
	],
	bootstrap: [AppComponent],
	exports: [DragDropModule],
	entryComponents: [
		SourceVariationComponent,
		MedicalConditionsComponent,
		VisitDialogComponent,
		FormChangesAttentionComponent,
		AuditLogComponent,
		GlobalSaveCancelComponent,
		GlobalSaveComponent,
		CompleteFollowUpComponent,
		GlobalSaveContinueClaimantUpdateComponent,
		GlobalSaveMissingFollowUpsComponent,
		GlobalSaveLoginFormErrorComponent,
		GlobalSaveErrorTableComponent,
		GlobalSaveFollowUpsPastDuesComponent,
		NotificationConfirmationsComponent,
		GoogleMapComponent,
		//NotificationsAlertDetailsComponent,
		ClaimantNotificationsAlertDetailsComponent,
		DoctorFormNewComponent,
		HospitalFormNewComponent,
		OtheFormNewComponent,
		ClaimantMedicationsNewComponent

	]
})
export class AppModule {
}
