<app-frame [overlay]="false"
		   [title]="frameTitle"
		   [class.no-frame]="true"
		   [showCloseButton]="false" class="step-frame">
	<form class="form-horizontal form-fieldset second-step" [formGroup]="formGroup" novalidate>
		<dynamic-material-form [group]="formGroup"
							   [layout]="formLayout"
							   class="row"
							   [model]="formModel">
			<ng-template modelId="treatedDoctor" align="END">
				<label style="padding-top: .5em; text-align: left"
					   class="form-label form-label_full-width form-label_mb">
					If you don't please hit next
				</label>
			</ng-template>
		</dynamic-material-form>
	</form>

	<ng-container *ngIf="!isAnotherVisit">
		<app-form-actions-content *appCanAccess="['EXECUTIVE_STAFF','CLIENT']">
			<form-button style="margin-right: auto; margin-left: 0;"
						 *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" (clickOnButton)="backHospitalsVisitNotes()">Back</form-button>
			<form-button *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" (clickOnButton)="nextHospitalsVisitNotes()">Next</form-button>
		</app-form-actions-content>
	</ng-container>
	<ng-container *ngIf="isAnotherVisit">
		<app-form-actions-content *appCanAccess="['EXECUTIVE_STAFF','CLIENT']">
			<form-button style="margin-right: auto; margin-left: 0;"
						 *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" (clickOnButton)="backHospitalsVisitNotes()">Back</form-button>
			<form-button *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" (clickOnButton)="nextHospitalsVisitNotes()">Next</form-button>
		</app-form-actions-content>
	</ng-container>
</app-frame>

