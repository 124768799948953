import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {
	DynamicFormControlLayout,
	DynamicFormControlModel,
	DynamicFormLayout,
	DynamicFormService
} from "@ng-dynamic-forms/core";
import {FormValueBuilder} from "../../../../../partial/forms/form-tamplate";
import {FormGroup} from "@angular/forms";
import {STRING_FIELD} from "../../../../../../../@util/dynamic-form/dynamic-controlls";
import {MedicalService} from "../../../../../../@core/medical.service";
import {DynamicForm} from "../../../../../../../@util/dynamic-form/dynamic-form";
import {
	DynamicFormGroupModel, DynamicRadioGroupModel, DynamicSelectModel,
	DynamicTextAreaModel
} from "../../../../../../../@util/ui-material/form-controls";
import {MEDICAL_TESTS_MODE} from "../../../../../partial/partial-medical-tests/partial-medical-tests.config";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {CatalogItemView} from "../../../../../../@models/medical/catalog-item-view";

const creationSteps = {
	'initial': 1,
	'medication': 2,
};

@Component({
	selector: 'app-claimant-medications-new',
	templateUrl: './claimant-medications-new.component.html',
	styleUrls: ['./claimant-medications-new.component.scss']
})
export class ClaimantMedicationsNewComponent extends DynamicForm implements OnInit {
	MEDICAL_TESTS_MODE = MEDICAL_TESTS_MODE;
	medications;
	_selectedType;
	@Input()
	set selectedType(value) {
		if (!value)
			return;
		if (this._selectedType != value) {
			this._selectedType = value;
			this.typeChanged(value);
		}

	};

	@Input()
	sslgId: string;
	@Output()
	saveThirdStepData = new EventEmitter();

	title = 'In what way would you like to add a medication?';
	typeAskingStep: number = creationSteps.initial;



	formGroup: FormGroup;
	formModel: DynamicFormControlModel[];

	formGroupTemplate: FormGroup;
	formModelTemplate: DynamicFormControlModel[];

	formLayout: DynamicFormLayout = {
		left: <DynamicFormControlLayout>{
			element: {
				host: 'col-lg-6 col-12',
				control: 'row',
				children: 'col-12 row-mb'
			},
		},
		right: <DynamicFormControlLayout>{
			element: {
				host: 'col-lg-6 col-12',
				control: 'row',
				children: 'col-12 row-mb'
			},
		}
	};

	form = {
		type: DynamicRadioGroupModel({
			id: 'type',
			label: '',
			value: 'DOCTOR',
			options: [
				{value: 'DOCTOR', label: 'DOCTOR'},
				{value: 'HOSPITAL', label: 'HOSPITAL'},
				{value: 'OTHER', label: 'OTHER'},
			],
			editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		}),
		catalogItemId: DynamicSelectModel({
			id: 'catalogItemId',
			label: 'Name',
			required: true,
			validators: {
				required: null
			},
			errorMessages: {
				required: 'Required'
			},
			value: '',editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		}),
		name: STRING_FIELD({id: 'medication', label: 'Medication',editableFor: ["EXECUTIVE_STAFF", "CLIENT"], required: true,	errorMessages: {required: 'Required'}}),
		reason: DynamicTextAreaModel({id: 'reason', label: 'Reason for Taking',editableFor: ["EXECUTIVE_STAFF", "CLIENT"], required: true,	errorMessages: {required: 'Required'}}),
		sideEffects: DynamicTextAreaModel({id: 'sideEffects', label: 'Side Effects',editableFor: ["EXECUTIVE_STAFF", "CLIENT"]}),
	};

	formTemplate = {
		left: DynamicFormGroupModel({
			id: 'left',
			group: [
				this.form.type,
				this.form.catalogItemId,
			],
			editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		}),
		right: DynamicFormGroupModel({
			id: 'right',
			group: [
				this.form.name,
				this.form.reason,
				this.form.sideEffects
			], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		})

	};

	ctatlogItems: any;

	constructor(private formService: DynamicFormService,
							private route: ActivatedRoute,
							private dialogRef: MatDialogRef<ClaimantMedicationsNewComponent>,
							@Inject(MAT_DIALOG_DATA) public componentData: any,
							private router: Router,
							private medicalService: MedicalService) {
		super();

		this.formModelTemplate = FormValueBuilder.buildFormModel(this.formTemplate);
		this.formGroupTemplate = formService.createFormGroup(this.formModelTemplate);
	}

	async ngOnInit() {
		this.sslgId = this.getSslgId();

		this.ctatlogItems = await this.medicalService.getCatalogVisitItem(this.sslgId).toPromise();
		this.typeChanged('DOCTOR');

		FormValueBuilder.resetToDefault(this.form);
		this.formGroupTemplate.valueChanges.subscribe(j => {
			this.formStateChanged.emit({key: this.formKey, value: FormValueBuilder.isDirty(this.form)});
		});
	}

	getSslgId() {
		return this.componentData.sslgId;
	}

	initCatalogOptions(value) {
		setTimeout(() => {
			if (!this.ctatlogItems[value]) {
				this.form.catalogItemId.options = [{label: 'Please add ' + value, value: '', disabled: true}];
			} else {
				this.form.catalogItemId.options = this.ctatlogItems[value].map((item: CatalogItemView) => {
					return {label: item.name, value: item.id};
				});
			}
		}, 0);

	}

	typeChanged(type) {
		this.initCatalogOptions(type);
		this.form.catalogItemId.valueUpdates.next(null);
		this.formStateChanged.emit({key: this.formKey, value: FormValueBuilder.isDirty(this.form)});
	}


	ngOnDestroy(): void {
		this.formStateChanged.next({value: false, key: this.formKey});
	}

	changeTypeAskingStep(step) {
		this.typeAskingStep = step;
		if (this.isInitialStep()) {
			this.title = 'In what way would you like to add a medication?';
		}	if (this.isMedicationStep()) {
			this.title = 'New Medication';
		}
	}

	isInitialStep() {
		return this.typeAskingStep === creationSteps.initial;
	}

	isMedicationStep() {
		return this.typeAskingStep === creationSteps.medication;
	}

	addMedication() {
		this.changeTypeAskingStep(creationSteps.medication);
	}

	addVisit() {
		document.querySelectorAll('.mat-drawer-content').forEach(el => {
			(<HTMLElement>el).style.overflow = 'scroll';
		});
		this.dialogRef.close({});
		this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'],
			{
				queryParams: {
					returnUrl: '/claimants/medical/' + this.sslgId + '/visit-summaries',
					type: 'DOCTOR',
				}
			});
	}

	closeMedicationForm(cmd) {
		document.querySelectorAll('.mat-drawer-content').forEach(el => {
			(<HTMLElement>el).style.overflow = 'scroll';
		});
		this.dialogRef.close(cmd);
	}

	async saveMedication() {
		FormValueBuilder.setControlsToched(this.formGroupTemplate);
		if (this.formGroupTemplate.valid) {
			const data = FormValueBuilder.getData(this.form);
			data.catalogId = data.catalogItemId;

			delete data.catalogItemId;
			delete data.type;

			await this.medicalService.addMedication(this.sslgId, data);
			this.closeMedicationForm('new');
			this.formGroupTemplate.reset();
		}
	}

	onChange($event) {
		if ($event.model.id === 'type') {
			this.typeChanged($event.control.value);
			this.selectedType.next($event.control.value);
			setTimeout(() => {
				if (this.form.catalogItemId.value &&
					(<any[]>this.form.catalogItemId.options)
						.filter(j => j.value === this.form.catalogItemId.value).length === 0) {
					this.form.catalogItemId.valueUpdates.next(null);
				}
			}, 0);
		} else if($event.model.id === 'catalogItemId') {
			FormValueBuilder.setData({catalogItemId: $event.control.value}, this.form);
		}
	}
}
