import {
	Component,
	EventEmitter,
	forwardRef,
	HostListener,
	Inject,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
	DynamicFormArrayModel,
	DynamicFormControlLayout,
	DynamicFormControlModel, DynamicFormLayout,
	DynamicFormService
} from "@ng-dynamic-forms/core";
import {
	ADDRESS, CHECK_BOX, CYTY,
	DATE_PICKER,
	EXT,
	PHONE,
	PRESS, RADIO_USER_STATE, STATE_SELECT,
	STRING_FIELD, ZIP
} from "../../../../../../../@util/dynamic-form/dynamic-controlls";
import {
	DynamicFormArrayModel as DunamicFormArray, DynamicFormGroupModel,
	DynamicRadioGroupModel
} from "../../../../../../../@util/ui-material/form-controls";
import {DoctorItem, DoctorType} from "../../../../../../@models/medical/doctor-item";
import {Observable, Subscription} from "rxjs";
import {MedicalRequestsComponent} from "../../medical-requests/medical-requests.component";
import {MedicalPaymentsComponent} from "../../medical-payments/medical-payments.component";
import {State} from "../../../../../../test-new-stepper-form/test-form/on-changes";
import {MedicalService} from "../../../../../../@core/medical.service";
import {AddressService} from "../../../../../../@core/address.service";
import {MedicalTreatingSourcesComponent} from "../../medical-treating-sources.component";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {UserInfoService} from "../../../../../../@core/user-info.service";
import {DateService} from "../../../../../../@core/functions/date.service";
import {DialogService} from "../../../../../../../@util/dialog/dialog.service";
import {NavigationService} from "../../../../../../@core/navigation.service";
import {FormValueBuilder} from "../../../../../partial/forms/form-tamplate";
import {CatalogItemType} from "../../../../../../@models/medical/catalog-item-view";
import {debounceTime, map, startWith} from "rxjs/operators";
import {Functions} from "../../../../../../../@util/functions";
import {MAT_DIALOG_DATA, MatAutocompleteTrigger, MatDialogRef} from "@angular/material";
import {FollowUp} from "../../../../../../@models/follow-up/follow-up";
import {UserTempService} from "../../../../../../@core/userTemp.service";
import {DataSortService} from "../../../../../../@core/data-sort.service";
import {MedicalSourcesService} from "../../../../../../@core/medical-sources.service";
import {LoadingService} from "../../../../../../../@util/loading/loading.service";
import {MEDICAL_TESTS_MODE} from "../../../../../partial/partial-medical-tests/partial-medical-tests.config";
import {LEVEL_REF_ITEMS_ID} from "../../../status/intake-form/intake.config";

const creationSteps = {
	'initial': 1,
	'initialFiling': 2,
	'requestForReconsideration': 3,
	'requestForHearing': 4,
	'newDoctor': 5,
	'medicalTests': 6,
	'nextDoctorCreated': 7,
	'finished': 8
};

const creationCheckBoxKeys = {
	'initialClaim': 0,
	'requestForReconsideration': 1,
	'requestForHearing': 2,
	'returning': 3,
	'returningHospital': 4,
	'returningOthers': 5
};

const finishedSteps = {
	'addAnotherDoctor': 1,
	'addHospitalization': 2,
	'addDoctorsVisit': 3,
	'addOtherTreatingSource': 4,
	'returning': 5
};

@Component({
	selector: 'app-doctor-form-new',
	templateUrl: './doctor-form-new.component.html',
	styleUrls: ['./doctor-form-new.component.scss']
})

export class DoctorFormNewComponent implements OnInit {
	@Output() updateDoctorsList = new EventEmitter<any>();
	MEDICAL_TESTS_MODE = MEDICAL_TESTS_MODE;
	title = 'What type of claim or appeal are we filling on your behalf?';
	formGroup: FormGroup;
	formModel: DynamicFormControlModel[];
	formModelDate: DynamicFormControlModel[];
	formLayout = {
		treat: <DynamicFormControlLayout>{
			element: {
				control: 'form-row'
			}
		},
		treats: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		speciality: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		type: <DynamicFormControlLayout>{
			element: {
				label: 'form-label'
			}
		},
		visits: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb',
				control: 'form-row',
			}
		},
		firstSeen: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_x2 form-element_br2-full'
			}
		},
		lastSeen: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_x2 form-element_br2-full'
			}
		},
		nextVisit: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_x2 form-element_br2-full'
			}
		},
		phones: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb',
				control: 'form-row',
			}
		},
		contact_phone: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_x2'
			}
		},
		contact_press: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		contact_ext: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		contact_fax: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb'
			}
		},
		contact_address: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb'
			}
		},
		contact_address2: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb'
			}
		}, contact_cyti: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb'
			}
		},
		state_zip: <DynamicFormControlLayout>{
			element: {
				control: 'form-row',
				container: 'row-mb'
			}
		},
		contact_state: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		contact_zip: <DynamicFormControlLayout>{
			element: {
				host: 'form-element label-accent'
			}
		},
		// callerPhones: <DynamicFormControlLayout>{
		// 	element: {
		// 		control: 'form-row',
		// 	}
		// },
		medicalCondition: <DynamicFormControlLayout>{
			element: {
				host: 'col-lg-12 row-mb-half',
				label: 'form-label form-label_full-width row-mb',
				group: 'row row_conditions'
			},
		}
	};
	private doctor: DoctorItem;

	formTemplate = {
		name: STRING_FIELD({id: 'name', label: 'Name', hidden: true}),
		googlePlaceId: STRING_FIELD({id: 'googlePlaceId', label: 'Name', hidden: true}),
		practiceName: STRING_FIELD({id: 'practiceName', label: 'Practice', maxLength: 256}),
		treats: STRING_FIELD({id: 'treats', label: 'Treats For', maxLength: 128}),
		specialty: STRING_FIELD({id: 'speciality', label: 'Specialty', maxLength: 128, hidden: true}),
		type: DynamicRadioGroupModel<DoctorType>({
			id: 'type',
			label: 'Type',
			options: [
				{
					value: DoctorType.PCP,
					label: DoctorType.PCP.toString()
				},
				{
					value: DoctorType.SPECIALIST,
					label: DoctorType.SPECIALIST.toString()
				}
			],
			value: DoctorType.PCP
		}),
		visits: {
			firstSeen: DATE_PICKER({id: 'firstSeen', label: 'First Seen',editableFor: ["EXECUTIVE_STAFF","CLIENT"]}),
			lastSeen: DATE_PICKER({id: 'lastSeen', label: 'Last Seen',editableFor: ["EXECUTIVE_STAFF","CLIENT"]}),
			nextVisit: DATE_PICKER({id: 'nextVisit', label: 'Next Visit',editableFor: ["EXECUTIVE_STAFF","CLIENT"]})
		},
		contact: {
			phone: PHONE({id: 'contact_phone', label: 'Phone'}),
			press: PRESS({id: 'contact_press', label: '"Press"'}),
			ext: EXT({id: 'contact_ext', label: 'Ext.'}),
			fax: PHONE({id: 'contact_fax', label: 'Business Fax'}),
			street: ADDRESS({id: 'contact_address'}),
			street2: ADDRESS({id: 'contact_address2', label: 'Address 2'}),
			city: CYTY({id: 'contact_cyti'}),
			state: STATE_SELECT({id: 'contact_state'}),
			zip: ZIP({id: 'contact_zip'}),
		},
		// callerName: STRING_FIELD({id: 'callerName', label: 'Name', maxLength: 128}),
		// callerPhone: PHONE({id: 'callerPhone', label: 'Phone'}),
		// callerPress: PRESS({id: 'callerPress', label: '"Press"'}),
		// callerExt: EXT({id: 'callerExt', label: 'Ext.'}),
		medicalCondition: DunamicFormArray({
			id: 'medicalCondition',
			label: 'What medical conditions were treated?',
			groupFactory: () => {
				return [
					STRING_FIELD({id: 'value', label: 'Condition',disabled:this.isDisabled(), maxLength: 64, editableFor: ["EXECUTIVE_STAFF", "CLIENT"]})
				]
			},
			editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		}),
	};

	form = {
		name: this.formTemplate.name,
		// recipientName: this.formTemplate.recipient.recipientName,
		googlePlaceId: this.formTemplate.googlePlaceId,
		practiceName: this.formTemplate.practiceName,
		treat: DynamicFormGroupModel({
			id: 'treat',
			group: [
				// this.formTemplate.treats,
				this.formTemplate.specialty,
			]
		}),
		type: this.formTemplate.type,
		contact: DynamicFormGroupModel({
			id: 'contact',
			group: [
				DynamicFormGroupModel({
					id: 'phones',
					group: [
						this.formTemplate.contact.phone,
						this.formTemplate.contact.press,
						this.formTemplate.contact.ext
					]
				}),
				this.formTemplate.contact.fax,
				DynamicFormGroupModel({
					id: 'state_zip',
					group: [
						this.formTemplate.contact.zip,
						this.formTemplate.contact.state,

					]
				}),
				this.formTemplate.contact.city,
				this.formTemplate.contact.street,
				this.formTemplate.contact.street2,
			]
		}),
		doctor: DynamicFormGroupModel({
			id: 'doctor',
			group: [
				this.formTemplate.medicalCondition
			], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		}),
		visit:DynamicFormGroupModel({
			id: 'visit',
			editableFor:["EXECUTIVE_STAFF","CLIENT"],
			group: [
				DynamicFormGroupModel({
					id: 'visits',
					editableFor:["EXECUTIVE_STAFF","CLIENT"],
					group: [
						this.formTemplate.visits.firstSeen,
						this.formTemplate.visits.lastSeen,
						this.formTemplate.visits.nextVisit
					]
				}),
			]
		}),

	};
	sslgId: any;
	private sub: Subscription;
	subscription;
	subscribed = true;
	state: State = State.before;
	allFormState: State = State.before;
	oldFormData;
	oldSourceNameData;
	disabledBtn = false;
	condition = "";
	requests;
	@ViewChild('trigger', {static: false}) trigger: MatAutocompleteTrigger;

	formDate = {
		date: <any> null
	};

	typeAskingStep: number = creationSteps.initial;
	creationSteps = creationSteps;

	initialFormRadioOptions = [{label: 'Initial Claim', value: creationCheckBoxKeys.initialClaim},
		{label: 'Req. For Reconsideration', value: creationCheckBoxKeys.requestForReconsideration},
		{label: 'Req. For Hearing', value: creationCheckBoxKeys.requestForHearing},
		{label: 'I\'m Returning', value: creationCheckBoxKeys.returning}]

	formInitialGroup: FormGroup;
	formInitialModel: DynamicFormControlModel[];
	formInitial = {
		levelSelection: RADIO_USER_STATE({
			id: 'levelSelection',
			options: [{label: 'Initial Claim', value: creationCheckBoxKeys.initialClaim},
								{label: 'Req. For Reconsideration', value: creationCheckBoxKeys.requestForReconsideration},
								{label: 'Req. For Hearing', value: creationCheckBoxKeys.requestForHearing},
								{label: 'I\'m Returning', value: creationCheckBoxKeys.returning}],
			editableFor: ["EXECUTIVE_STAFF", "CLIENT"]
		})
	};

	formFinishedGroup: FormGroup;
	formFinishedModel: DynamicFormControlModel[];
	formFinished = {
		levelSelection: RADIO_USER_STATE({
			id: 'nextStepSelection',
			options: [
				{label: 'Add Another Doctor', value: finishedSteps.addAnotherDoctor},
				{label: 'Add a Hospitalization', value: finishedSteps.addHospitalization},
				{label: 'Add a Doctor\'s Visit Summary ', value: finishedSteps.addDoctorsVisit},
				{label: 'Add a OTHER Treating Source', value: finishedSteps.addOtherTreatingSource},
				{label: 'Return to my Profile', value: finishedSteps.returning}
				]
		})
	};

	medicalTestsData: any;
	medicationsData: any;
	dateLastWorked;
	dateIC;
	dateRecon;
	docNewVisitId;
	doctorDates;
	// doctorDates = {
	// 	dlw: '2021-11-09 00:00:00',
	// 	ic: '2021-05-01 00:00:00',
	// 	recon: '2021-08-11 00:00:00'
	// }
	levelRefValue: number | string = 'unset';

	@HostListener('mousewheel', ['$event'])
	onMousewheel(event) {
		const input = document.querySelector('.mat-input-element');
		(<HTMLInputElement>input).blur();
		if (this.trigger) {
			this.trigger.closePanel();
		}
	}

	constructor(private medicalService: MedicalService,
							private medicalSourcesService: MedicalSourcesService,
				private formService: DynamicFormService,
				private fb: FormBuilder,
				private addressService: AddressService,
				private route: ActivatedRoute,
				private userInfo: UserInfoService,
				private user: UserInfoService,
				private userInfoService: UserInfoService,
				private userTempService: UserTempService,
				private dateService: DateService,
				private dialog: DialogService,
				private dialogRef: MatDialogRef<DoctorFormNewComponent>,
				@Inject(MAT_DIALOG_DATA) public componentData: any,
				private router: Router,
				private formBuilder: FormBuilder,
				private dataSortService: DataSortService,
				private loadingService: LoadingService,
				private navService: NavigationService) {

		this.formModel = FormValueBuilder.buildFormModel(this.form);
		this.formGroup = this.formService.createFormGroup(this.formModel);
		this.nameCtrl.setValidators(Validators.required);
		this.sub = navService.back().subscribe(j => this.closeDoctorForm({}));
		this.formModelDate = FormValueBuilder.buildFormModel(this.formDate);

		this.formInitialModel = FormValueBuilder.buildFormModel(this.formInitial);
		this.formInitialGroup = this.formService.createFormGroup(this.formInitialModel);
		this.formFinishedModel = FormValueBuilder.buildFormModel(this.formFinished);
		this.formFinishedGroup = this.formService.createFormGroup(this.formFinishedModel);

		// this.formDate = this.formBuilder.group({
		// 	date: [null, Validators.required],
		// });
		router.events.subscribe((val) => {
			if (val instanceof NavigationStart) {
				this.closeDoctorForm({});
			}
		});
	}

	ngOnDestroy(): void {
		//this._parent.hideHeader = false;
		this.sub.unsubscribe();
	}

	isDisabled(){
		if(this.isCreationPage() || !this.isClaimant()){
			return false;
		}

		return true;
	}

	nameCtrl = new FormControl();
	filteredNames: Observable<{ id: number, name: string, label?: string }[]>;
	names: { id: number, name: string, label?: string }[] = [];
	catalogItemId: number = null;

	private _filterNames(value): { id: number, name: string, label?: string }[] {
		////console.log(value);
		const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name ? value.name.toLowerCase() : '';
		////console.log(filterValue);
		return this.names.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
	}

	displayFn(item?: { id: number, name: string }): string | undefined {
		return item ? item.name : undefined;
	}

	async ngOnInit() {
		this.hideDropDownOnscroll();

		this.checkDisabledBtn();

		this.sslgId = this.getSslgId();
		if (this.componentData.from) {
			if (this.componentData.from === 'hospitals' || this.componentData.from === 'other') {
				const type = this.componentData.from === 'hospitals' ? creationCheckBoxKeys.returningHospital : this.componentData.from === 'other' ? creationCheckBoxKeys.returningOthers : null;
				this.formInitialGroup.controls.levelSelection.setValue(type);
				this.changeTypeAskingStep(this.creationSteps.newDoctor);
			}
		}

		this.medicalService.setClaimantUserName(this.sslgId);

			//this.condition = this.route.snapshot.url[1].path;

		this.names = await this.medicalService.getCatalog(CatalogItemType.DOCTOR).toPromise();
		this.doctorDates = await this.medicalSourcesService.addTreatingSourcesDoctorDates(this.sslgId).toPromise();
		this.levelRefValue = this.doctorDates.levelReferred;

			for (let i in this.names) {
				let element = <any>this.names[i];

				this.names[i].label = '';
				if (element.address != null) {
					this.names[i].label += element.address;
				}
				if (element.city != null) {
					if (this.names[i].label != '') {
						this.names[i].label += ', ';
					}
					this.names[i].label += element.city;
				}
				if (element.state != null) {
					if (this.names[i].label != '') {
						this.names[i].label += ', ';
					}
					this.names[i].label += element.state;
				}
				if (element.zip != null) {
					this.names[i].label += ' ' + element.zip;
				}

			}

			this.filteredNames = this.nameCtrl.valueChanges
				.pipe(
					map(value => {
					  console.log(value);

						this.doctor = null;
						this.formTemplate.name = this.nameCtrl.value;

						this.visitsSwitchedOn();

						if (value.id) {
							//this.formGroup.reset();

							this.formGroup.enable({onlySelf: true});

							this.subscription.unsubscribe();



							if (value.id != -1) {
								if(this.user.isClaimant()){

									// if (!this.isUserTemp()) {
									// 	this.formGroup.get("callerName").disable({onlySelf: true});
									// 	this.formGroup.get("callerPhones").disable({onlySelf: true});
									// }

									this.formGroup.get("practiceName").disable({onlySelf: true});
									this.formGroup.get("type").disable({onlySelf: true});
									this.formGroup.get("contact").disable({onlySelf: true});
									// this.formGroup.get("recipientName").disable({onlySelf: true});

								}

								setTimeout(async () => {
									this.doctor = await this.medicalService.getCatalogItem(CatalogItemType.DOCTOR, value.id).toPromise();

									await this.setFormData();

									//this.visitsDisabled();
								});
							} else {
								//this.formGroup.enable({onlySelf: true});

								this.addressService.getPlaceInfo(value.placeId).subscribe(j => {
									this.formTemplate.name.autocompleteSelectedValue = null;
									if (j.zip)
										this.formTemplate.contact.zip.valueUpdates.next(j.zip);
									if (j.state)
										this.formTemplate.contact.state.valueUpdates.next(j.state);
									if (j.city)
										this.formTemplate.contact.city.valueUpdates.next(j.city);
									if (j.phone){
										this.formTemplate.contact.phone.valueUpdates.next(j.phone);
									}

									if (j.locations)
										this.formTemplate.contact.street.valueUpdates.next((j.streetNumber ? j.streetNumber + ' ' : '') + (j.locations ? j.locations : ''));
								})
							}
						} else {
							if (!this.subscribed) {
								this.medicalService.setMedicalConditionsTemplate([]);
								this.subscribeZipCode();
							}

							this.formGroup.enable({onlySelf: true});

							setTimeout(() => {
								this.formGroup.reset();
								this.form.type.valueUpdates.next(DoctorType.PCP);
							});
						}

						return value;
					}),
					startWith(''),
					debounceTime(300),
					map((state: any) => {
						this.onStateChanges();

						if (state) {
							let names = this._filterNames(state);
							// if (typeof state === 'string' && state.length > 3 || state.name) {
							// 	let query = typeof state === 'string' ? state : state.name;
							// 	this.addressService.findPlace(query, "DOCTOR").pipe(map((place: any) => {
							// 		return place.map(j => {
							// 			return {
							// 				id: -1,
							// 				name: j.title,
							// 				label: j.description,
							// 				...j
							// 			};
							// 		})
							// 	})).subscribe(j => {
							// 		names.push(...j);
							// 	});
							// }

							return names;
						}
						return this.names.slice().sort(this.dataSortService.sortAlphabeticallyByKey('name'));
					}));

		this.subscribeZipCode();
		this.oldFormData = FormValueBuilder.getData(this.form);
		this.oldSourceNameData = this.nameCtrl.value ? this.nameCtrl.value : '';
	}

	hideDropDownOnscroll() {
		document.querySelector('.mat-dialog-container').addEventListener('scroll', () => {
			const input = document.querySelector('.mat-input-element');
			(<HTMLInputElement>input).blur();
			if (this.trigger) {
				this.trigger.closePanel();
			}
		});
	}

	getSslgId(){
		return this.componentData.sslgId;
	}

	onChange($event) {
		if ($event.model) {
			if ($event.model.id === 'type') {
				this.hideSpecialty($event.model.value);
			}
		}
	}

	onChangeDate($event) {
		//console.log($event);
		if($event.target){
			this.formDate['date'] = $event.target.value;
		}
	}

	isCreationPage(){
		return true;
	}

	visitsSwitchedOn(){
		this.formTemplate.visits.firstSeen.disabled = false;
		this.formTemplate.visits.lastSeen.disabled = false;
		this.formTemplate.visits.nextVisit.disabled = false;

	}

	visitsSwitchedOff(){
		this.formTemplate.visits.firstSeen.disabled = true;
		this.formTemplate.visits.lastSeen.disabled = true;
		this.formTemplate.visits.nextVisit.disabled = true;


	}

	subscribeZipCode() {
		this.subscription = this.formTemplate.contact.zip.valueUpdates.subscribe((j: string) => {
			if (j && j.length == 5) {

				this.addressService.findAddress(j).subscribe(addr => {
					if (addr && addr.state) {
						this.formTemplate.contact.state.valueUpdates.next(addr.state);
						this.formTemplate.contact.city.valueUpdates.next(addr.city);
					}
				});
			}
			this.subscribed = true;
		});
	}

	async setFormData() {

		setTimeout(() => {
			if (this.doctor !== null) {
				try {
					let street = this.doctor.contact.street;
					this.doctor.contact.street = street.replace(/,/g,'');
				}catch (e) {

				}

				FormValueBuilder.setData(this.doctor, this.formTemplate);
				setTimeout(() => {
					this.hideSpecialty(this.formTemplate.type.value as DoctorType);
				});
			}
		});
	}

	checkDisabledBtn(){
		if(!this.userInfo.getCurrentUserNameIsSystem()){
			this.disabledBtn = true;
		}else{
			this.disabledBtn = false;
		}
	}

	getBtnTitle(){
		let title = 'Add a New Medical Source';

		return title;
	}

	getBtnName(){
		return 'Add';
	}

	showName() {
		return true;
	}

	onBlur($event) {
	}



	hideSpecialty(typeValue: DoctorType) {
		setTimeout(() => {
			this.formTemplate.specialty.hidden = typeValue === DoctorType.PCP;
			this.formLayout.treat.element.control = typeValue === DoctorType.PCP ? '' : 'form-row';
			this.formLayout.treats.element.host = typeValue === DoctorType.PCP ? '' : 'form-element';
		});
	}

	onFocus($event) {
	}

	closeDoctorForm(cmd) {
		// this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
		// 	{queryParams: {page: this.route.snapshot.queryParams['page']}});
		// this._parent.hideHeader = false;
		// this.dialogRef.close();
		document.querySelectorAll('.mat-drawer-content').forEach(el => {
			(<HTMLElement>el).style.overflow = 'scroll';
		});
		this.dialogRef.close(cmd);
	}

	async saveDoctor(cmd) {
		let data;

		if (!this.nameCtrl.value) {
			return false;
		}

		this.formTemplate.name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
		data = FormValueBuilder.getData(this.formTemplate);
		data.name = typeof this.nameCtrl.value === 'string' ? this.nameCtrl.value : this.nameCtrl.value.name;
		if (typeof this.nameCtrl.value !== 'string') {
			data.id = this.nameCtrl.value.id;
		}


		if (!this.doctor) {
			data.googlePlaceId = this.nameCtrl.value.placeId ? this.nameCtrl.value.placeId : null
		}

		data.sslgId = this.sslgId;
		data.pageType = "new";

		const arrayModel = (<any>this.formGroup.controls['doctor']).controls['medicalCondition'];
		let cond = [];
		for(let i=0;i<arrayModel.controls.length;i++){
			let value = arrayModel.controls[i].value.value;
			if(value != "null" && value){
				cond.push(value);
			}
		}

		data.medicalConditions = cond.join('-||-');

		let nextVisit = data.visits.nextVisit;
		data.visits.nextVisit = this.dateService.getDateWithoutHours(nextVisit);

		let firstSeen = data.visits.firstSeen;
		data.visits.firstSeen = this.dateService.getDateWithoutHours(firstSeen);

		let lastSeen = data.visits.lastSeen;
		data.visits.lastSeen = this.dateService.getDateWithoutHours(lastSeen);

		const particularDate: any = {};
		if (this.dateLastWorked) {
			particularDate.dateLastWorked = this.dateLastWorked;
		} else if (this.dateIC) {
			particularDate.dateIC = this.dateIC;
		} else if (this.dateRecon) {
			particularDate.dateRecon = this.dateRecon;
		}

		// const doc = await this.medicalService.saveTreatingSourcesDoctor(this.sslgId, data, this.doctor);
		const typeClaimantAppeal = this.formInitialGroup.controls.levelSelection.value;
		const dateIc = +typeClaimantAppeal === +creationCheckBoxKeys.initialClaim ? this.dateService.getDateWithoutHours(this.formDate.date) : null;
		const dateRecon = +typeClaimantAppeal === +creationCheckBoxKeys.requestForReconsideration ? this.dateService.getDateWithoutHours(this.formDate.date) : null;
		const dateHrg = +typeClaimantAppeal === +creationCheckBoxKeys.requestForHearing ? this.dateService.getDateWithoutHours(this.formDate.date) : null;
		const treatment = this.medicalTestsData ? this.medicalTestsData.treatment : null;
		const medicalTests = this.medicalTestsData ? this.medicalTestsData.showMedicalTests : null;
		const medicalTestsList = this.medicalTestsData ? this.medicalTestsData.medicalTestsList : null;
		const medications = this.medicationsData ? this.medicationsData.showMedications : null;
		const medicationsList = this.medicationsData ? this.medicationsData.medications : null;


		// FOR TESTING FRONTEND
		// console.log(this.sslgId, data, this.doctor, typeClaimantAppeal, dateIc, dateRecon, dateHrg, treatment, medicalTests, medicalTestsList, this.medicalTestsData, medicationsList, medications);
		//
		// this.docNewVisitId = 70762;
		// this.resetPendingDate();
		// this.dialog.show("THANK YOU! You have successfully added this treating source to status star", ["ok"]);
		//
		// this.nameCtrl.setValue(null);
		// this.formGroup.reset();
		// this.doctor = null;
		// this.updateDoctorsList.next(Math.random());
		// this.changeTypeAskingStep(this.creationSteps.finished);
		// END FOR TESTING FRONTEND

		const doc = await this.medicalSourcesService.addTreatingSourcesDoctor(this.sslgId, data, typeClaimantAppeal, dateIc, dateRecon, dateHrg, treatment, medicalTests, medicalTestsList, medications, medicationsList);
		this.docNewVisitId = doc.id;
		this.resetPendingDate();
    this.dialog.show("THANK YOU! You have successfully added this treating source to status star", ["OK"]);
    this.nameCtrl.reset();
    this.formGroup.reset();
    this.doctor = null;
		this.updateDoctorsList.next(Math.random());
    this.changeTypeAskingStep(this.creationSteps.finished);
	}

	onStateChanges(): void {
		const dataNew = FormValueBuilder.getData(this.form);
		const sourceNew = this.nameCtrl.value ? this.nameCtrl.value : '';

		if(this.oldFormData != '' && this.oldSourceNameData != '' && dataNew && sourceNew){
			const isChanges = Functions.equals(this.oldFormData, dataNew);
			const isChangesSource = Functions.equals(this.oldSourceNameData, sourceNew);

			if (!isChanges || !isChangesSource) {
				this.state = State.after;
			} else {
				this.state = State.before;
			}
		}
	}

	isInputDirty() {
		return this.state == State.after;
	}

	isClaimant() {
		return this.userInfoService.isClaimant();
	}

	showSave(){
		// if(this.isClaimant() && this.condition!='new'){
		// 	this.visitsSwitchedOff();
		//
		// 	return false;
		// }

		return true;
	}

	isApp(){
		var w = window.innerWidth;

		if(w < 1100){
			return true;
		}

		return false;
	}

	initMedicalCondition(catalogItemId) {
		let medicalCondition = this.doctor.medicalConditions;
		let medicalConditions = medicalCondition.split("-||-");
		for (let i = 0; i < this.formTemplate.medicalCondition.groups.length; i++) {
			this.formTemplate.medicalCondition.removeGroup(i);
		}
		this.insertMedicalCondition();
		this.initMedicalConditions(medicalConditions);
	}
	initMedicalConditions(medicalConditions: any[]) {
		if (!medicalConditions)
			return;
		if (medicalConditions.length > 1) {
			for (let i = 1; i < medicalConditions.length; i++) {
				this.insertMedicalCondition();
			}
		}
		if (medicalConditions.length > 0)
			(<any>this.formGroup.controls['doctor']).controls['medicalCondition'].patchValue(medicalConditions.map(j => {
				return {value: j}
			}));
	}

	// Medical conditions
	insertMedicalCondition() {
		const arrayModel = this.formService.findById('medicalCondition', this.formModel) as DynamicFormArrayModel;
		this.formService.insertFormArrayGroup(arrayModel.groups.length, <any>(this.formGroup.controls['doctor'] as FormGroup)
			.controls['medicalCondition'], arrayModel);
	}

	remove(context: DynamicFormArrayModel, index) {
		this.formService.removeFormArrayGroup(index, <any>(this.formGroup.controls['doctor'] as FormGroup).controls['medicalCondition'], context);
	}

	showAddBtn(context: DynamicFormArrayModel, index) {
		if(this.isCreationPage() || !this.isClaimant()){
			return context.groups.length - 1 === index;
		}

		return false;
	}

	showRemoveBtn(context: DynamicFormArrayModel, index) {
		if(this.isCreationPage() || !this.isClaimant()){
			return context.groups.length > 1;
		}

		return false;
	}

	typeChanged(type) {
		if (type === 'DOCTOR') {
			this.form.doctor.hidden = false;
		} else {
			this.form.doctor.hidden = true;
		}
	}

	canAccess(){
		if(this.userInfoService.isClaimant() || this.userInfoService.isSslgStaff() || this.userInfoService.isStaff()){
			return true;
		}

		return false;
	}

	cancel(){
		this.closeDoctorForm({});
	}

	isUserTemp() {
		return this.userTempService.isTempUser();
	}

	changeTypeAskingStep(step) {
		this.typeAskingStep = step;
		if (this.isInitialStep()) {
			this.title = 'What type of claim or appeal are we filling on your behalf?';
		}
		if (this.isInitialFilingStep()) {
			this.formDate.date = new Date(this.doctorDates.dlw);
			this.title = 'Initial Claim Filing';
		}	if (this.isRequestForReconsiderationStep()) {
			this.formDate.date = new Date(this.doctorDates.ic);
			this.title = 'Request For Reconsideration';
		}	if (this.isRequestForHearingStep()) {
			this.formDate.date = new Date(this.doctorDates.recon);
			this.title = 'Request For Hearing';
		}	if (this.isNewDoctorStep()) {
			this.title = 'New Doctor';
		}	if (this.isMedicalTestsStep()) {
			const doctorName = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
			this.title = `Medical Tests <span>${doctorName}</span>`;
		} if (this.isNextDoctorCreatedStep()) {
			const doctorName = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
			this.title = `Medications <span>${doctorName}</span>`;
		}	if (this.isFinishedStep()) {
			this.title = `What would you like to do next?`;
		}
	}

	setPendingDate() {
		this.resetPendingDate();
		const date = this.formDate.date;
		if (this.isInitialFilingStep()) {
			this.dateLastWorked = date;
		} else if (this.isRequestForReconsiderationStep()) {
			this.dateIC = date;
		} else if (this.isRequestForHearingStep()) {
			this.dateRecon = date;
		}
	}

	resetPendingDate() {
		this.dateLastWorked = null;
		this.dateIC = null;
		this.dateRecon = null;
	}

	isInitialStep() {
		return this.typeAskingStep === creationSteps.initial;
	}

	isInitialFilingStep() {
		return this.typeAskingStep === creationSteps.initialFiling;
	}

	isRequestForReconsiderationStep() {
		return this.typeAskingStep === creationSteps.requestForReconsideration;
	}

	isRequestForHearingStep() {
		return this.typeAskingStep === creationSteps.requestForHearing;
	}

	isNewDoctorStep() {
		return this.typeAskingStep === creationSteps.newDoctor;
	}

	isMedicalTestsStep() {
		return this.typeAskingStep === creationSteps.medicalTests;
	}

	isNextDoctorCreatedStep() {
		return this.typeAskingStep === creationSteps.nextDoctorCreated;
	}

	isFinishedStep() {
		return this.typeAskingStep === creationSteps.finished;
	}


	goNext() {
		// const doc = await this.medicalService.saveTreatingSourcesDoctor(this.sslgId, data, this.doctor);
		// const doc = this.doctor;
		// this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'],
		// 	{
		// 		queryParams: {
		// 			returnUrl: this.route.snapshot.queryParams['returnUrl'],
		// 			catalogItemId: doc.id,
		// 			type: doc.type,
		// 		}
		// 	});
	}

	addHospital() {
		this.router.navigate(['/claimants/medical-treating-sources/' + this.sslgId + '/hospitals'],
			{
				queryParams: {
					returnUrl: this.route.snapshot.queryParams['returnUrl'],
					popup: 'new'
				}
			});
	}

	closeForm(text) {
		//console.log('close test');
	}

	onFormStateChanged($event) {
		//console.log('changed test', $event);
	}

	onAllFormStateChanged($event)  {
		//console.log('all changed test', $event);
	}

	async saveMedicalTest($event: any) {
		//console.log('save', $event);
		this.medicalTestsData = $event;
		this.saveDoctor('new', );
	}

	nextMedicalTest($event: any) {
		this.medicalTestsData = $event;
		this.changeTypeAskingStep(creationSteps.nextDoctorCreated);
	}

	async saveMedication($event: any) {
		this.medicationsData = $event;
		this.saveDoctor('new', );
	}

	goToSecondStep(type?) {
		const selectedStep = type ? type : this.formInitialGroup.controls.levelSelection.value;
		let selectedStepNum;
		switch (selectedStep) {
			case creationCheckBoxKeys.initialClaim :
				selectedStepNum = creationSteps.initialFiling;
				break;
			case creationCheckBoxKeys.requestForReconsideration :
				selectedStepNum = creationSteps.requestForReconsideration;
				break;
			case creationCheckBoxKeys.requestForHearing :
				selectedStepNum = creationSteps.requestForHearing;
				break;
			case creationCheckBoxKeys.returning :
				selectedStepNum = creationSteps.newDoctor;
				break;
		}
		this.changeTypeAskingStep(selectedStepNum);
	}

	async addAnotherDoctor() {
		this.names = await this.medicalService.getCatalog(CatalogItemType.DOCTOR).toPromise();
		this.formFinishedGroup.reset();
		this.formGroup.reset();
		this.nameCtrl.setValue({});
		this.changeTypeAskingStep(creationSteps.newDoctor);
	}

	addHospitalization() {
		this.closeDoctorForm('new');
		this.addHospital();
	}

	addADoctorsVisit() {
		this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'],
			{
				queryParams: {
					returnUrl: '/claimants/medical/' + this.sslgId + '/visit-summaries',
					catalogItemId: this.docNewVisitId,
					type: 'DOCTOR',
				}
			});
	}

	addOtherTreatingSource() {
    document.querySelectorAll('.mat-drawer-content').forEach(el => {
      (<HTMLElement>el).style.overflow = 'scroll';
    });
    this.dialogRef.close({});
    this.router.navigate(['/claimants/medical-treating-sources/' + this.sslgId + '/others'],
      {
        queryParams: {
          returnUrl: this.route.snapshot.queryParams['returnUrl'],
          popup: 'new'
        }
      });
	}

	returnToMyProfile() {
		this.closeDoctorForm('new');
	}

	isLevelRefStatusInitial() {
		return (this.levelRefValue === LEVEL_REF_ITEMS_ID.initial) || (this.levelRefValue === LEVEL_REF_ITEMS_ID.icPending);
	}

	isLevelRefStatusRecon() {
		return (this.levelRefValue === LEVEL_REF_ITEMS_ID.recon) || (this.levelRefValue === LEVEL_REF_ITEMS_ID.rcPending);
	}

	isLevelRefStatusHRG() {
		return (this.levelRefValue === LEVEL_REF_ITEMS_ID.hearing) || (this.levelRefValue === LEVEL_REF_ITEMS_ID.hrgPend) || (this.levelRefValue === LEVEL_REF_ITEMS_ID.hrgSched);
	}

	getSelectValue(item) {
		//console.log(item)
		// const answer = this.initialFormRadioOptions.find(choice => +choice.value === +item.answer);
		// return answer;
	}

	onRadioChange($event) {
		this.formInitialGroup.controls.levelSelection.setValue($event.value);
	}

	isRadioChecked(item, option) {
		//console.log(item, option)
	}

	isRadioDisabled(val) {
		if (val.value === 0) {
			return (this.isLevelRefStatusRecon() || this.isLevelRefStatusHRG());
		}
		if (val.value === 1) {
			return this.isLevelRefStatusInitial() || this.isLevelRefStatusHRG();
		}
		if (val.value === 2) {
			return this.isLevelRefStatusRecon() || this.isLevelRefStatusInitial();
		}
		return false;
	}

	test(date) {
		console.log(date);
	}
}
