<ng-container [formGroup]="group">

    <mat-checkbox #matCheckbox
                  [checked]="model.checked"
                  [color]="model.getAdditional('color', 'accent')"
                  [disableRipple]="model.getAdditional('disableRipple', RIPPLE_OPTIONS && RIPPLE_OPTIONS['disabled'] || false)"
                  [formControlName]="model.id"
                  [id]="elementId"
                  [indeterminate]="model.indeterminate"
                  [labelPosition]="model.labelPosition || 'after'"
                  [name]="model.name"
                  [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
                  (blur)="onBlur($event)"
                  (change)="onChange($event)"
                  (focus)="onFocus($event)"
                  (indeterminateChange)="onCustomEvent($event, 'indeterminateChange')">

        <span [ngClass]="getClass('element', 'label')" [innerHTML]="model.label"></span>

    </mat-checkbox>

</ng-container>
