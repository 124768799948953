import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormTamplate, FormValueBuilder} from '../../../../../partial/forms/form-tamplate';
import {FormGroup} from '@angular/forms';
import {
	DynamicFormControlLayout,
	DynamicFormControlModel,
	DynamicFormLayout,
	DynamicFormService
} from '@ng-dynamic-forms/core';
import {DATE_PICKER, RADIO} from '../../../../../../../@util/dynamic-form/dynamic-controlls';
import {MedicalService} from '../../../../../../@core/medical.service';
import {FormMode} from '../../../../../../@models/system-managment/form-mode';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../../../../../../../@util/dialog/dialog.service';
import {CatalogItemView} from '../../../../../../@models/medical/catalog-item-view';
import {DynamicForm} from "../../../../../../../@util/dynamic-form/dynamic-form";
import {FormClose} from "../../../../../partial/forms/form-close";
import {AuthenticationService} from "../../../../../../auth/_services/authentication.service";
import {
	DynamicFormGroupModel, DynamicSelectModel, DynamicTextAreaModel
} from "../../../../../../../@util/ui-material/form-controls";
import {DynamicFormArrayModel} from "@ng-dynamic-forms/core/public-api";
import {GlobalSaveService} from "../../../../../../@core/global-save.service";
import {UserInfoService} from "../../../../../../@core/user-info.service";
import {DateService} from "../../../../../../@core/functions/date.service";

@Component({
	selector: 'app-hospitals-visit',
	templateUrl: './hospitals-visit.component.html',
	styleUrls: ['./hospitals-visit.component.scss']
})
export class HospitalsVisitComponent extends DynamicForm implements FormTamplate, FormClose {
	@Output()
	backForm: EventEmitter<any> = new EventEmitter();
	@Output()
	nextForm: EventEmitter<any> = new EventEmitter();
	@Input()
	sslgId;
	@Output()
	closeForm: EventEmitter<any> = new EventEmitter();
	@Output()
	saveForm: EventEmitter<any> = new EventEmitter();
	@Input() isAnotherVisit: boolean = false;

	formLayout: DynamicFormLayout = {
		eRVisit: <DynamicFormControlLayout>{
			element: {
				label: 'form-label',
			}
		},
    erVisitG: <DynamicFormControlLayout>{
      element: {
        control: 'form-row',
        children: 'form-element',
        container: 'row-mb'
      }
    },
		col2Test: <DynamicFormControlLayout>{
			element: {
				control: 'form-row',
				children: 'form-element',
				container: 'row-mb'
			}
		},
		eRVisitDate: <DynamicFormControlLayout>{
			element: {
				host: 'form-element_hide-label'
			}
		},
		outPatient: <DynamicFormControlLayout>{
			element: {
				label: 'form-label',
				host: 'form-element'
			}
		},
		inPatientG1: <DynamicFormControlLayout>{
			element: {
				control: 'form-row',
				container: 'row-mb',
				children: 'form-element form-element_1-2 form_mb'
			}
		},
		inPatient_hospital: <DynamicFormControlLayout>{
			element: {
				label: 'form-label',
			}
		},
		inPatientDischarge_hospital: <DynamicFormControlLayout>{
			element: {
				host: ''
			}
		},
		outPatientG: <DynamicFormControlLayout>{
			element: {
				control: 'form-row',
				children: 'form-element',
				container: 'row-mb'
			}
		},
		outPatientDate: <DynamicFormControlLayout>{
			element: {
				host: 'form-element_hide-label'
			}
		},
		inPatientG2: <DynamicFormControlLayout>{
			element: {
				control: 'form-row form-row_wrap',
				container: 'row-mb',
				children: 'form-element form-element_1-2 form_mb'
			}
		},
		visitDate: <DynamicFormControlLayout>{
			grid: {
				container: 'row-mb half-width'
			}
		},
		hospitalizationReason: <DynamicFormControlLayout>{
			grid: {
				container: 'row-mb',
				control: 'column-items'
			}
		},
		conditions: <DynamicFormControlLayout>{
			grid: {
				container: 'row-mb',
				control: 'column-items'
			}
		},
		treatmentDetails: <DynamicFormControlLayout>{
			grid: {
				container: 'row-mb',
				control: 'column-items'
			}
		},
		treatmentDetails1: <DynamicFormControlLayout>{
			grid: {
				container: 'row-mb',
				control: 'column-items'
			}
		},
		treatedDoctor: <DynamicFormControlLayout>{
			grid: {
				container: 'row-mb',
				control: 'column-items'
			}
		},
	};
	formGroup: FormGroup;
	formModel: DynamicFormControlModel[];
	title: string = 'Medical Visits Summaries';

	hospitalForm = {
		catalogItemId: DynamicSelectModel({
			id: 'catalogItemId',
			label: 'Name',
			required: true,
			validators: {
				required: null
			},
			errorMessages: {
				required: 'Required'
			},
			value: '', editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		}),
		eRVisit: RADIO({id: 'eRVisit', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], label: 'ER visit'}),
		eRVisitDate: DATE_PICKER({id: 'eRVisitDate', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], label: ''}),
		outPatient: RADIO({id: 'outPatient', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], value: false, label: 'Outpatient'}),
		outPatientDate: DATE_PICKER({id: 'outPatientDate', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], disabled: true, label: ''}),
		inPatient: RADIO({id: 'inPatient_hospital', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], value: false, label: 'Inpatient'}),
		inPatientAdmission: DATE_PICKER({
			id: 'inPatientAdmission_hospital',
			editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
			label: 'Admission',
			disabled: true,
			required: undefined
		}),
		inPatientDischarge: DATE_PICKER({
			id: 'inPatientDischarge_hospital',
			editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
			label: 'Discharge',
			disabled: true,
			required: undefined
		}),
		hospitalizationReason: DynamicTextAreaModel({
			id: 'hospitalizationReason', editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
			label: 'Reason for Hospitalization',
			maxLength: 512
		}),
		conditions: DynamicTextAreaModel({
			id: 'conditions', editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
			label: 'Medical Conditions or Diagnoses',
			maxLength: 512
		}),
		treatmentDetails: DynamicTextAreaModel({
			id: 'treatmentDetails', editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
			label: 'Details of Treatment',
			maxLength: 512
		}),
		treatedDoctor: DynamicTextAreaModel({
			id: 'treatedDoctor',
			editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
			label: 'Doctors Treated By',
			maxLength: 512
		}),
	};

	// form = {
	// 	// contactPerson: STRING_FIELD({id: 'contactPerson', label: 'Person to contact', maxLength: 32}),
	// 	//visitDate: DATE_PICKER({id: 'visitDate', label: 'Visit Date', editableFor: ["EXECUTIVE_STAFF", "CLIENT"],}),
	// 	hospital: DynamicFormGroupModel({
	// 		id: 'hospital', editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
	// 		group: [
	// 			DynamicFormGroupModel({
	// 				id: 'col1',
	// 				editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
	// 				group: [
	// 					DynamicFormGroupModel({
	// 						id: 'col2Test',
	// 						group: [
	// 							this.hospitalForm.eRVisit,
	// 							this.hospitalForm.eRVisitDate
	// 						], editableFor: ["EXECUTIVE_STAFF", "CLIENT"]}),
	// 					DynamicFormGroupModel({
	// 						id: 'col1Test',
	// 						group: [
	// 							this.hospitalForm.inPatient,
	// 							this.hospitalForm.inPatientAdmission,
	// 						], editableFor: ["EXECUTIVE_STAFF", "CLIENT"]}),]
	// 			}),
	// 			DynamicFormGroupModel({
	// 				id: 'col2',
	// 				editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
	// 				group: [
	// 					DynamicFormGroupModel({
	// 						id: 'erVisitG', editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
	// 						group: [this.hospitalForm.outPatient, this.hospitalForm.outPatientDate]
	// 					}),
	// 					DynamicFormGroupModel({
	// 						id: 'inPatientG1', editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
	// 						group: [this.hospitalForm.inPatientDischarge]
	// 					})
	// 				]
	// 			}),
	// 		]
	// 	})
	// };
	form = {
		// contactPerson: STRING_FIELD({id: 'contactPerson', label: 'Person to contact', maxLength: 32}),
		//visitDate: DATE_PICKER({id: 'visitDate', label: 'Visit Date', editableFor: ["EXECUTIVE_STAFF", "CLIENT"],}),
		hospital: DynamicFormGroupModel({
			id: 'hospital', editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
			group: [
				DynamicFormGroupModel({
					id: 'col1',
					editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
					group: [
						DynamicFormGroupModel({
							id: 'col2Test',
							group: [
								this.hospitalForm.eRVisit,
								this.hospitalForm.eRVisitDate
							], editableFor: ["EXECUTIVE_STAFF", "CLIENT"]}),
						]
				}),
				DynamicFormGroupModel({
					id: 'col2',
					editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
					group: [
						DynamicFormGroupModel({
							id: 'erVisitG', editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
							group: [this.hospitalForm.outPatient, this.hospitalForm.outPatientDate]
						}),
					]
				}),
				DynamicFormGroupModel({
					id: 'col3',
					editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
					group: [
						DynamicFormGroupModel({
							id: 'inPatientG1', editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
							group: [
								this.hospitalForm.inPatient,
								this.hospitalForm.inPatientAdmission,
								this.hospitalForm.inPatientDischarge
							]
						})
					]
				}),
			]
		})
	};
	ctatlogItems;

	constructor(private formService: DynamicFormService,
							private medicalService: MedicalService,
							private router: Router,
							private dialog: DialogService,
							private route: ActivatedRoute,
							public globalSaveService: GlobalSaveService,
							private userInfoService: UserInfoService,
							private dateService: DateService,
							public auth: AuthenticationService) {
		super();

		this.formModel = FormValueBuilder.buildFormModel(this.form);
		this.formGroup = formService.createFormGroup(this.formModel);
	}

	showAddBtn(context: DynamicFormArrayModel, index) {
		return context.groups.length - 1 === index;
	}

	showRemoveBtn(context: DynamicFormArrayModel, index) {
		return context.groups.length > 1;
	}

	async ngOnInit() {}

	onBlur($event) {
	}

	onChange($event) {
		this.initRelations($event.model.id, $event.control.value);
	}

	initRelations(controlId, controlValue) {
		switch (controlId) {
			case  this.hospitalForm.eRVisit.id:
				this.hospitalForm.eRVisitDate.disabledUpdates.next(!controlValue);
				this.hospitalForm.eRVisitDate.valueUpdates.next(null);

				this.hospitalForm.outPatient.valueUpdates.next(false);
				this.hospitalForm.outPatientDate.disabledUpdates.next(true);
				this.hospitalForm.outPatientDate.valueUpdates.next(null);

				this.hospitalForm.inPatient.valueUpdates.next(false);
				this.hospitalForm.inPatientDischarge.disabledUpdates.next(true);
				this.hospitalForm.inPatientDischarge.valueUpdates.next(null);
				this.hospitalForm.inPatientAdmission.disabledUpdates.next(true);
				this.hospitalForm.inPatientAdmission.valueUpdates.next(null);
				break;
			case this.hospitalForm.outPatient.id:
				this.hospitalForm.outPatientDate.disabledUpdates.next(!controlValue);
				this.hospitalForm.outPatientDate.valueUpdates.next(null);

				this.hospitalForm.inPatient.valueUpdates.next(false);
				this.hospitalForm.inPatientDischarge.disabledUpdates.next(true);
				this.hospitalForm.inPatientDischarge.valueUpdates.next(null);
				this.hospitalForm.inPatientAdmission.disabledUpdates.next(true);
				this.hospitalForm.inPatientAdmission.valueUpdates.next(null);
				this.hospitalForm.eRVisit.valueUpdates.next(false);
				this.hospitalForm.eRVisitDate.disabledUpdates.next(true);
				this.hospitalForm.eRVisitDate.valueUpdates.next(null);
				break;
			case this.hospitalForm.inPatient.id:
				this.hospitalForm.inPatientDischarge.disabledUpdates.next(!controlValue);
				this.hospitalForm.inPatientDischarge.valueUpdates.next(null);
				this.hospitalForm.inPatientAdmission.disabledUpdates.next(!controlValue);
				this.hospitalForm.inPatientAdmission.valueUpdates.next(null);

				this.hospitalForm.eRVisit.valueUpdates.next(false);
				this.hospitalForm.eRVisitDate.disabledUpdates.next(true);
				this.hospitalForm.eRVisitDate.valueUpdates.next(null);

				this.hospitalForm.outPatient.valueUpdates.next(false);
				this.hospitalForm.outPatientDate.disabledUpdates.next(true);
				this.hospitalForm.outPatientDate.valueUpdates.next(null);
				break;
		}
	}

	onFocus($event) {
		////console.log($event);
	}

	closeVisitForm() {
		this.closeForm.emit();
	}

	saveVisitForm() {
		FormValueBuilder.setControlsToched(this.formGroup);
		if (!this.formGroup.valid) {
			return;
		}
		let rez;
		rez = {
			...FormValueBuilder.getData(this.hospitalForm)
		};
		rez.visitDate = new Date();
		rez.catalogItemId = this.hospitalForm.catalogItemId.value;
		rez.inPatientAdmission = this.dateService.getDateWithoutHours(rez.inPatientAdmission);
		rez.inPatientDischarge = this.dateService.getDateWithoutHours(rez.inPatientDischarge);
		rez.outPatientDate = this.dateService.getDateWithoutHours(rez.outPatientDate);
		rez.eRVisitDate = this.dateService.getDateWithoutHours(rez.eRVisitDate);

		console.log('save', {data: rez});
		return rez;
		// TODO State before
		// this.reset();
	}

	ngOnDestroy(): void {
		this.formStateChanged.next({value: false, key: this.formKey});
	}

	isDirty() {
		return FormValueBuilder.isDirty(this.hospitalForm) ||
			     FormValueBuilder.isDirty(this.form);
	}

	reset() {
		FormValueBuilder.resetToDefault(this.hospitalForm);
		FormValueBuilder.resetToDefault(this.form);
	}

	isClaimant() {
		if(this.userInfoService.getCurrentUserRole() == 'CLIENT'){
			return true;
		}
		return false;
	}

	backHospitalsVisit() {
		this.backForm.emit();
	}

	nextHospitalsVisit() {
		this.nextForm.emit(this.saveVisitForm());
	}
}
