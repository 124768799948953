import {
	AfterViewInit,
	Component,
	ContentChildren,
	EventEmitter,
	Input,
	OnInit,
	Output,
	QueryList,
	ViewChild
} from '@angular/core';
import {DynamicTableModel} from "../dynamic-table.model";
import {CellId} from "../cellId";
import {MatSelect, MatSelectChange} from "@angular/material";
import {PromotionsService} from "../../../app/@core/promotions.service";
import {ActivatedRoute} from "@angular/router";

@Component({
	selector: 'irs-dynamic-table',
	templateUrl: './irs-dynamic-table.component.html',
	styleUrls: ['./irs-dynamic-table.component.scss']
})
export class IrsDynamicTableComponent implements OnInit, AfterViewInit {

	@Input()
	inOneLine: boolean = false;
	@Input()
	scrollX: boolean = false;
	@Input()
	tableData;
	@Input()
	columnData: DynamicTableModel<any>[];
	@Input()
	descriptionRow: boolean = false;
	@Input()
	rowClasses: Function;

	@Output()
	rowClick = new EventEmitter<any>();

	@Output()
	cellClick = new EventEmitter<{ cell: string, value: any }>();

	@Output()
	selectChange = new EventEmitter<{ status: number, id: number }>();

	@ContentChildren(CellId)
	private contentTemplateList: QueryList<CellId>;

	showingStepsUserId = null;

	constructor(private promotionsService: PromotionsService,
							private route: ActivatedRoute,
							) {
		if (this.route.snapshot.queryParams.messaging) {
			this.showStepsInfo({id: this.promotionsService.activeUser});
		}
	}

	ngOnInit() {
	}

	ngAfterViewInit(): void {
	}

	getClassList() {
		return `i-table ${this.inOneLine ? 'one-line' : ''} ${this.scrollX ? 'scroll-x' : ''}`
	}

	getCdkHeaderRowDef(columnData) {
		let arr = [];
		if (columnData) {
			columnData.forEach(item => {
				arr.push(item.column_key);
			});
		}
		return arr;
	}

	getCellValue(elem, column) {
		let val = column.displayFn ? column.displayFn(elem) : elem[column.column_key] ? elem[column.column_key] : '';
		return val;
	}

	getTemplateRef(headerCell: string) {
		if (!this.contentTemplateList.find(i => i.modelType === headerCell)) return false;
		return this.contentTemplateList.find(i => i.modelType === headerCell).templateRef;
	}

	getTemplateRefByColumnKey(columnKey: string) {
		if (!this.contentTemplateList.find(i => i.modelId === columnKey)) return false;
		return this.contentTemplateList.find(i => i.modelId === columnKey).templateRef;
	}

	clickTableRow(elem) {
		this.rowClick.next(elem);
	}

	onCellClick(key: any, elem: any) {
		this.cellClick.emit({cell: key, value: elem});
	}

	getTitleCellValue(elem, column) {
		return column.displayTitleFn ? column.displayTitleFn(elem) : elem[column.column_key] ? elem[column.column_key] : '';
	}

	isRowWrap() {
		return !!this.columnData.find(j => j.column_type == 'text-row');
	}

	isHiddenCol() {
		return !!this.columnData.find(j => j.column_classes && j.column_classes.includes('hidden'));
	}

	getRowClasses(row) {
		return (this.rowClasses ? this.rowClasses(row) + (this.isRowWrap() ? ' flex-wrap i-tr' : ' i-tr') : (this.isRowWrap() ? ' flex-wrap i-tr' : ' i-tr'))
	}

	onSelectChange(event: MatSelectChange, elem) {
		const matSelect: MatSelect = event.source;
		this.selectChange.emit({status: event.value, id: elem.id});
		matSelect.writeValue(elem.status);
	}

	getSelectValue(elem) {
		return elem.status;
	}

	getProgress(elem) {
		const finished = elem.finished_blocks;
		const total = elem.total_blocks;
		if (Number.isInteger(+finished) && Number.isInteger(+total)) {
			return `${finished} of ${total}`;
		}
		return '';
	}

	identify(index, item) {
		return item.status;
	}

	showStepsInfo(elem, item?) {
		this.showingStepsUserId = elem.id;
		// this.showingStepsUserId === null ? this.showingStepsUserId = elem.id : this.showingStepsUserId = null;
	}

	getTabs(item, elem) {
		return item.column_selectValues;
	}

	isStatusHidden(classes) {
		// console.log(classes, classes.includes('hidden'));
		return classes.includes('hidden');
	}
}
