<app-frame [overlay]="false" [title]="title" [showCloseButton]="false" autocomplete="off">
	<div class="main-content__wrapper">
		<div class="controls">
			<div class="show-archived">
				<div>
					<nav mat-tab-nav-bar>
						<a mat-tab-link *ngFor="let link of navLinks; let i = index"
						   (click)="showBlock(i)"
						   [active]="blockTabActive(i)">
							<b>
								{{link.label}}
							</b>
						</a>
					</nav>
				</div>
			</div>
		</div>
	</div>

	<div id="block-tab-info" class="blocks-tab">
		<form *ngIf="this.globalSaveService.checkDisabledFields(this.form)" class="form-horizontal" [formGroup]="formGroup" novalidate>
<!--			<mat-form-field *ngIf="showName()" class="name_input" [ngClass]="{'dirty': isInputDirty()}">-->
<!--				<input required matInput placeholder="Name" aria-label="Name" [matAutocomplete]="auto"-->
<!--					   [formControl]="nameCtrl">-->
<!--				<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">-->
<!--					<mat-option [class.grey]="!name.label" *ngFor="let name of filteredNames | async" [value]="name"-->
<!--								[matTooltip]="name.label ? name.label : null">-->
<!--						<span [class.grey]="!name.label">{{name.name}}</span>-->
<!--					</mat-option>-->
<!--				</mat-autocomplete>-->
<!--			</mat-form-field>-->
			<dynamic-material-form [group]="formGroup"
								   [layout]="formLayout"
								   [model]="formModel"
								   (blur)="onBlur($event)"
								   (change)="onChange($event); onStateChanges()"
								   (focus)="onFocus($event)">
			</dynamic-material-form>
		</form>

		<div *ngIf="showBtn()">
			<app-form-actions-content *appCanAccess="['EXECUTIVE_STAFF','SSLG_STAFF','CLIENT']">
				<form-button
					*ngIf="showName()"
					(clickOnButton)="addVisit()"
					title="Add Visit"
					buttonType="save">
					Add Visit
				</form-button>
				<form-button *ngIf="showName()" (clickOnButton)="saveDoctor('new')" buttonType="save"
							 title="Update a Medical Source">
					Update
				</form-button>
			</app-form-actions-content>
		</div>
	</div>

	<div id="block-tab-requests" class="blocks-tab" style="display: none">
		<app-medical-requests
				[page]="'Other'"
				[contacts]="otherContacts"
		></app-medical-requests>
	</div>

	<div id="block-tab-payments" class="blocks-tab" style="display: none">
		<app-medical-payments></app-medical-payments>
	</div>
</app-frame>
