import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { DynamicFormService, } from '@ng-dynamic-forms/core';
import { FormValueBuilder } from '../../../../partial/forms/form-tamplate';
import { FormMode } from '../../../../../@models/system-managment/form-mode';
import { RADIO_USER_STATE, STRING_FIELD } from '../../../../../../@util/dynamic-form/dynamic-controlls';
import { MedicalService } from '../../../../../@core/medical.service';
import { DynamicForm } from "../../../../../../@util/dynamic-form/dynamic-form";
import { AuthenticationService } from "../../../../../auth/_services/authentication.service";
import { DynamicFormArrayModel as DynamicFormArray, DynamicTextAreaModel } from "../../../../../../@util/ui-material/form-controls";
import { GlobalSaveService } from "../../../../../@core/global-save.service";
import { UserInfoService } from "../../../../../@core/user-info.service";
import { DateService } from "../../../../../@core/functions/date.service";
import { DialogService } from "../../../../../../@util/dialog/dialog.service";
export class MedicalTreatingSourcesMedicationsComponent extends DynamicForm {
    constructor(formService, medicalService, globalSaveService, userInfoService, dialog, dateService, auth) {
        super();
        this.formService = formService;
        this.medicalService = medicalService;
        this.globalSaveService = globalSaveService;
        this.userInfoService = userInfoService;
        this.dialog = dialog;
        this.dateService = dateService;
        this.auth = auth;
        this.medications = [];
        this.closeForm = new EventEmitter();
        this.saveForm = new EventEmitter();
        this.backForm = new EventEmitter();
        this.title = 'Medications';
        this.formLayout = {
            'showMedications': {
                element: {
                    container: 'top-label'
                }
            },
            medications: {
                element: {
                    label: 'form-label form-label_full-width form-label_mb',
                    group: 'row-mb row row--padding'
                }
            },
            period: {
                element: {
                    control: 'form-row form-row_wrap',
                    container: 'row-mb',
                    children: 'form-element form-element_1-2 form_mb'
                }
            },
            medication: {
                element: {
                    container: 'row-mb',
                    host: 'col-12'
                }
            },
            reason: {
                element: {
                    container: 'row-mb',
                    host: 'col-12'
                }
            },
            sideEffects: {
                element: {
                    container: '',
                    host: 'col-12'
                }
            }
        };
        this.form = {
            showMedications: RADIO_USER_STATE({ id: 'showMedications', options: [{ label: 'YES', value: true }, { label: 'NO', value: false }], editableFor: ["EXECUTIVE_STAFF", "CLIENT"] }),
            medications: DynamicFormArray({
                id: 'medications',
                initialCount: 2,
                groupFactory: () => {
                    return [
                        STRING_FIELD({ id: 'id', hidden: true, editableFor: ["EXECUTIVE_STAFF", "CLIENT"], }),
                        STRING_FIELD({ id: 'medication', label: 'Medication', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], }),
                        DynamicTextAreaModel({ id: 'reason', label: 'Reason for Taking', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], }),
                        DynamicTextAreaModel({ id: 'sideEffects', label: 'Side Effects', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], }),
                    ];
                }, editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
            })
        };
        this.isMedicationsVisible = false;
        this.formModel = FormValueBuilder.buildFormModel(this.form);
        this.formGroup = formService.createFormGroup(this.formModel);
        this.arrayControl = this.formGroup.controls['medications'];
        this.arrayModel = this.formService.findById('medications', this.formModel);
    }
    set sourceIdValue(id) {
        if (this.sourceId !== id) {
            this.sourceId = id;
            setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let catalogItem = yield this.medicalService.getCatalogItemById(this.sourceId, this.sslgId).toPromise();
                this.prescribedBy = catalogItem.name;
                this.setPrescribedValue();
            }));
        }
        else {
            setTimeout(() => {
                this.setPrescribedValue();
            });
        }
    }
    insert() {
        console.log(this.arrayControl, this.arrayModel);
        this.formService.addFormArrayGroup(this.arrayControl, this.arrayModel);
        setTimeout(() => {
            console.log(this.arrayModel, this.arrayModel.groups, this.arrayModel.groups[this.arrayModel.groups.length - 1], this.arrayModel.groups[this.arrayModel.groups.length - 1].group[5]);
            this.arrayModel.groups[this.arrayModel.groups.length - 1].group[5].valueUpdates.next(this.prescribedBy);
            FormValueBuilder.resetToDefault(this.arrayModel.groups[this.arrayModel.groups.length - 1].group);
        });
    }
    backMedications() {
        this.backForm.emit();
    }
    insertDouble() {
        this.insert();
        this.insert();
    }
    remove(index) {
        if (this.canRemove()) {
            this.formService.removeFormArrayGroup(index, this.arrayControl, this.arrayModel);
        }
    }
    removeDouble(index) {
        if (this.canRemove()) {
            this.dialog.show('Are you sure you want to delete this medications row?', ['OK', 'CANCEL'])
                .onCloseDialog.subscribe(j => {
                if (j === 'OK') {
                    this.remove(index);
                    this.remove(index - 1);
                }
            });
        }
    }
    isRemoveHidden() {
        return this.arrayModel.groups.length < 3;
    }
    canRemove() {
        return this.arrayModel.groups.length > 1;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.form.showMedications.valueUpdates.subscribe(j => {
                this.isMedicationsVisible = !!j;
                this.isMedicationsVisible ? document.querySelector('#medications').classList.remove('is-hidden') : document.querySelector('#medications').classList.add('is-hidden');
            });
            this.setNewVisit();
            FormValueBuilder.resetToDefault(this.form);
            this.formGroup.valueChanges.subscribe(j => {
                this.formStateChanged.emit({
                    key: this.formKey,
                    value: FormValueBuilder.isDirty(this.form) || this.formGroup.value.medications.length < this.medications.length
                });
            });
            setTimeout(() => {
                this.formGroup.controls.showMedications.setValue(false);
            });
        });
    }
    onBlur($event) {
    }
    onChange($event) {
        console.log($event);
        if (($event.model.id === 'startDate' || $event.model.id === 'endDate') &&
            $event.group.controls['startDate'].value !== null && $event.group.controls['endDate'].value !== null &&
            Date.parse($event.group.controls['startDate'].value) > Date.parse($event.group.controls['endDate'].value)) {
            setTimeout(() => {
                $event.group.controls['endDate'].patchValue(new Date(Date.parse($event.group.controls['startDate'].value)));
            });
        }
        if ($event.model.id === 'regular') {
            if ($event.model.value) {
                setTimeout(() => {
                    $event.group.controls['endDate'].setValidators(null);
                    $event.context.group[8].hidden = true;
                    $event.context.group[8].required = false;
                    $event.context.group[8].valueUpdates.next(null);
                });
            }
            else {
                $event.context.group[8].hidden = false;
                $event.context.group[8].required = true;
                $event.context.group[8].validators = { required: null };
                setTimeout(() => {
                    $event.group.controls['endDate'].setValidators([Validators.required]);
                });
            }
        }
    }
    onFocus($event) {
    }
    closeMedications() {
        this.closeForm.emit();
    }
    saveMedication() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let medications = this.formGroup.value.medications ? this.formGroup.value.medications : [];
            medications = medications.filter(el => el.medication);
            let newFormGroupMedication = medications.map(j => {
                //j.startDate =  this.dateService.getDateWithoutHours(j.startDate);
                //j.endDate =  this.dateService.getDateWithoutHours(j.endDate);
                if (j.medication) {
                    return {
                        name: j.medication,
                        reason: j.reason,
                        sideEffects: j.sideEffects,
                    };
                }
            });
            FormValueBuilder.setControlsToched(this.formGroup);
            if (!this.formGroup.valid) {
                return;
            }
            let data = { showMedications: !!this.formGroup.value.showMedications };
            if (this.formGroup.value.showMedications) {
                data.medications = newFormGroupMedication;
            }
            this.saveForm.emit(data);
            // this.medicalService.setMedication(this.sslgId, this.visitId, newFormGroupMedication).subscribe(rez => {
            // 	FormValueBuilder.resetToDefault(this.form);
            // 	this.formStateChanged.emit({key: this.formKey, value: FormValueBuilder.isDirty(this.form)});
            // 	this.allFormStateChanged.emit(State.after);
            // });
        });
    }
    getButtonType() {
        return this.canRemove() ? 'warning' : 'disabled';
    }
    setPrescribedValue() {
        this.arrayModel.groups.forEach(item => {
            item.group[5].valueUpdates.next(this.prescribedBy);
            FormValueBuilder.resetToDefault(item.group[5]);
        });
    }
    setNewVisit() {
        if (this.mode === FormMode.EditMode && this.medications && this.medications.length > 0) {
            let newFormGroupMedication = this.medications.map(j => {
                return {
                    genericForm: j.genericForm,
                    id: j.id,
                    name: j.name,
                    reason: j.reason,
                    regular: j.regular,
                    sideEffects: j.sideEffects,
                    startDate: j.period.startDate,
                    endDate: j.period.endDate
                };
            });
            if (newFormGroupMedication.length !== this.form.medications.groups.length)
                newFormGroupMedication.forEach(j => this.insert());
            newFormGroupMedication.filter(j => !j.period).forEach(j => j.period = { startDate: null, endDate: null });
            this.formGroup.controls.medications.patchValue(newFormGroupMedication);
            newFormGroupMedication.forEach((item, indx) => {
                if (item.regular) {
                    setTimeout(() => {
                        this.arrayModel.groups[indx].group[8].hidden = true;
                        this.arrayModel.groups[indx].group[8].required = true;
                        this.arrayModel.groups[indx].group[8].valueUpdates.next(null);
                        this.arrayModel.groups[indx].group[8].hidden = true;
                        this.formGroup.controls['medications'].controls[indx].controls['endDate'].setValidators(null);
                    });
                }
            });
        }
    }
    ngOnDestroy() {
        this.formStateChanged.next({ value: false, key: this.formKey });
    }
    isClaimant() {
        if (this.userInfoService.getCurrentUserRole() == 'CLIENT') {
            return true;
        }
        return false;
    }
}
