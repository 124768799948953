import {Component, forwardRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
	DynamicFormControlLayout,
	DynamicFormControlModel,
	DynamicFormService,
} from '@ng-dynamic-forms/core';
import {
	ADDRESS,
	CYTY, EXT,
	PHONE, PRESS,
	STATE_SELECT,
	STRING_FIELD, ZIP
} from '../../../../../../../@util/dynamic-form/dynamic-controlls';
import {MedicalService} from '../../../../../../@core/medical.service';
import {FormValueBuilder} from '../../../../../partial/forms/form-tamplate';
import {OtherItem} from '../../../../../../@models/medical/othe-item';
import {MedicalTreatingSourcesComponent} from '../../medical-treating-sources.component';
import {ActivatedRoute, Router} from '@angular/router';
import {map, startWith} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {CatalogItemType} from '../../../../../../@models/medical/catalog-item-view';
import {NavigationService} from "../../../../../../@core/navigation.service";
import {AddressService} from "../../../../../../@core/address.service";
import {State} from "../../../../../../test-new-stepper-form/test-form/on-changes";
import {Functions} from "../../../../../../../@util/functions";
import {DynamicFormGroupModel} from "../../../../../../../@util/ui-material/form-controls";
import {MedicalRequestsPaymentsComponent} from "../../medical-requests-payments/medical-requests-payments.component";
import {UserInfoService} from "../../../../../../@core/user-info.service";
import {GlobalSaveService} from "../../../../../../@core/global-save.service";

@Component({
	selector: 'app-hospital-form',
	templateUrl: './othe-form.component.html'
})
export class OtheFormComponent implements OnInit, OnDestroy {
	@ViewChild(MedicalRequestsPaymentsComponent, {static: false}) medicalRequestsPaymentsComponent: MedicalRequestsPaymentsComponent;

	title = 'New Other';
	formGroup: FormGroup;
	formModel: DynamicFormControlModel[];
	formLayout = {
		treat: <DynamicFormControlLayout>{
			element: {
				control: 'form-row'
			}
		},
		treats: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		speciality: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		type: <DynamicFormControlLayout>{
			element: {
				label: 'form-label'
			}
		},
		phones: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb',
				control: 'form-row',
			}
		},
		contact_phone: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_x2'
			}
		},
		contact_press: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		contact_ext: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		contact_fax: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb'
			}
		},
		contact_address: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb'
			}
		},
		contact_address2: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb'
			}
		},
		contact_cyti: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb'
			}
		},
		state_zip: <DynamicFormControlLayout>{
			element: {
				control: 'form-row',
				container: 'row-mb'
			}
		},
		contact_state: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		contact_zip: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		callerPhones: <DynamicFormControlLayout>{
			element: {
				control: 'form-row',
			}
		},
		callerPhone: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_x2'
			}
		},
		callerPress: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		callerExt: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
	};
	private other: OtherItem;
	formTemplate = {
		name: STRING_FIELD({id: 'name', label: 'Name', hidden: true}),
		recipient: {
			recipientName: STRING_FIELD({
				id: 'recipientName',
				label: 'Person To Contact',
				maxLength: 128,
				required: true,
				validators: {required: null}
			}),
		},
		contact: {
			phone: PHONE({id: 'contact_phone', label: 'Phone'}),
			press: PRESS({id: 'contact_press', label: '"Press"'}),
			ext: EXT({id: 'contact_ext', label: 'Ext.'}),
			fax: PHONE({id: 'contact_fax', label: 'Business Fax'}),
			street: ADDRESS({id: 'contact_address'}),
			street2: ADDRESS({id: 'contact_address2', label: 'Address 2'}),
			city: CYTY({id: 'contact_cyti'}),
			state: STATE_SELECT({id: 'contact_state'}),
			zip: ZIP({id: 'contact_zip'}),
		}
	};
	form = {
		name: this.formTemplate.name,
		recipientName: this.formTemplate.recipient.recipientName,
		contact: DynamicFormGroupModel({
			id: 'contact',
			group: [
				DynamicFormGroupModel({
					id: 'phones',
					group: [
						this.formTemplate.contact.phone,
						this.formTemplate.contact.press,
						this.formTemplate.contact.ext
					]
				}),
				this.formTemplate.contact.fax,
				DynamicFormGroupModel({
					id: 'state_zip',
					group: [
						this.formTemplate.contact.zip,
						this.formTemplate.contact.state,
					]
				}),
				this.formTemplate.contact.city,
				this.formTemplate.contact.street,
				this.formTemplate.contact.street2,
			]
		}),
	};
	nameCtrl = new FormControl();
	filteredNames: Observable<{ id: number, name: string, label?: string }[]>;
	names: { id: number, name: string, label?: string }[] = [];
	private sslgId: any;
	private sub: Subscription;
	subscription;
	subscribed = true;
	state: State = State.before;
	oldFormData;
	oldSourceNameData;

	navLinks: ({ id: string; label: string })[];
	tabNavigationActive = 0;
	otherContacts;

	constructor(private medicalService: MedicalService, private formService: DynamicFormService,
				private addressService: AddressService,
				private userInfoService: UserInfoService,
				public globalSaveService: GlobalSaveService,
				// private dialogRef: MatDialogRef<OtheFormComponent>,
				// @Inject(MAT_DIALOG_DATA) private data: { sslgId: string, mode: FormMode, id?: number }
				@Inject(forwardRef(() => MedicalTreatingSourcesComponent)) private _parent: MedicalTreatingSourcesComponent,
				private route: ActivatedRoute, private router: Router, private navService: NavigationService) {

		this.formModel = FormValueBuilder.buildFormModel(this.form);
		this.formGroup = this.formService.createFormGroup(this.formModel);
		// this.formGroup.addControl('name', this.nameCtrl);
		this.nameCtrl.setValidators(Validators.required);
		this.sub = navService.back().subscribe(j => this.closeDoctorForm());

	}

	ngOnDestroy(): void {
		this._parent.hideHeader = false;
		this.sub.unsubscribe();
	}


	private _filterNames(value): { id: number, name: string }[] {
		////console.log(value);
		const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name ? value.name.toLowerCase() : '';
		////console.log(filterValue);
		return this.names.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
	}

	displayFn(item?: { id: number, name: string }): string | undefined {
		return item ? item.name : undefined;
	}

	showName() {
		if(this.globalSaveService.getStatusTrue() && !this.isClaimant()){
			return true;
		}

		return this.route.snapshot.url[1].path === 'new'
	}

	showBtn(){
		if(!this.globalSaveService.getStatusTrue() && !this.isClaimant()){
			return false;
		}

		return true;
	}

	ngAfterViewInit() {

	}

	async ngOnInit() {
		if(this.userInfoService.isStaff() || this.userInfoService.isSslgStaff()){
			this.navLinks = [
				{
					id: 'info',
					label: 'Info'
				},
				{
					id: 'requests',
					label: 'Requests & Submissions'
				},
				// {
				// 	id: 'payments',
				// 	label: 'Fees & Payments',
				// },
			];
		}else{
			this.navLinks = [
				{
					id: 'info',
					label: 'Info'
				}
			];
		}

		setTimeout(() => {
			this._parent.hideHeader = true;
		});

		this.sslgId = this.route.snapshot.parent.params['sslgId'];
		if (this.isCreationPage()) {
			this.names = await this.medicalService.getCatalog(CatalogItemType.OTHER).toPromise();

			this.filteredNames = this.nameCtrl.valueChanges
				.pipe(
					map(value => {
						this.other = null;
						this.formTemplate.name = this.nameCtrl.value;
						if (value.id) {
							this.subscription.unsubscribe();
							console.log('jslk3')
							if (value.id != -1){
								this.formGroup.disable({onlySelf: true});
								setTimeout(async () => {
									this.other = await this.medicalService.getCatalogItem(CatalogItemType.OTHER, value.id).toPromise();
									console.log('jslk')
									await this.setFormData();
								});
							}
							else {
								console.log('jslk2')
								this.addressService.getPlaceInfo(value.placeId).subscribe(j => {
									this.formTemplate.name.autocompleteSelectedValue = null;
									if (j.zip)
										this.formTemplate.contact.zip.valueUpdates.next(j.zip);
									if (j.state)
										this.formTemplate.contact.state.valueUpdates.next(j.state);
									if (j.city)
										this.formTemplate.contact.city.valueUpdates.next(j.city);
									if (j.locations)
										this.formTemplate.contact.street.valueUpdates.next((j.streetNumber ? j.streetNumber + ' ' : '') + (j.locations ? j.locations : ''));
								})
							}
						} else {
							console.log('jslk4')
							if (!this.subscribed) {
								this.medicalService.setMedicalConditionsTemplate([]);
								this.subscribeZipCode();
							}
							this.formGroup.enable({onlySelf: true});
							setTimeout(() => {
								this.formGroup.reset();
							});
						}
						return value;
					}),
					startWith(''),
					map((state: any) => {
						this.onStateChanges();
						if (state) {
							let names = this._filterNames(state);
							if (typeof state === 'string' && state.length > 3 || state.name) {
								let query = typeof state === 'string' ? state : state.name;
								this.addressService.findPlace(query, "OTHER").pipe(map((place: any) => {
									return place.map(j=>{
										return {
											id: -1,
											name: j.title,
											label: j.description,
											...j
										};
									})
								})).subscribe(j => {
									names.push(...j);
									console.log(this.filteredNames)
								});
							}
							return names;
						}
						return this.names.slice();
					}));

			this.subscribeZipCode();
		} else {
			this.title = 'Info Other';
			try {
				let result = await this.medicalService.getCatalogItemInfo(this.sslgId, this.route.snapshot.paramMap.get('id')).toPromise();
				this.other = result.catalog;



			} catch (e) {
				this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
					{queryParams: {page: this.route.snapshot.queryParams['page']}});
			}

			await this.setFormData();
			setTimeout(() => {
				this.formTemplate.name.hidden = false;
				//this.formGroup.disable({onlySelf: false});
				//this.nameCtrl.disable({onlySelf: false});
				const name = this.other.name ? this.other.name : '';
				const fax = (this.other.contact && this.other.contact.fax) ? this.other.contact.fax : '';
				this.otherContacts = {
					name,
					fax
				};
			});

		}

		this.oldFormData = FormValueBuilder.getData(this.form);
		this.oldSourceNameData = this.nameCtrl.value ? this.nameCtrl.value : '';
	}

	showBlock(i){
		this.tabNavigationActive = i;

		document.querySelectorAll('.blocks-tab').forEach((el:any) => {
			el.style.display = 'none';
		});

		document.getElementById('block-tab-'+this.navLinks[this.tabNavigationActive].id).style.display = '';
	}

	blockTabActive(i){
		if(i == this.tabNavigationActive){
			return true;
		}

		return false;
	}

	isCreationPage(){
		if (this.route.snapshot.url[1].path === 'new') {
			return true;
		}

		return false;
	}

	subscribeZipCode() {
		this.subscription = this.formTemplate.contact.zip.valueUpdates.subscribe((j: string) => {
			if (j && j.length == 5) {
				this.addressService.findAddress(j).subscribe(addr => {
					if (addr && addr.state) {
						this.formTemplate.contact.state.valueUpdates.next(addr.state);
						this.formTemplate.contact.city.valueUpdates.next(addr.city);
					}
				});
			}
			this.subscribed = true;
		});
	}

	async setFormData() {
		if (this.other !== null) {
			try {
				let street = this.other.contact.street ? this.other.contact.street : '';
				this.other.contact.street = street.replace(/,/g,'');

				const name = this.other.name;
				const fax = this.other.contact.fax;

				this.otherContacts = {
					name,
					fax
				};
			}catch (e) {

			}
			FormValueBuilder.setData(this.other, this.formTemplate);
		}
	}

	onBlur($event) {
	}

	onChange($event) {
	}

	onFocus($event) {
	}

	closeDoctorForm() {
		this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
			{queryParams: {page: this.route.snapshot.queryParams['page']}});
		// this.dialogRef.close();
	}

	async saveDoctor(cmd) {
		FormValueBuilder.setControlsToched(this.formGroup);
		//if (this.nameCtrl.value) {
			//this.formTemplate.name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
			const data = FormValueBuilder.getData(this.formTemplate);
			if (!this.other) {
				data.name = typeof this.nameCtrl.value === 'string' ? this.nameCtrl.value : this.nameCtrl.value.name;
				data.googlePlaceId = this.nameCtrl.value.placeId ? this.nameCtrl.value.placeId : null
			}

			data.sslgId = this.sslgId;
			//if (this.route.snapshot.url[1].path !== 'new') {
				data.id = +this.route.snapshot.paramMap.get('id');
			//}

			//let requestsPayments = this.medicalRequestsPaymentsComponent.getData();

			const doc = await this.medicalService.saveTreatingSourcesOther(this.sslgId, data, this.other);
			//const doc = await this.medicalService.setOther(this.sslgId, data, this.other);

			if (doc) {
				if(cmd == 'new'){
					this.state = State.before;
					this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
						{queryParams: {page: this.route.snapshot.queryParams['page']}});
				}else{
					this.state = State.before;
					this.router.navigate(['/claimants/medical/'+this.sslgId+'/visit-summaries/new'],
						{queryParams: {
								returnUrl: this.route.snapshot.queryParams['returnUrl'],
								catalogItemId: doc.id,
								type: 'OTHER',
							}});
				}
			}
		//}
	}

	onStateChanges(): void {

		const dataNew = FormValueBuilder.getData(this.form);
		const sourceNew = this.nameCtrl.value ? this.nameCtrl.value : '';

		if(this.oldFormData != '' && this.oldSourceNameData != '') {
			const isChanges = Functions.equals(this.oldFormData, dataNew);
			const isChangesSource = Functions.equals(this.oldSourceNameData, sourceNew);

			if (!isChanges || !isChangesSource) {
				this.state = State.after;
			} else {
				this.state = State.before;
			}
		}
	}

	isInputDirty() {
		return this.state == State.after;
	}

	addVisit(){
		this.saveDoctor('addVisit');
	}

	isClaimant() {
		return this.userInfoService.isClaimant();
	}
}
