import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { DynamicFormService } from "@ng-dynamic-forms/core";
import { FormValueBuilder } from "../../../../../partial/forms/form-tamplate";
import { STRING_FIELD } from "../../../../../../../@util/dynamic-form/dynamic-controlls";
import { MedicalService } from "../../../../../../@core/medical.service";
import { DynamicForm } from "../../../../../../../@util/dynamic-form/dynamic-form";
import { DynamicFormGroupModel, DynamicRadioGroupModel, DynamicSelectModel, DynamicTextAreaModel } from "../../../../../../../@util/ui-material/form-controls";
import { MEDICAL_TESTS_MODE } from "../../../../../partial/partial-medical-tests/partial-medical-tests.config";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialogRef } from "@angular/material";
const creationSteps = {
    'initial': 1,
    'medication': 2,
};
export class ClaimantMedicationsNewComponent extends DynamicForm {
    constructor(formService, route, dialogRef, componentData, router, medicalService) {
        super();
        this.formService = formService;
        this.route = route;
        this.dialogRef = dialogRef;
        this.componentData = componentData;
        this.router = router;
        this.medicalService = medicalService;
        this.MEDICAL_TESTS_MODE = MEDICAL_TESTS_MODE;
        this.saveThirdStepData = new EventEmitter();
        this.title = 'In what way would you like to add a medication?';
        this.typeAskingStep = creationSteps.initial;
        this.formLayout = {
            left: {
                element: {
                    host: 'col-lg-6 col-12',
                    control: 'row',
                    children: 'col-12 row-mb'
                },
            },
            right: {
                element: {
                    host: 'col-lg-6 col-12',
                    control: 'row',
                    children: 'col-12 row-mb'
                },
            }
        };
        this.form = {
            type: DynamicRadioGroupModel({
                id: 'type',
                label: '',
                value: 'DOCTOR',
                options: [
                    { value: 'DOCTOR', label: 'DOCTOR' },
                    { value: 'HOSPITAL', label: 'HOSPITAL' },
                    { value: 'OTHER', label: 'OTHER' },
                ],
                editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
            }),
            catalogItemId: DynamicSelectModel({
                id: 'catalogItemId',
                label: 'Name',
                required: true,
                validators: {
                    required: null
                },
                errorMessages: {
                    required: 'Required'
                },
                value: '', editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
            }),
            name: STRING_FIELD({ id: 'medication', label: 'Medication', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], required: true, errorMessages: { required: 'Required' } }),
            reason: DynamicTextAreaModel({ id: 'reason', label: 'Reason for Taking', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], required: true, errorMessages: { required: 'Required' } }),
            sideEffects: DynamicTextAreaModel({ id: 'sideEffects', label: 'Side Effects', editableFor: ["EXECUTIVE_STAFF", "CLIENT"] }),
        };
        this.formTemplate = {
            left: DynamicFormGroupModel({
                id: 'left',
                group: [
                    this.form.type,
                    this.form.catalogItemId,
                ],
                editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
            }),
            right: DynamicFormGroupModel({
                id: 'right',
                group: [
                    this.form.name,
                    this.form.reason,
                    this.form.sideEffects
                ], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
            })
        };
        this.formModelTemplate = FormValueBuilder.buildFormModel(this.formTemplate);
        this.formGroupTemplate = formService.createFormGroup(this.formModelTemplate);
    }
    set selectedType(value) {
        if (!value)
            return;
        if (this._selectedType != value) {
            this._selectedType = value;
            this.typeChanged(value);
        }
    }
    ;
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.sslgId = this.getSslgId();
            this.ctatlogItems = yield this.medicalService.getCatalogVisitItem(this.sslgId).toPromise();
            this.typeChanged('DOCTOR');
            FormValueBuilder.resetToDefault(this.form);
            this.formGroupTemplate.valueChanges.subscribe(j => {
                this.formStateChanged.emit({ key: this.formKey, value: FormValueBuilder.isDirty(this.form) });
            });
        });
    }
    getSslgId() {
        return this.componentData.sslgId;
    }
    initCatalogOptions(value) {
        setTimeout(() => {
            if (!this.ctatlogItems[value]) {
                this.form.catalogItemId.options = [{ label: 'Please add ' + value, value: '', disabled: true }];
            }
            else {
                this.form.catalogItemId.options = this.ctatlogItems[value].map((item) => {
                    return { label: item.name, value: item.id };
                });
            }
        }, 0);
    }
    typeChanged(type) {
        this.initCatalogOptions(type);
        this.form.catalogItemId.valueUpdates.next(null);
        this.formStateChanged.emit({ key: this.formKey, value: FormValueBuilder.isDirty(this.form) });
    }
    ngOnDestroy() {
        this.formStateChanged.next({ value: false, key: this.formKey });
    }
    changeTypeAskingStep(step) {
        this.typeAskingStep = step;
        if (this.isInitialStep()) {
            this.title = 'In what way would you like to add a medication?';
        }
        if (this.isMedicationStep()) {
            this.title = 'New Medication';
        }
    }
    isInitialStep() {
        return this.typeAskingStep === creationSteps.initial;
    }
    isMedicationStep() {
        return this.typeAskingStep === creationSteps.medication;
    }
    addMedication() {
        this.changeTypeAskingStep(creationSteps.medication);
    }
    addVisit() {
        document.querySelectorAll('.mat-drawer-content').forEach(el => {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close({});
        this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'], {
            queryParams: {
                returnUrl: '/claimants/medical/' + this.sslgId + '/visit-summaries',
                type: 'DOCTOR',
            }
        });
    }
    closeMedicationForm(cmd) {
        document.querySelectorAll('.mat-drawer-content').forEach(el => {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close(cmd);
    }
    saveMedication() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            FormValueBuilder.setControlsToched(this.formGroupTemplate);
            if (this.formGroupTemplate.valid) {
                const data = FormValueBuilder.getData(this.form);
                data.catalogId = data.catalogItemId;
                delete data.catalogItemId;
                delete data.type;
                yield this.medicalService.addMedication(this.sslgId, data);
                this.closeMedicationForm('new');
                this.formGroupTemplate.reset();
            }
        });
    }
    onChange($event) {
        if ($event.model.id === 'type') {
            this.typeChanged($event.control.value);
            this.selectedType.next($event.control.value);
            setTimeout(() => {
                if (this.form.catalogItemId.value &&
                    this.form.catalogItemId.options
                        .filter(j => j.value === this.form.catalogItemId.value).length === 0) {
                    this.form.catalogItemId.valueUpdates.next(null);
                }
            }, 0);
        }
        else if ($event.model.id === 'catalogItemId') {
            FormValueBuilder.setData({ catalogItemId: $event.control.value }, this.form);
        }
    }
}
