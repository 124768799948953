import {Component, forwardRef, Host, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl,FormArray, FormControl, FormGroup, Validators,FormBuilder} from '@angular/forms';
import {
	DynamicFormArrayModel,
	DynamicFormControlLayout,
	DynamicFormControlModel,
	DynamicFormService, DynamicSelectModel,
} from '@ng-dynamic-forms/core';
import {
	ADDRESS,
	CYTY, DATE_PICKER, EXT,
	PHONE, PRESS,
	STATE_SELECT,
	STRING_FIELD,
	ZIP
} from '../../../../../../../@util/dynamic-form/dynamic-controlls';
import {FormValueBuilder} from '../../../../../partial/forms/form-tamplate';
import {MedicalService} from '../../../../../../@core/medical.service';
import {DoctorItem, DoctorType} from '../../../../../../@models/medical/doctor-item';
import {ActivatedRoute, Router} from '@angular/router';
import {MedicalTreatingSourcesComponent} from '../../medical-treating-sources.component';
import {Observable, Subscription} from 'rxjs';
import {debounceTime, map, scan, startWith} from 'rxjs/operators';
import {CatalogItemType} from '../../../../../../@models/medical/catalog-item-view';
import {NavigationService} from "../../../../../../@core/navigation.service";
import {AddressService} from "../../../../../../@core/address.service";
import {OnStateChanges, State} from "../../../../../../test-new-stepper-form/test-form/on-changes";
import {Functions} from "../../../../../../../@util/functions";
import {
	DynamicFormArrayModel as DynamicFormArray,
	DynamicFormArrayModel as DunamicFormArray,
	DynamicFormGroupModel,
	DynamicRadioGroupModel
} from "../../../../../../../@util/ui-material/form-controls";
import {UserInfoService} from "../../../../../../@core/user-info.service";
import {DateService} from "../../../../../../@core/functions/date.service";
import { BehaviorSubject } from 'rxjs';
import {SsaRulesComponent} from "../../../ssdi/ssa-rules/ssa-rules.component";
import {MedicalRequestsPaymentsComponent} from "../../medical-requests-payments/medical-requests-payments.component";
import {MedicalRequestsComponent} from "../../medical-requests/medical-requests.component";
import {MedicalPaymentsComponent} from "../../medical-payments/medical-payments.component";
import {MatDialog} from "@angular/material";
import {DialogService} from "../../../../../../../@util/dialog/dialog.service";
import {GlobalSaveService} from "../../../../../../@core/global-save.service";

@Component({
	selector: 'app-doctor-form',
	templateUrl: './doctor-form.component.html',
	styleUrls: ['./doctor-form.component.scss']
})
export class DoctorFormComponent implements OnInit, OnDestroy, OnStateChanges {
	//@ViewChild(MedicalRequestsPaymentsComponent, {static: false}) medicalRequestsPaymentsComponent: MedicalRequestsPaymentsComponent;

	@ViewChild(MedicalRequestsComponent, {static: false}) medicalRequestsComponent: MedicalRequestsComponent;
	@ViewChild(MedicalPaymentsComponent, {static: false}) medicalPaymentsComponent: MedicalPaymentsComponent;

	_selectedType;
	@Input()
	set selectedType(value) {
		if (!value)
			return;
		if (this._selectedType != value) {
			this._selectedType = value;
			this.typeChanged(value);
		}

	};
	_visitId;
	@Input()
	set visitId(value) {
		if (!value)
			return;
		if (this._visitId != value) {
			this._visitId = value;


			//this.initMedicalCondition();
		}
	};
	title = 'New Doctor';
	formGroup: FormGroup;
	formModel: DynamicFormControlModel[];
	formLayout = {
		treat: <DynamicFormControlLayout>{
			element: {
				control: 'form-row'
			}
		},
		treats: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		speciality: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		type: <DynamicFormControlLayout>{
			element: {
				label: 'form-label'
			}
		},
		visits: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb',
				control: 'form-row',
			}
		},
		firstSeen: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_x2'
			}
		},
		lastSeen: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_x2'
			}
		},
		nextVisit: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_x2'
			}
		},
		phones: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb',
				control: 'form-row',
			}
		},
		contact_phone: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_x2'
			}
		},
		contact_press: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		contact_ext: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		contact_fax: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb'
			}
		},
		contact_address: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb'
			}
		},
		contact_address2: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb'
			}
		}, contact_cyti: <DynamicFormControlLayout>{
			element: {
				container: 'row-mb'
			}
		},
		state_zip: <DynamicFormControlLayout>{
			element: {
				control: 'form-row',
				container: 'row-mb'
			}
		},
		contact_state: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		contact_zip: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		callerPhones: <DynamicFormControlLayout>{
			element: {
				control: 'form-row',
			}
		},
		medicalCondition: <DynamicFormControlLayout>{
			element: {
				host: 'col-lg-12 row-mb-half',
				label: 'form-label form-label_full-width row-mb',
				group: 'row row_conditions'
			},
		},
		callerPhone: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_x2'
			}
		},
		callerPress: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
		callerExt: <DynamicFormControlLayout>{
			element: {
				host: 'form-element'
			}
		},
	};
	private doctor: DoctorItem;

	formTemplate = {
		name: STRING_FIELD({id: 'name', label: 'Name', hidden: true}),
		googlePlaceId: STRING_FIELD({id: 'googlePlaceId', label: 'Name', hidden: true}),
		// recipient: {
		// 	recipientName: STRING_FIELD({
		// 		id: 'recipientName',
		// 		label: 'Person To Contact',
		// 		maxLength: 128,
		// 		required: true,
		// 		validators: {required: null}
		// 	}),
		// },
		practiceName: STRING_FIELD({id: 'practiceName', label: 'Practice', maxLength: 256}),
		treats: STRING_FIELD({id: 'treats', label: 'Treats For', maxLength: 128}),
		specialty: STRING_FIELD({id: 'speciality', label: 'Specialty', maxLength: 128}),
		type: DynamicRadioGroupModel<DoctorType>({
			id: 'type',
			label: 'Type',
			options: [
				{
					value: DoctorType.PCP,
					label: DoctorType.PCP.toString()
				},
				{
					value: DoctorType.SPECIALIST,
					label: DoctorType.SPECIALIST.toString()
				}
			],
			value: DoctorType.PCP
		}),
		visits: {
			firstSeen: DATE_PICKER({id: 'firstSeen', label: 'First Seen',editableFor: ["EXECUTIVE_STAFF","CLIENT"]}),
			lastSeen: DATE_PICKER({id: 'lastSeen', label: 'Last Seen',editableFor: ["EXECUTIVE_STAFF","CLIENT"]}),
			nextVisit: DATE_PICKER({id: 'nextVisit', label: 'Next Visit',editableFor: ["EXECUTIVE_STAFF","CLIENT"]})
		},
		contact: {
			phone: PHONE({id: 'contact_phone', label: 'Phone'}),
			press: PRESS({id: 'contact_press', label: '"Press"'}),
			ext: EXT({id: 'contact_ext', label: 'Ext.'}),
			fax: PHONE({id: 'contact_fax', label: 'Business Fax'}),
			street: ADDRESS({id: 'contact_address'}),
			street2: ADDRESS({id: 'contact_address2', label: 'Address 2'}),
			city: CYTY({id: 'contact_cyti'}),
			state: STATE_SELECT({id: 'contact_state'}),
			zip: ZIP({id: 'contact_zip'}),
		},
		callerName: STRING_FIELD({id: 'callerName', label: 'Name', maxLength: 128}),
		callerPhone: PHONE({id: 'callerPhone', label: 'Phone'}),
		callerPress: PRESS({id: 'callerPress', label: '"Press"'}),
		callerExt: EXT({id: 'callerExt', label: 'Ext.'}),
		medicalCondition: DunamicFormArray({
			id: 'medicalCondition',
			label: 'What medical conditions were treated?',
			groupFactory: () => {
				return [
					STRING_FIELD({id: 'value', label: 'Condition',disabled:this.isDisabled(), maxLength: 64, editableFor: ["EXECUTIVE_STAFF", "CLIENT"]})
				]
			},
			editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		}),

	};



	form = {
		name: this.formTemplate.name,
		// recipientName: this.formTemplate.recipient.recipientName,
		googlePlaceId: this.formTemplate.googlePlaceId,
		practiceName: this.formTemplate.practiceName,
		treat: DynamicFormGroupModel({
			id: 'treat',
			group: [
				// this.formTemplate.treats,
				this.formTemplate.specialty,
			]
		}),
		type: this.formTemplate.type,
		contact: DynamicFormGroupModel({
			id: 'contact',
			group: [
				DynamicFormGroupModel({
					id: 'phones',
					group: [
						this.formTemplate.contact.phone,
						this.formTemplate.contact.press,
						this.formTemplate.contact.ext
					]
				}),
				this.formTemplate.contact.fax,
				DynamicFormGroupModel({
					id: 'state_zip',
					group: [
						this.formTemplate.contact.zip,
						this.formTemplate.contact.state,

					]
				}),
				this.formTemplate.contact.city,
				this.formTemplate.contact.street,
				this.formTemplate.contact.street2,
			]
		}),
		doctor: DynamicFormGroupModel({
			id: 'doctor',
			group: [
				this.formTemplate.medicalCondition
			], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		}),
		// callerName: this.formTemplate.callerName,
		// callerPhones: DynamicFormGroupModel({
		// 	id: 'callerPhones',
		// 	group: [
		// 		this.formTemplate.callerPhone,
		// 		this.formTemplate.callerPress,
		// 		this.formTemplate.callerExt,
		// 	]
		// }),
		visit:DynamicFormGroupModel({
			id: 'visit',
			editableFor:["EXECUTIVE_STAFF","CLIENT"],
			group: [
				DynamicFormGroupModel({
					id: 'visits',
					editableFor:["EXECUTIVE_STAFF","CLIENT"],
					group: [
						this.formTemplate.visits.firstSeen,
						this.formTemplate.visits.lastSeen,
						this.formTemplate.visits.nextVisit
					]
				}),
			]
		}),

	};
	private sslgId: any;
	private sub: Subscription;
	subscription;
	subscribed = true;
	state: State = State.before;
	allFormState: State = State.before;
	oldFormData;
	oldSourceNameData;
	disabledBtn = false;
	condition = "";
	requests;

	navLinks: ({ id: string; label: string })[];
	doctorContacts;

	constructor(private medicalService: MedicalService, private formService: DynamicFormService,
				private fb: FormBuilder,
				private addressService: AddressService,
				@Inject(forwardRef(() => MedicalTreatingSourcesComponent)) private _parent: MedicalTreatingSourcesComponent,
				private route: ActivatedRoute,
				private userInfo: UserInfoService,
				private user: UserInfoService,
				private userInfoService: UserInfoService,
				private dateService: DateService,
				private dialog: DialogService,
				public globalSaveService: GlobalSaveService,
				private router: Router, private navService: NavigationService) {

		this.formModel = FormValueBuilder.buildFormModel(this.form);
		this.formGroup = this.formService.createFormGroup(this.formModel);
		// this.formGroup.addControl('name', this.nameCtrl);
		this.nameCtrl.setValidators(Validators.required);
		this.sub = navService.back().subscribe(j => this.closeDoctorForm());
	}

	ngOnDestroy(): void {
		this._parent.hideHeader = false;
		this.sub.unsubscribe();
	}

	isDisabled(){
		if(this.isCreationPage() || !this.isClaimant()){
			return false;
		}

		return true;
	}

	nameCtrl = new FormControl();
	filteredNames: Observable<{ id: number, name: string, label?: string }[]>;
	names: { id: number, name: string, label?: string }[] = [];
	catalogItemId: number = null;

	tabNavigationActive = 0;

	private _filterNames(value): { id: number, name: string, label?: string }[] {
		////console.log(value);
		const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name ? value.name.toLowerCase() : '';
		////console.log(filterValue);
		return this.names.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
	}

	displayFn(item?: { id: number, name: string }): string | undefined {
		return item ? item.name : undefined;
	}

	ngAfterViewInit() {
		if (this.isCreationPage()) {
			//this.medicalRequestsPaymentsComponent.requestsPayments(null,null);
		}
	}

	showBlock(i){
		this.tabNavigationActive = i;

		document.querySelectorAll('.blocks-tab').forEach((el:any) => {
			el.style.display = 'none';
		});

		document.getElementById('block-tab-'+this.navLinks[this.tabNavigationActive].id).style.display = '';
	}

	blockTabActive(i){
		if(i == this.tabNavigationActive){
			return true;
		}

		return false;
	}

	async ngOnInit() {
		if(this.userInfoService.isStaff() || this.userInfoService.isSslgStaff()){
			this.navLinks = [
				{
					id: 'info',
					label: 'Info'
				},
				{
					id: 'requests',
					label: 'Requests & Submissions'
				},
				// {
				// 	id: 'payments',
				// 	label: 'Fees & Payments',
				// },
			];
		}else{
			this.navLinks = [
				{
					id: 'info',
					label: 'Info'
				}
			];
		}

		this.checkDisabledBtn();

		setTimeout(() => {
			this._parent.hideHeader = true;
		});
		this.sslgId = this.getSslgId();
		if (this.isCreationPage()) {
			this.medicalService.setClaimantUserName(this.sslgId);

			this.condition = this.route.snapshot.url[1].path;

			this.names = await this.medicalService.getCatalog(CatalogItemType.DOCTOR).toPromise();

			for (let i in this.names) {
				let element = <any>this.names[i];

				this.names[i].label = '';
				if (element.address != null) {
					this.names[i].label += element.address;
				}
				if (element.city != null) {
					if (this.names[i].label != '') {
						this.names[i].label += ', ';
					}
					this.names[i].label += element.city;
				}
				if (element.state != null) {
					if (this.names[i].label != '') {
						this.names[i].label += ', ';
					}
					this.names[i].label += element.state;
				}
				if (element.zip != null) {
					this.names[i].label += ' ' + element.zip;
				}

			}

			this.filteredNames = this.nameCtrl.valueChanges
				.pipe(
					map(value => {
						this.doctor = null;
						this.formTemplate.name = this.nameCtrl.value;

						this.visitsSwitchedOn();

						if (value.id) {
							//this.formGroup.reset();

							this.formGroup.enable({onlySelf: true});

							this.subscription.unsubscribe();



							if (value.id != -1) {
								if(this.user.isClaimant()){
									this.formGroup.get("practiceName").disable({onlySelf: true});
									this.formGroup.get("type").disable({onlySelf: true});
									this.formGroup.get("contact").disable({onlySelf: true});
									this.formGroup.get("callerName").disable({onlySelf: true});
									this.formGroup.get("callerPhones").disable({onlySelf: true});
									// this.formGroup.get("recipientName").disable({onlySelf: true});

								}

								setTimeout(async () => {
									this.doctor = await this.medicalService.getCatalogItem(CatalogItemType.DOCTOR, value.id).toPromise();

									await this.setFormData();

									//this.visitsDisabled();
								});
							} else {
								//this.formGroup.enable({onlySelf: true});

								this.addressService.getPlaceInfo(value.placeId).subscribe(j => {
									this.formTemplate.name.autocompleteSelectedValue = null;
									if (j.zip)
										this.formTemplate.contact.zip.valueUpdates.next(j.zip);
									if (j.state)
										this.formTemplate.contact.state.valueUpdates.next(j.state);
									if (j.city)
										this.formTemplate.contact.city.valueUpdates.next(j.city);
									if (j.phone)
										this.formTemplate.contact.phone.valueUpdates.next(j.phone);
									if (j.locations)
										this.formTemplate.contact.street.valueUpdates.next((j.streetNumber ? j.streetNumber + ' ' : '') + (j.locations ? j.locations : ''));
								})
							}
						} else {
							if (!this.subscribed) {
								this.medicalService.setMedicalConditionsTemplate([]);
								this.subscribeZipCode();
							}

							this.formGroup.enable({onlySelf: true});

							setTimeout(() => {
								this.formGroup.reset();
								this.form.type.valueUpdates.next(DoctorType.PCP);
							});
						}

						return value;
					}),
					startWith(''),
					debounceTime(300),
					map((state: any) => {
						this.onStateChanges();
						if (state) {
							let names = this._filterNames(state);
							if (typeof state === 'string' && state.length > 3 || state.name) {
								let query = typeof state === 'string' ? state : state.name;
								this.addressService.findPlace(query, "DOCTOR").pipe(map((place: any) => {
									return place.map(j => {
										return {
											id: -1,
											name: j.title,
											label: j.description,
											...j
										};
									})
								})).subscribe(j => {
									names.push(...j);
								});
							}

							return names;
						}

						return this.names.slice();
					}));

			this.subscribeZipCode();
		} else {
			this.title = 'Info Doctor';

			//try {
				let result = await this.medicalService.getCatalogItemInfo(this.sslgId, this.route.snapshot.paramMap.get('id')).toPromise();
				this.doctor = result.catalog;
				this.requests = result.requests;

				//this.medicalRequestsComponent.init(result.requests);
				//this.medicalPaymentsComponent.init(result.payments);

				//this.medicalRequestsPaymentsComponent.requestsPayments(result.requests,result.payments);



				if(this.doctor.visits){
					const firstSeen = !this.doctor.visits.firstSeen || (new Date(this.doctor.visits.firstSeen).toUTCString() === new Date('1969-12-31 00:00:00').toUTCString()) ? null : new Date(this.doctor.visits.firstSeen);
					const lastSeen = !this.doctor.visits.lastSeen || (new Date(this.doctor.visits.lastSeen).toUTCString() === new Date('1969-12-31 00:00:00').toUTCString()) ? null : new Date(this.doctor.visits.lastSeen);
					const nextVisit = !this.doctor.visits.nextVisit || (new Date(this.doctor.visits.nextVisit).toUTCString() === new Date('1969-12-31 00:00:00').toUTCString()) ? null : new Date(this.doctor.visits.nextVisit);

					// this.doctor.visits.firstSeen = this.doctor.visits.firstSeen ? new Date(this.doctor.visits.firstSeen) : this.doctor.visits.firstSeen;
					// this.doctor.visits.firstSeen = new Date(this.doctor.visits.firstSeen);
					// this.doctor.visits.lastSeen = new Date(this.doctor.visits.lastSeen);
					// this.doctor.visits.nextVisit = new Date(this.doctor.visits.nextVisit);

					this.doctor.visits.firstSeen = firstSeen;
					this.doctor.visits.lastSeen = lastSeen;
					this.doctor.visits.nextVisit = nextVisit;
					// this.doctor.visits.nextVisit = new Date('1969-12-31 00:00:00');
				}
			// } catch (e) {
			// 	 this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
			// 	 	{queryParams: {page: this.route.snapshot.queryParams['page']}});
			// 	// this._parent.hideHeader = false;
			// }

			await this.setFormData();
			setTimeout(() => {
				this.formTemplate.name.hidden = false;

				this.initMedicalCondition(this.route.snapshot.paramMap.get('id'));

				this.nameCtrl.disable({onlySelf: false});


			});
		}

		this.oldFormData = FormValueBuilder.getData(this.form);
		this.oldSourceNameData = this.nameCtrl.value ? this.nameCtrl.value : '';
	}

	getSourceId(){
		return this.route.snapshot.paramMap.get('id');
	}

	getSslgId(){
		return this.route.snapshot.parent.params['sslgId'];
	}

	getReturnUrl(){
		return this.route.snapshot.queryParams['returnUrl'];
	}

	onChange($event) {
		if ($event.model) {
			if ($event.model.id === 'type') {
				this.hideSpecialty($event.model.value);
			}
		}
	}

	isCreationPage(){
		if (this.route.snapshot.url[1].path === 'new') {
			return true;
		}

		return false;
	}

	visitsDisabled(){
		// if(this.formTemplate.visits.firstSeen != null ||
		// 	this.formTemplate.visits.lastSeen != null ||
		// 	this.formTemplate.visits.nextVisit != null){
		//
		// 	this.formTemplate.visits.firstSeen.disabled = true;
		// 	this.formTemplate.visits.lastSeen.disabled = true;
		// 	this.formTemplate.visits.nextVisit.disabled = true;
		// }
	}

	visitsSwitchedOn(){
		this.formTemplate.visits.firstSeen.disabled = false;
		this.formTemplate.visits.lastSeen.disabled = false;
		this.formTemplate.visits.nextVisit.disabled = false;



		//this.formTemplate.practiceName.disabled = false;

	}

	visitsSwitchedOff(){
		this.formTemplate.visits.firstSeen.disabled = true;
		this.formTemplate.visits.lastSeen.disabled = true;
		this.formTemplate.visits.nextVisit.disabled = true;


	}

	subscribeZipCode() {
		this.subscription = this.formTemplate.contact.zip.valueUpdates.subscribe((j: string) => {
			if (j && j.length == 5) {

				this.addressService.findAddress(j).subscribe(addr => {
					if (addr && addr.state) {
						this.formTemplate.contact.state.valueUpdates.next(addr.state);
						this.formTemplate.contact.city.valueUpdates.next(addr.city);
					}
				});
			}
			this.subscribed = true;
		});
	}

	async setFormData() {

		setTimeout(() => {
			if (this.doctor !== null) {
				try {

					let street = this.doctor.contact.street ? this.doctor.contact.street : '';
					this.doctor.contact.street = street.replace(/,/g,'');

					const name = this.doctor.name ? this.doctor.name : '';
					const fax = (this.doctor.contact && this.doctor.contact.fax) ? this.doctor.contact.fax : '';
					this.doctorContacts = {
						name,
						fax
					};
				}catch (e) {

				}

				FormValueBuilder.setData(this.doctor, this.formTemplate);
				this.hideSpecialty(this.formTemplate.type.value as DoctorType);
			}
		});
	}

	checkDisabledBtn(){
		if(!this.userInfo.getCurrentUserNameIsSystem()){
			this.disabledBtn = true;
		}else{
			this.disabledBtn = false;
		}
	}

	getBtnTitle(){
		let title = 'Add a New Medical Source';
		if (this.route.snapshot.url[1].path !== 'new') {
			title = 'Update a Medical Source';
		}

		// if(!this.userInfo.getCurrentUserNameIsSystem()){
		// 	title = 'You do not have permissions to use this functionality.';
		// }

		return title;
	}

	getBtnName(){
		//this.userInfo.getCurrentUserNameIsSystem()
		if (this.route.snapshot.url[1].path !== 'new') {
			return 'Update';
		}

		return 'Add';
	}

	showName() {
		return this.route.snapshot.url[1].path === 'new'
	}

	onBlur($event) {
	}



	hideSpecialty(typeValue: DoctorType) {
		setTimeout(() => {
			this.formTemplate.specialty.hidden = typeValue === DoctorType.PCP;
			this.formLayout.treat.element.control = typeValue === DoctorType.PCP ? '' : 'form-row';
			this.formLayout.treats.element.host = typeValue === DoctorType.PCP ? '' : 'form-element';
		});
	}

	onFocus($event) {
	}

	closeDoctorForm() {

		this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
			{queryParams: {page: this.route.snapshot.queryParams['page']}});
		// this._parent.hideHeader = false;
		// this.dialogRef.close();
	}

	async saveDoctor(cmd) {
		let data;
		if (this.isCreationPage()) {
			if (!this.nameCtrl.value) {
				return false;
			}

			this.formTemplate.name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
			data = FormValueBuilder.getData(this.formTemplate);
			data.name = typeof this.nameCtrl.value === 'string' ? this.nameCtrl.value : this.nameCtrl.value.name;
			if (typeof this.nameCtrl.value !== 'string') {
				data.id = this.nameCtrl.value.id;
			}
		}else{
			if (!this.formTemplate.name.value) {
				return false;
			}

			data = FormValueBuilder.getData(this.formTemplate);
			data.name = this.formTemplate.name.value;
		}

		if (!this.doctor) {
			data.googlePlaceId = this.nameCtrl.value.placeId ? this.nameCtrl.value.placeId : null
		}

		data.sslgId = this.sslgId;
		data.pageType = "new";
		if (this.route.snapshot.url[1].path !== 'new') {
			data.id = +this.route.snapshot.paramMap.get('id');
			data.pageType = "old";
		}


		//data.medicalConditions = this.formTemplate.medicalCondition;
		//const arrayModel = this.formTemplate.medicalCondition;
		const arrayModel = (<any>this.formGroup.controls['doctor']).controls['medicalCondition'];
		let cond = [];
		for(let i=0;i<arrayModel.controls.length;i++){
			let value = arrayModel.controls[i].value.value;
			if(value != "null" && value){
				cond.push(value);
			}
		}

		data.medicalConditions = cond.join('-||-');

		let nextVisit = data.visits.nextVisit;
		data.visits.nextVisit = this.dateService.getDateWithoutHours(nextVisit);

		let firstSeen = data.visits.firstSeen;
		data.visits.firstSeen = this.dateService.getDateWithoutHours(firstSeen);

		let lastSeen = data.visits.lastSeen;
		data.visits.lastSeen = this.dateService.getDateWithoutHours(lastSeen);

		const doc = await this.medicalService.saveTreatingSourcesDoctor(this.sslgId, data, this.doctor);
		if (doc) {
			if(cmd == 'addVisit'){
				this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'],
					{
						queryParams: {
							returnUrl: this.route.snapshot.queryParams['returnUrl'],
							catalogItemId: doc.id,
							type: doc.type,
						}
					});
			}else{
				//this.dialog.show("Information was updated successfully!", ["ok"]);
				this.state = State.before;
				this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
					{queryParams: {page: this.route.snapshot.queryParams['page']}});
			}

			// if(cmd == 'new'){
			// 	this.state = State.before;
			// 	this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
			// 		{queryParams: {page: this.route.snapshot.queryParams['page']}});
			// }else{
			// 	this.state = State.before;
			// 	this.router.navigate(['/claimants/medical/'+this.sslgId+'/visit-summaries/new'],
			// 		{queryParams: {
			// 			returnUrl: this.route.snapshot.queryParams['returnUrl'],
			// 			catalogItemId: doc.id,
			// 			type: doc.type,
			// 		}});
			// }

		}
	}

	onStateChanges(): void {

		const dataNew = FormValueBuilder.getData(this.form);
		const sourceNew = this.nameCtrl.value ? this.nameCtrl.value : '';

		if(this.oldFormData != '' && this.oldSourceNameData != '' && dataNew && sourceNew){
			const isChanges = Functions.equals(this.oldFormData, dataNew);
			const isChangesSource = Functions.equals(this.oldSourceNameData, sourceNew);

			if (!isChanges || !isChangesSource) {
				this.state = State.after;
			} else {
				this.state = State.before;
			}
		}
	}

	isInputDirty() {
		return this.state == State.after;
	}

	isClaimant() {
		return this.userInfoService.isClaimant();
	}

	showSave(){
		if(this.isClaimant() && this.condition!='new'){
			this.visitsSwitchedOff();

			return false;
		}

		if(!this.globalSaveService.getStatusTrue() && !this.isClaimant()){
			return false;
		}

		return true;
	}

	isApp(){
		var w = window.innerWidth;

		if(w < 1100){
			return true;
		}

		return false;
	}

	initMedicalCondition(catalogItemId) {
		let medicalCondition = this.doctor.medicalConditions;
		let medicalConditions = medicalCondition.split("-||-");
		for (let i = 0; i < this.formTemplate.medicalCondition.groups.length; i++) {
			this.formTemplate.medicalCondition.removeGroup(i);
		}
		this.insertMedicalCondition();
		this.initMedicalConditions(medicalConditions);
	}
	initMedicalConditions(medicalConditions: any[]) {
		if (!medicalConditions)
			return;
		if (medicalConditions.length > 1) {
			for (let i = 1; i < medicalConditions.length; i++) {
				this.insertMedicalCondition();
			}
		}
		if (medicalConditions.length > 0)
			(<any>this.formGroup.controls['doctor']).controls['medicalCondition'].patchValue(medicalConditions.map(j => {
				return {value: j}
			}));
	}

	// Medical conditions
	insertMedicalCondition() {
		const arrayModel = this.formService.findById('medicalCondition', this.formModel) as DynamicFormArrayModel;
		this.formService.insertFormArrayGroup(arrayModel.groups.length, <any>(this.formGroup.controls['doctor'] as FormGroup)
			.controls['medicalCondition'], arrayModel);
	}

	remove(context: DynamicFormArrayModel, index) {
		this.formService.removeFormArrayGroup(index, <any>(this.formGroup.controls['doctor'] as FormGroup).controls['medicalCondition'], context);
	}

	showAddBtn(context: DynamicFormArrayModel, index) {
		if(!this.globalSaveService.getStatusTrue() && !this.isClaimant()){
			return false;
		}

		if(this.isCreationPage() || !this.isClaimant()){
			return context.groups.length - 1 === index;
		}

		return false;
	}

	showRemoveBtn(context: DynamicFormArrayModel, index) {
		if(!this.globalSaveService.getStatusTrue() && !this.isClaimant()){
			return false;
		}

		if(this.isCreationPage() || !this.isClaimant()){
			return context.groups.length > 1;
		}

		return false;
	}

	typeChanged(type) {
		if (type === 'DOCTOR') {
			this.form.doctor.hidden = false;
		} else {
			this.form.doctor.hidden = true;
		}
	}

	addVisit(){
		this.saveDoctor('addVisit');
	}

	canAccess(){
		if(this.userInfoService.isClaimant() || this.userInfoService.isSslgStaff() || this.userInfoService.isStaff()){
			return true;
		}

		return false;
	}

}
