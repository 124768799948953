import {Component, OnInit} from '@angular/core';
import {MedicalService} from '../../../../../@core/medical.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationService} from "../../../../../@core/navigation.service";
import {Subscription} from "rxjs";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {FormGroup} from '@angular/forms';
import {UserInfoService} from "../../../../../@core/user-info.service";
import {GlobalSaveService} from "../../../../../@core/global-save.service";
import {DateService} from "../../../../../@core/functions/date.service";
import {HospitalFormNewComponent} from "../hospitals/hospital-form-new/hospital-form-new.component";
import {ClaimantMedicationsNewComponent} from "./claimant-medications-new/claimant-medications-new.component";
import {MatDialog} from "@angular/material";

@Component({
    selector: 'app-claimant-medications',
    templateUrl: './claimant-medications.component.html',
    styleUrls: ['./claimant-medications.component.scss']
})
export class ClaimantMedicationsComponent implements OnInit {
    sslgId: string;
    displayRow = {
        Doctor: true,
        Hospital: true,
        Other: true
    };
    medicationsData: any[];
    groups;
    private sub: Subscription;
    formGroup: FormGroup;

    isEditMode = false;

    medicationsDataFormatted: any[];

    constructor(private medicalService: MedicalService,
                private userInfoService: UserInfoService,
                private route: ActivatedRoute,
                private dialog: MatDialog,
                private dateService: DateService,
                public globalSaveService: GlobalSaveService,
                private navService: NavigationService) {
        this.sub = navService.back().subscribe(j => this.goBack());
      this.formGroup = new FormGroup({
        // firstName: new FormControl()
      });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    goBack() {
        this.navService.navigate(['/claimants'], [`${this.route.snapshot.paramMap.get('sslgId')}`]);
    }

    async ngOnInit() {
        this.sslgId = this.route.snapshot.parent.paramMap.get('sslgId');
        await this.getMedicationsData();
    }

  async getMedicationsData() {
    this.medicationsData = await this.medicalService.getAllMedicationForClaim(this.sslgId).toPromise();
    this.medicationsDataFormatted = {...this.medicationsData};

    Object.keys(this.medicationsDataFormatted).forEach(type => {
      Object.keys(this.medicationsDataFormatted[type]).forEach(claim => {
        const activeList = [];
        const inactiveList = [];
        this.medicationsDataFormatted[type][claim].medicationsList = [];

        this.medicationsDataFormatted[type][claim].forEach((med) => {
          med.activeList = [];
          med.inactiveList = [];
          if (med.status) {
            med.activeList.push(med);
          } else {
            med.inactiveList.push(med);
          }
          this.medicationsDataFormatted[type][claim].medicationsList.push(med);
        });
      });
    });
  }

  getTypes() {
      if (this.medicationsData) {
          return Object.getOwnPropertyNames(this.medicationsData).map(j => {
              return j.substr(0, 1) + j.toLocaleLowerCase().substr(1);
          }).reverse();
      }
      return [];
  }

  getMedications(type: string) {
    if (!type) return [];
    const values = this.medicationsData[type.toUpperCase()];
    return Object.values(values);
  }

    identify(index, item) {
        return item;
    }

    identify2(index, item) {
        return item.catalogItemId;
    }

    identify3(index, item) {
        return item.medicationName;
    }

    getDate(medication) {
      return medication.date ? new Date(medication.date).toLocaleDateString('en-Us') : null;
    }

    showRow(type: string | any) {

        this.displayRow[type] = !this.displayRow[type];
    }

    canShowRow(type: string | any) {
        return this.displayRow[type];
    }

    // compareMedicationsFn(a, b) {
    //   console.log(a, b);
    //   if (a < b) {
    //     return -1;
    //   } else if (a > b) {
    //     return 1;
    //   }
    //   // a must be equal to b
    //   return 0;
    // }

    async dropSegments(event: CdkDragDrop<string[]>, el) {
      // const oldDate = el.date;
      // const oldStatus = el.status;
      //
      // const oldActiveList = [...el.activeList];
      // const oldInactiveList = [...el.inactiveList];

      let status;
      const date = new Date();
      if (event.distance.x < 0) {
        status = false;
      } else {
        status = true;
      }

      const data = {
        id: el.medicationId,
        status: status,
        date: this.dateService.getDateWithoutHours(date)
      };

      el.status = status;
      el.date = date;

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      // await this.medicalService.changeMedicationStatus(this.sslgId, data).catch(data => {
      //   if (data.error) {
      //     el.status = oldStatus;
      //     el.date = oldDate;
      //     el.activeList = oldActiveList;
      //     el.inactiveList = oldInactiveList;
      //   }
      // });

      // visit.medicationsList.sort((a, b) => {
      //   console.log(a, b);
      //   if (a.period && a.period.endDate && b.period && b.period.endDate) {
      //     return b.period.endDate - a.period.endDate;
      //   }
      //   return 1;
      // });
    }

  isDoctorType(type) {
      return type.toUpperCase() === 'DOCTOR';
  }

  isHospitalType(type) {
    return type.toUpperCase() === 'HOSPITAL';
  }

  isOtherType(type) {
    return type.toUpperCase() === 'OTHER';
  }

  isClaimant() {
    if(this.userInfoService.getCurrentUserRole() == 'CLIENT'){
      return true;
    }
    return false;
  }

  isExecutive() {
    if(this.userInfoService.getCurrentUserRole() == 'EXECUTIVE_STAFF'){
      return true;
    }
    return false;
  }

  onEditMode() {
      this.isEditMode = true;
  }

  async saveChanges() {
    const data = {
      medications: []
    }

    Object.keys(this.medicationsDataFormatted).forEach(type => {
      Object.keys(this.medicationsDataFormatted[type]).forEach(claim => {
        this.medicationsDataFormatted[type][claim].forEach(med => {
          // console.log(med);
          data.medications.push({
            id: med.medicationId,
            status: med.status,
            date: med.date ? this.dateService.getDateWithoutHours(med.date) : null
          });
        });
      });
    });

    await this.medicalService.saveAllMedications(this.sslgId, data).catch(data => {
      if (data.error) {
        this.getMedicationsData();
      }
    });
    this.isEditMode = false;
  }

  async changeActiveMedication(claimMedications, state, e) {
      if (!this.isEditMode) {
        return;
      }

      if (String(state) === String(claimMedications.status)) {
        return;
      }
      // const oldDate = claimMedications.date;
      // const oldStatus = claimMedications.status;
      //
      // const oldActiveList = [...claimMedications.activeList];
      // const oldInactiveList = [...claimMedications.inactiveList];

      if (state) {
        claimMedications.activeList = [...claimMedications.inactiveList];
        claimMedications.inactiveList = [];

      } else {
        claimMedications.inactiveList = [...claimMedications.activeList];
        claimMedications.activeList = [];
      }

      // e.target.value = !e.target.value;
      claimMedications.status = state;
      claimMedications.date = new Date();

    // const data = {
    //   id: claimMedications.medicationId,
    //   status: claimMedications.status,
    //   date: this.dateService.getDateWithoutHours(claimMedications.date)
    // };
    // await this.medicalService.changeMedicationStatus(this.sslgId, data).catch(data => {
    //   if (data.error) {
    //     claimMedications.status = oldStatus;
    //     claimMedications.date = oldDate;
    //     claimMedications.activeList = oldActiveList;
    //     claimMedications.inactiveList = oldInactiveList;
    //   }
    // });
  }

  async addMedication() {
    document.querySelectorAll('.mat-drawer-content').forEach(el => {
      (<HTMLElement>el).style.overflow = 'hidden';
    });

    const dialogRef = this.dialog.open(ClaimantMedicationsNewComponent, {
      data: {
        sslgId:this.sslgId
      },
      panelClass: ['mobile-popup', 'with-shadow', 'popup-scrollable'],
      // panelClass: ['mobile-popup', 'with-shadow'],
      backdropClass: 'mobile-popup__overlay',
      disableClose: true
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.cmd) {

        } else if(result == 'new'){
          this.getMedicationsData();
        }
      }
    });
  }
}
