import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {
	DynamicFormControlModel,
	DynamicFormService
} from "@ng-dynamic-forms/core";
import {MedicalService} from "../../../../../../@core/medical.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {Router} from "@angular/router";
import {FormValueBuilder} from "../../../../../partial/forms/form-tamplate";
import {STRING_FIELD} from "../../../../../../../@util/dynamic-form/dynamic-controlls";
import {DynamicFormArrayModel as DynamicFormArray} from "../../../../../../../@util/ui-material/form-controls";
import {DynamicFormArrayModel} from "@ng-dynamic-forms/core/public-api";

@Component({
	selector: 'app-medical-conditions',
	templateUrl: './medical-conditions.component.html',
	styleUrls: ['./medical-conditions.component.scss']
})
export class MedicalConditionsComponent implements OnInit {
	title = 'New Medical Record';
	formGroup: FormGroup;
	formModel: DynamicFormControlModel[];
	form = {
		medicalCondition: DynamicFormArray({
			id: 'medicalCondition',
			label: 'Please add all medical conditions and diagnoses that are treated by this medical source.',
			groupFactory: () => {
				return [
					STRING_FIELD({id: 'value', maxLength: 64, editableFor: ["CLIENT", "EXECUTIVE_STAFF"]})
				]
			}, editableFor: ["CLIENT", "EXECUTIVE_STAFF"]
		}, {
			grid: {
				// container: 'row-mb',
				// control: 'column-items',
				// label: 'form-label_full-width form-label'
				label: 'form-label form-label_full-width form-label_mb',
				control: 'form-row_col_flex',
				group: 'row',
				children: 'col col-11 auto-f-basis',
				host: 'form-element_hide-label'
			},
		}),
	};

	constructor(private formService: DynamicFormService, private medicalService: MedicalService,
				private dialogRef: MatDialogRef<MedicalConditionsComponent>,
				@Inject(MAT_DIALOG_DATA) private data: string[],
				private router: Router) {
		this.formModel = FormValueBuilder.buildFormModel(this.form);
		this.formGroup = formService.createFormGroup(this.formModel);
	}

	ngOnInit() {
		for (let i = 0; i < this.form.medicalCondition.groups.length; i++) {
			this.form.medicalCondition.removeGroup(i);
		}
		this.insertMedicalCondition();
		if (this.data)
			this.initMedicalConditions(this.data);
	}

	initMedicalConditions(medicalConditions: any[]) {
		if (!medicalConditions)
			return;
		if (medicalConditions.length > 1) {
			for (let i = 1; i < medicalConditions.length; i++) {
				this.insertMedicalCondition();
			}
		}
		if (medicalConditions.length > 0)
			(<any>this.formGroup).controls['medicalCondition'].patchValue(medicalConditions.map(j => {
				return {value: j}
			}));
	}

	closeVisitForm() {
		this.dialogRef.close();
	}

	insertMedicalCondition() {
		let arrayModel = this.formService.findById('medicalCondition', this.formModel) as DynamicFormArrayModel;
		this.formService.insertFormArrayGroup(arrayModel.groups.length, <any>this.formGroup.controls['medicalCondition'], arrayModel);
	}

	remove(context: DynamicFormArrayModel, index) {
		this.formService.removeFormArrayGroup(index, <any>this.formGroup.controls['medicalCondition'], context);
	}

	showAddBtn(context: DynamicFormArrayModel, index) {
		return context.groups.length - 1 === index;
	}


	showRemoveBtn(context: DynamicFormArrayModel, index) {
		return context.groups.length > 1;
	}

	saveMedicalConditions() {
		this.dialogRef.close((<any>this.formGroup).controls['medicalCondition'].value.map(j => j.value).filter(j => j));
	}
}
