import { INSURERS_CONFIG } from '../@config/api';
import { HttpClient } from '@angular/common/http';
import { Social } from '../@models/system-managment/social';
import { Functions } from '../../@util/functions';
import { FileUploadingService } from "../../@util/file-uploading-progress/file-uploading.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../@util/file-uploading-progress/file-uploading.service";
export class InsurancesService {
    constructor(http, fileUploadingService) {
        this.http = http;
        this.fileUploadingService = fileUploadingService;
        this.config = INSURERS_CONFIG;
    }
    getInsurers() {
        return this.http.get(this.config.COMPANIES.getAll);
    }
    addInsurer(data) {
        return this.http.post(this.config.COMPANIES.addCompany, data);
    }
    setInsurerContact(insuraceId, value) {
        value.press = Functions.checkPhone(value.press);
        value.fax = Functions.checkPhone(value.fax);
        value.phone = Functions.checkPhone(value.phone);
        return this.http.post(this.config.COMPANIES.setContact(insuraceId), value);
    }
    setInsurerNote(insuraceId, val) {
        return this.http.post(this.config.COMPANIES.setNote(insuraceId), val);
    }
    getCurrentInsurance(insuraceId) {
        return this.http.get(this.config.COMPANIES.byId(insuraceId));
    }
    setSocial(insuraceId, value) {
        Social.checkLinck(value);
        return this.http.post(this.config.COMPANIES.setSocial(insuraceId), value);
    }
    setBasic(value) {
        return this.http.post(this.config.COMPANIES.setBasic(value.id), value);
    }
    setWebSite(id, website) {
        return this.http.post(this.config.COMPANIES.setWebSite(id), website);
    }
    uploadInsurerLogo(id, file) {
        return this.fileUploadingService.uploadFile(this.config.COMPANIES.uploadLogo(id), { file: file, fileInfo: null });
    }
    removeLogo(id) {
        return this.http.post(this.config.COMPANIES.removeLogo(id), {});
    }
}
InsurancesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InsurancesService_Factory() { return new InsurancesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FileUploadingService)); }, token: InsurancesService, providedIn: "root" });
