import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  HostListener,
  forwardRef,
  ViewChild, Output, EventEmitter
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import {
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  setYear,
  eachDay,
  getDate,
  getMonth,
  getYear,
  isToday,
  isSameDay,
  isSameMonth,
  isSameYear,
  format,
  getDay,
  subDays,
  setDay, setMonth
} from 'date-fns';
import { MatDialog } from "@angular/material/dialog";
import {PickerComponent} from "../picker/picker.component";

export type AddClass = string | string[] | { [k: string]: boolean } | null;

export interface DatepickerOptions {
  minYear?: number; // default: current year - 30
  maxYear?: number; // default: current year + 30
  displayFormat?: string; // default: 'MMM D[,] YYYY'
  barTitleFormat?: string; // default: 'MMMM YYYY'
  dayNamesFormat?: string; // default 'ddd'
  barTitleIfEmpty?: string;
  firstCalendarDay?: number; // 0 = Sunday (default), 1 = Monday, ..
  locale?: object;
  minDate?: Date;
  maxDate?: Date;
  /** Placeholder for the input field */
  placeholder?: string;
  /** [ngClass] to add to the input field */
  addClass?: AddClass;
  /** [ngStyle] to add to the input field */
  addStyle?: { [k: string]: any } | null;
  /** ID to assign to the input field */
  fieldId?: string;
  /** If false, barTitleIfEmpty will be disregarded and a date will always be shown. Default: true */
  useEmptyBarTitle?: boolean;
  formControlName?: string;
  name?:string;
  ngClass: string;
  readonly?: boolean;
  required?: boolean;
  tabIndex?: number | null;
  autofocus?:boolean;
  id?: string;
  min?: Date;
}

// Counter for calculating the auto-incrementing field ID
let counter = 0;

/**
 * Internal library helper that helps to check if value is empty
 * @param value
 */
const isNil = (value: Date | DatepickerOptions) => {
  return (typeof value === 'undefined') || (value === null);
};

@Component({
  selector: 'app-ng-datepicker',
  templateUrl: 'ng-datepicker.component.html',
  styleUrls: ['ng-datepicker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NgDatepickerComponent), multi: true }
  ]
})
export class NgDatepickerComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() options: DatepickerOptions;

  @ViewChild('inp', { static: false }) pickerInput;
  @ViewChild('calendarWrapper', { static: false }) calendarWrapper;
  // @ViewChild('ngxInputContainer') inputContainer;

  @Output()
  datePick = new EventEmitter();
  @Output()
  clearDate = new EventEmitter();
  @Output()
  onChange = new EventEmitter();

  /**
   * Disable datepicker's input
   */
  @Input() headless = false;
  @Input()
  set selectedValue(value) {
  	if (value) {
  		this.value = value;
	}
  };

  /**
   * Set datepicker's visibility state
   */
  @Input() isOpened = false;

  monthNameList = ['jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec',];

  /**
   * Datepicker dropdown position
   */
  @Input() position = 'bottom-right';


  private positions = ['bottom-left', 'bottom-right', 'top-left', 'top-right', 'auto'];

  @Input() startPosition: 'center' | 'start' | 'end';
  @Input() hideClear;
  @Input() hideTrigger;

  // toggleViewButtons: 'days' | 'years' | 'month' = 'days';
  // calculatedYearsArr: number;
  calendarXposition;
  calendarYposition;

  innerValue: Date;
  displayValue: string;
  displayFormat: string;
  date: Date;
  barTitle: string;
  barTitleFormat: string;
  barTitleIfEmpty: string;
  minYear: number;
  maxYear: number;
  selectedYear: number;
  firstCalendarDay: number;
  dayNamesFormat: string;
  locale: object;
  placeholder: string;
  addClass: AddClass;
  addStyle: { [k: string]: any } | null;
  fieldId: string;
  useEmptyBarTitle: boolean;
  @Input()
  disabled: boolean = false;
  autofocus:boolean;
  formControlName?: string;
  name?:string;
  ngClass: string;
  readonly?: boolean;
  required?: boolean;
  tabIndex?: number | null;
  min: Date;

  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_: any) => void = () => { };

  public setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  get value(): Date {
    return this.innerValue;
  }

  set value(val: Date) {
    this.innerValue = val;
    this.date = val;
    this.onChangeCallback(this.innerValue);
  }

  constructor(private elementRef: ElementRef, public dialog: MatDialog) {

  }

  openDialog(item): void {

    const dialogRef = this.dialog.open(PickerComponent, {
      panelClass: 'picker-dialog',
      backdropClass: 'picker-backdrop',
      minWidth: 296,
      data: {
        date: this.date,
        position: this.position,
        barTitle: this.barTitle,
        barTitleFormat: this.barTitleFormat,
        barTitleIfEmpty: this.barTitleIfEmpty,
        useEmptyBarTitle: this.useEmptyBarTitle,
        minYear: this.minYear,
        maxYear: this.maxYear,
        selectedYear: this.selectedYear,
        firstCalendarDay: this.firstCalendarDay,
        startPosition: this.startPosition,
        innerValue: this.innerValue,
        displayFormat: this.displayFormat,
        locale: this.locale,
        displayValue: this.displayValue,
        dayNamesFormat: this.dayNamesFormat,
        item: item
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        this.isOpened = !this.isOpened;
        return false
      }
      this.value = result;
      this.datePick.next(result);
      this.date = result;
      this.isOpened = false;
    });
  }

  ngOnInit() {
    this.date = this.value ? this.value : new Date();
    this.value = this.date;
    this.selectedYear = this.date.getFullYear();
    this.setOptions();

    // Check if 'position' property is correct
    if (this.positions.indexOf(this.position) === -1) {
      throw new TypeError(`ng-datepicker: invalid position property value '${this.position}' (expected: ${this.positions.join(', ')})`);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('options' in changes) {
      this.setOptions();
    }

	this.onChange.next(changes);
  }

  get defaultFieldId(): string {
    // Only evaluate and increment if required
    const value = `datepicker-${counter++}`;
    Object.defineProperty(this, 'defaultFieldId', {value});

    return value;
  }

  setOptions(): void {
    const today = new Date(); // this const was added because during my tests, I noticed that at this level this.date is undefined
    this.minYear = this.options && this.options.minYear || getYear(today) - 30;
    this.maxYear = this.options && this.options.maxYear || getYear(today) + 30;
    this.displayFormat = this.options && this.options.displayFormat || 'M[/]D[/]YYYY';
    this.barTitleFormat = this.options && this.options.barTitleFormat || 'MMMM YYYY';
    this.dayNamesFormat = this.options && this.options.dayNamesFormat || 'ddd';
    this.barTitleIfEmpty = this.options && this.options.barTitleIfEmpty || this.getCurrentShortDate();
    this.firstCalendarDay = this.options && this.options.firstCalendarDay || 0;
    this.locale = this.options && { locale: this.options.locale } || {};
    this.placeholder = this.options && this.options.placeholder || '';
    this.addClass = this.options && this.options.addClass || {};
    this.addStyle = this.options && this.options.addStyle || {};
    this.fieldId = this.options && this.options.id || this.defaultFieldId;
    this.useEmptyBarTitle = this.options && 'useEmptyBarTitle' in this.options ? this.options.useEmptyBarTitle : true;
    this.formControlName = this.options && this.options.formControlName;
    this.autofocus = this.options && this.options.autofocus;
    this.name = this.options && this.options.name;
    this.tabIndex = this.options && this.options.tabIndex;
    this.required = this.options && this.options.required;
	this.min = this.min && this.options.min;
  }

  toggle(item): void {
  	if(!this.disabled){
		this.isOpened = !this.isOpened;
		if (this.isOpened) {
			this.openDialog(item);
		}
	}
  }

  writeValue(val: Date) {
    if (val) {
      this.date = val;
      this.innerValue = val;
      //this.init();
      this.displayValue = format(this.innerValue, this.displayFormat, this.locale);
      this.barTitle = format(startOfMonth(val), this.barTitleFormat, this.locale);
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  clearInput() {
    this.clearDate.next(null);
  }

  private getCurrentShortDate() {
    let month = this.monthNameList[new Date().getMonth()];
    let year = new Date().getFullYear();
    return month +' '+ year;
  }
}
