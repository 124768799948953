import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {
	DynamicFormControlLayout,
	DynamicFormControlModel,
	DynamicFormService,
} from '@ng-dynamic-forms/core';
import {FormTamplate, FormValueBuilder} from '../../../../partial/forms/form-tamplate';
import {FormMode} from '../../../../../@models/system-managment/form-mode';
import {
	CHECK_BOX,
	DATE_PICKER,
	RADIO_USER_STATE,
	STRING_FIELD
} from '../../../../../../@util/dynamic-form/dynamic-controlls';
import {MedicalService} from '../../../../../@core/medical.service';
import {DatepickerCustompropsModel} from "../../../../../../@util/ui-material/datepicker-customprops/datepicker-customprops-model";
import {DynamicForm} from "../../../../../../@util/dynamic-form/dynamic-form";
import {FormClose} from "../../../../partial/forms/form-close";
import {Subscription} from "rxjs";
import {AuthenticationService} from "../../../../../auth/_services/authentication.service";
import {DynamicFormArrayModel as DynamicFormArray, DynamicTextAreaModel} from "../../../../../../@util/ui-material/form-controls";
import {DynamicFormArrayModel, DynamicInputModel} from "@ng-dynamic-forms/core/public-api";
import {State} from "../../../../../test-new-stepper-form/test-form/on-changes";
import {GlobalSaveService} from "../../../../../@core/global-save.service";
import {UserInfoService} from "../../../../../@core/user-info.service";
import {DateService} from "../../../../../@core/functions/date.service";
import {DialogService} from "../../../../../../@util/dialog/dialog.service";

@Component({
	selector: 'app-medical-treating-sources-medications',
	templateUrl: './medical-treating-sources-medications.component.html',
	styleUrls: ['./medical-treating-sources-medications.component.scss']
})
export class MedicalTreatingSourcesMedicationsComponent extends DynamicForm implements FormTamplate, FormClose {
	medications: any = [];
	private sourceId;
	@Input()
	sslgId;
	@Input()
	mode: FormMode;
	@Input()
	set sourceIdValue(id: number) {
		if (this.sourceId !== id) {
			this.sourceId = id;
			setTimeout(async () => {
				let catalogItem = await this.medicalService.getCatalogItemById( this.sourceId, this.sslgId).toPromise();
				this.prescribedBy = catalogItem.name;
				this.setPrescribedValue();
			})
		} else {
			setTimeout(() => {
				this.setPrescribedValue();
			})
		}
	}

	@Output()
	closeForm: EventEmitter<any> = new EventEmitter();
	@Output()
	saveForm: EventEmitter<any> = new EventEmitter();
	@Output()
	backForm: EventEmitter<any> = new EventEmitter();
	prescribedBy: any;
	formGroup: FormGroup;
	formModel: DynamicFormControlModel[];
	title: string = 'Medications';
	arrayControl: FormArray;
	arrayModel: DynamicFormArrayModel;
	formLayout = {
		'showMedications': <DynamicFormControlLayout>{
			element: {
				container: 'top-label'
			}
		},
		medications: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width form-label_mb',
				group: 'row-mb row row--padding'
			}
		},
		period: <DynamicFormControlLayout>{
			element: {
				control: 'form-row form-row_wrap',
				container: 'row-mb',
				children: 'form-element form-element_1-2 form_mb'
			}
		},
		medication: {
			element: {
				container: 'row-mb',
				host: 'col-12'
			}
		},
		reason: {
			element: {
				container: 'row-mb',
				host: 'col-12'
			}
		},
		sideEffects: {
			element: {
				container: '',
				host: 'col-12'
			}
		}
	};
	form = {
		showMedications: RADIO_USER_STATE({id: 'showMedications', options: [{label: 'YES', value: true}, {label: 'NO', value: false}], editableFor: ["EXECUTIVE_STAFF", "CLIENT"]}),
		medications: DynamicFormArray({
			id: 'medications',
			initialCount: 2,
			groupFactory: () => {
				return [
					STRING_FIELD({id: 'id', hidden: true,editableFor: ["EXECUTIVE_STAFF", "CLIENT"],}),
					STRING_FIELD({id: 'medication', label: 'Medication',editableFor: ["EXECUTIVE_STAFF", "CLIENT"],}),
					DynamicTextAreaModel({id: 'reason', label: 'Reason for Taking',editableFor: ["EXECUTIVE_STAFF", "CLIENT"],}),
					DynamicTextAreaModel({id: 'sideEffects', label: 'Side Effects',editableFor: ["EXECUTIVE_STAFF", "CLIENT"],}),
				];
			},editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		})
	};
	isMedicationsVisible:boolean = false;
	constructor(private formService: DynamicFormService,
				private medicalService: MedicalService,
				public globalSaveService: GlobalSaveService,
				private userInfoService: UserInfoService,
				private dialog: DialogService,
				private dateService: DateService,
				private auth: AuthenticationService) {
		super();
		this.formModel = FormValueBuilder.buildFormModel(this.form);
		this.formGroup = formService.createFormGroup(this.formModel);
		this.arrayControl = this.formGroup.controls['medications'] as FormArray;
		this.arrayModel = this.formService.findById('medications', this.formModel) as DynamicFormArrayModel;
	}

	insert() {
		console.log(this.arrayControl, this.arrayModel)
		this.formService.addFormArrayGroup(this.arrayControl, this.arrayModel);
		setTimeout(() => {
			console.log(this.arrayModel, this.arrayModel.groups, this.arrayModel.groups[this.arrayModel.groups.length - 1], this.arrayModel.groups[this.arrayModel.groups.length - 1].group[5]);
			(this.arrayModel.groups[this.arrayModel.groups.length - 1].group[5] as DynamicInputModel).valueUpdates.next(this.prescribedBy);
			FormValueBuilder.resetToDefault(this.arrayModel.groups[this.arrayModel.groups.length - 1].group);
		});
	}

	backMedications() {
		this.backForm.emit();
	}

	insertDouble() {
		this.insert();
		this.insert();
	}

	remove(index) {
		if (this.canRemove()) {
			this.formService.removeFormArrayGroup(index, this.arrayControl, this.arrayModel);
		}
	}

	removeDouble(index) {
		if (this.canRemove()) {
			this.dialog.show('Are you sure you want to delete this medications row?', ['OK', 'CANCEL'])
				.onCloseDialog.subscribe(j => {
				if (j === 'OK') {
					this.remove(index);
					this.remove(index - 1);
				}
			});
		}
	}

	isRemoveHidden() {
		return this.arrayModel.groups.length < 3;
	}

	canRemove() {
		return this.arrayModel.groups.length > 1;
	}

	async ngOnInit() {
		this.form.showMedications.valueUpdates.subscribe(j => {
			this.isMedicationsVisible = !!j;
			this.isMedicationsVisible ? document.querySelector('#medications').classList.remove('is-hidden') : document.querySelector('#medications').classList.add('is-hidden');
		});

		this.setNewVisit();
		FormValueBuilder.resetToDefault(this.form);
		this.formGroup.valueChanges.subscribe(j => {
			this.formStateChanged.emit({
				key: this.formKey,
				value: FormValueBuilder.isDirty(this.form) || (this.formGroup.value.medications as any[]).length < this.medications.length
			});
		});

		setTimeout(() => {
			this.formGroup.controls.showMedications.setValue(false);
		});
	}

	onBlur($event) {
	}

	onChange($event) {
		console.log($event);
		if (($event.model.id === 'startDate' || $event.model.id === 'endDate') &&
			$event.group.controls['startDate'].value !== null && $event.group.controls['endDate'].value !== null &&
			Date.parse($event.group.controls['startDate'].value) > Date.parse($event.group.controls['endDate'].value)) {
			setTimeout(() => {
				$event.group.controls['endDate'].patchValue(new Date(Date.parse($event.group.controls['startDate'].value)));
			});
		}
		if ($event.model.id === 'regular') {
			if ($event.model.value) {
				setTimeout(() => {
					($event.group.controls['endDate'] as FormControl).setValidators(null);
					($event.context.group[8] as DatepickerCustompropsModel).hidden = true;
					($event.context.group[8] as DatepickerCustompropsModel).required = false;
					($event.context.group[8] as DatepickerCustompropsModel).valueUpdates.next(null);
				})
			} else {
				($event.context.group[8] as DatepickerCustompropsModel).hidden = false;
				($event.context.group[8] as DatepickerCustompropsModel).required = true;
				($event.context.group[8] as DatepickerCustompropsModel).validators = {required: null};
				setTimeout(() => {
					($event.group.controls['endDate'] as FormControl).setValidators([Validators.required]);
				})
			}

		}
	}

	onFocus($event) {
	}

	closeMedications() {
		this.closeForm.emit();
	}

	async saveMedication() {
		let medications = this.formGroup.value.medications ? this.formGroup.value.medications : [];
		medications = (medications as any).filter(el => el.medication);
		let newFormGroupMedication = (medications as any).map(j => {
			//j.startDate =  this.dateService.getDateWithoutHours(j.startDate);
			//j.endDate =  this.dateService.getDateWithoutHours(j.endDate);

			if (j.medication) {
				return {
					name: j.medication,
					reason: j.reason,
					sideEffects: j.sideEffects,
				}
			}

		});
		FormValueBuilder.setControlsToched(this.formGroup);
		if (!this.formGroup.valid) {
			return;
		}

		let data: any = {showMedications: !!this.formGroup.value.showMedications};
		if (this.formGroup.value.showMedications) {
			data.medications = newFormGroupMedication;
		}
		this.saveForm.emit(data);

		// this.medicalService.setMedication(this.sslgId, this.visitId, newFormGroupMedication).subscribe(rez => {
		// 	FormValueBuilder.resetToDefault(this.form);
		// 	this.formStateChanged.emit({key: this.formKey, value: FormValueBuilder.isDirty(this.form)});
		// 	this.allFormStateChanged.emit(State.after);
		// });
	}

	getButtonType() {
		return this.canRemove() ? 'warning' : 'disabled';
	}

	private setPrescribedValue() {
		this.arrayModel.groups.forEach(item => {
			(item.group[5] as DynamicInputModel).valueUpdates.next(this.prescribedBy);
			FormValueBuilder.resetToDefault(item.group[5]);
		});
	}

	private setNewVisit() {
		if (this.mode === FormMode.EditMode && this.medications && this.medications.length > 0) {
			let newFormGroupMedication = this.medications.map(j => {
				return {
					genericForm: j.genericForm,
					id: j.id,
					name: j.name,
					reason: j.reason,
					regular: j.regular,
					sideEffects: j.sideEffects,
					startDate: j.period.startDate,
					endDate: j.period.endDate
				}
			});
			if (newFormGroupMedication.length !== this.form.medications.groups.length)
				newFormGroupMedication.forEach(j => this.insert());
			newFormGroupMedication.filter(j => !j.period).forEach(j => j.period = {startDate: null, endDate: null});

			this.formGroup.controls.medications.patchValue(newFormGroupMedication);

			newFormGroupMedication.forEach((item, indx) => {
				if (item.regular) {
					setTimeout(() => {
						(this.arrayModel.groups[indx].group[8] as DatepickerCustompropsModel).hidden = true;
						(this.arrayModel.groups[indx].group[8] as DatepickerCustompropsModel).required = true;
						(this.arrayModel.groups[indx].group[8] as DatepickerCustompropsModel).valueUpdates.next(null);
						(this.arrayModel.groups[indx].group[8] as DatepickerCustompropsModel).hidden = true;
						((<any>this.formGroup.controls['medications']).controls[indx].controls['endDate'] as FormControl).setValidators(null);
					})
				}
			});
		}
	}

	ngOnDestroy(): void {
		this.formStateChanged.next({value: false, key: this.formKey});
	}

	isClaimant() {
		if(this.userInfoService.getCurrentUserRole() == 'CLIENT'){
			return true;
		}

		return false;
	}
}
