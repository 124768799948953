import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as conf from '../@config/api';
import {UserInfoService} from "./user-info.service";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private config = conf.DASHBOARD_CONFIG;

  constructor(private http: HttpClient,
			  private userInfoService: UserInfoService,) {
  }

  getCardsInfo() {
    return this.http.get<any>( this.config.header.cardInfo );
  }

	getInfo() {
		return this.http.post<any>(this.config.header.info,{userName:this.getUserName(),});
	}

  getAdjusterCardsInfo() {
  	return this.http.get<any>(this.config.header.adjusterCardInfo);
  }

  getClaimantHistory() {
    return this.http.get<{key: any, value: number}[]>(this.config.header.claimantHistory);
  }

	getUserName(){
		return this.userInfoService.getCurrentUserName();
	}
}
