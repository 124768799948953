import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AUTH_CONFIG } from '../@config/api';
import { Subject } from 'rxjs';
import { CustomIrisNotificationService } from "../../@util/custom-iris-notification/custom-iris-notification.service";
import { map } from "rxjs/operators";
import { DialogService } from "../../@util/dialog/dialog.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../@util/custom-iris-notification/custom-iris-notification.service";
import * as i3 from "../../@util/dialog/dialog.service";
export class UserInfoService {
    constructor(http, notification, dialog) {
        this.http = http;
        this.notification = notification;
        this.dialog = dialog;
        this.storage = sessionStorage;
        this.userInfo = new Subject();
    }
    loadCurent() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.current = (yield this.http.get(AUTH_CONFIG.current).pipe(map(j => {
                // if (this.current == null && (+j.passwordChangeDaysLeft) > 80) {
                // 	this.notification.show(<any>{
                // 		autoHide: true,
                // 		timeOut: 15000,
                // 		message: `Your access will be revoked after ${j.passwordChangeDaysLeft} days.`,
                // 		title: "Change your password!",
                // 		type: 'info',
                // 		position: "rb",
                // 		imagePosition: 'right', // left or right
                // 		progressBar: 'linear', //linear or circle
                // 	});
                // }
                return j;
            })).toPromise()).user;
            this.userInfo.next(this.current);
        });
    }
    getCurrentUserName() {
        return this.storage.getItem('currentUserName');
    }
    getCurrentFirebaseToken() {
        return this.storage.getItem('currentFirebaseToken');
    }
    setCurrentFirebaseToken(token) {
        this.storage.setItem('currentFirebaseToken', token);
    }
    getCurrentUserRole() {
        return this.storage.getItem('currentUserRole');
    }
    getCurrentUserNameIsSystem() {
        if (this.getCurrentUserName() == 'system') {
            return true;
        }
        return false;
    }
    isAdjuster() {
        if (this.getCurrentUserRole() == 'ADJUSTER') {
            return true;
        }
        return false;
    }
    isStaff() {
        if (this.getCurrentUserRole() == 'EXECUTIVE_STAFF') {
            return true;
        }
        return false;
    }
    someStaff() {
        return !(this.getCurrentUserRole().indexOf('ADJUSTER') != -1 || this.getCurrentUserRole().indexOf('CLIENT') != -1);
    }
    isSslgStaff() {
        if (this.getCurrentUserRole() == 'SSLG_STAFF') {
            return true;
        }
        return false;
    }
    isClaimant() {
        if (this.getCurrentUserRole() == 'CLIENT') {
            return true;
        }
        return false;
    }
    setAudioNotifiicationRunning() {
        this.storage.setItem('audioNotifiication', "1");
    }
    setDeviceData(uuid, type) {
        this.storage.setItem('deviceUuid', uuid);
        this.storage.setItem('deviceType', type);
    }
    getDeviceUuid() {
        return this.storage.getItem('deviceUuid');
    }
    getDeviceType() {
        return this.storage.getItem('deviceType');
    }
    isApp() {
        if (this.getDeviceType() !== undefined && this.getDeviceType() !== null) {
            return true;
        }
        return false;
    }
    getAudioNotifiicationRunning() {
        return this.storage.getItem('audioNotifiication');
    }
    getToken() {
        let currentUser = JSON.parse(this.storage.getItem('currentUser'));
        return currentUser.token;
    }
}
UserInfoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserInfoService_Factory() { return new UserInfoService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomIrisNotificationService), i0.ɵɵinject(i3.DialogService)); }, token: UserInfoService, providedIn: "root" });
