const REST = '/api';
const SYSTEM_USER = REST + '/system-user';
const MANAGER_USER = REST + '/manager';
const CLIENT_USER = REST + '/client';
const AUTH = REST + '/session';

const COMPANIES = REST + '/insurance';
const DASHBOARD = REST + '/overview';
const CLAIMANT = REST + '/claimant';
const ARCHIVE = REST + '/archive-cases';
const PERMISSIONS = REST + '/permissions';
const CODES_DICTIONARY = REST + '/codes-dictionary';
const MEDICAL_SOURCE = REST + '/catalog';
const DDS_OFFICE = `${REST}/dds-offices`;
const FIELD_OFFICES = `${REST}/field-offices`;
const HEARING_OFFICE = `${REST}/hearing-offices`;
const GLOBAL_SAVE = REST + '/forms';
const DIARIES = REST + '/diaries';
const DEVICES = REST + '/devices';
const NOTIFICATIONS = REST + '/notifications';
const BOARDS = REST + '/boards';
const PROMOTIONS = REST + '/promotions';
const PROMOTIONS_USERS = PROMOTIONS + '/users';
const JOBS = REST + '/claimant/jobs';

const STATUS = (sslgId) => `${CLAIMANT}/${sslgId}/status`;
const REFERRAL = (sslgId) => `${CLAIMANT}/${sslgId}/referral`;
const MEDICAL_REQUEST = (sslgId) => `${CLAIMANT}/${sslgId}/medical-requests`;
const MEDICAL_RECORDS = (sslgId) => `${CLAIMANT}/${sslgId}/medical-records`;
const MEDICAL_RECORDS_SSA = (sslgId) => `${CLAIMANT}/${sslgId}/medical-records-ssa`;
const MEDICAL_VISITS = (sslgId) => `${CLAIMANT}/${sslgId}/medical-visits`;
const POST_AWARD = (sslgId) => `${CLAIMANT}/${sslgId}/post-award`;
const MEDICAL_CATALOG_ITEMS = (sslgId) => `${CLAIMANT}/${sslgId}/catalog-items`;
const CLAIMANT_HEARING_OFFICE = (sslgId) => `${CLAIMANT}/${sslgId}/hearing-offices`;
const CLAIMANT_DDS_OFFICE = (sslgId) => `${CLAIMANT}/${sslgId}/dds-offices`;
const FIELD_OFFICE = (sslgId) => `${CLAIMANT}/${sslgId}/field-offices`;
const CASE_ACCESS = (sslgId) => `${CLAIMANT}/${sslgId}/case-access`;
const AUDIT_LOG = (sslgId) => `${CLAIMANT}/${sslgId}/audit-log`;
const LAST_JOB = (sslgId) => `${CLAIMANT}/${sslgId}/jobs/last-job`;
const MESSAGES = (sslgId) => `${CLAIMANT}/${sslgId}/messages`;

export const AUTH_CONFIG = {
	login: AUTH + '/open',
	refresh: AUTH + '/refresh',
	current: AUTH + '/details',
	checkUser: AUTH + '/check-user',
	restore: AUTH + '/restore',
	sendUserName: AUTH + '/user-name',
};
export const CODES_DICTIONARY_CONFIG = {
	FOLLOW_UPS: {
		getCodes: CODES_DICTIONARY + '/follow-ups',
		getCodesByType: (type) => `${CODES_DICTIONARY}/follow-ups/find/${type}`,
		getCode: (ID) => CODES_DICTIONARY + '/follow-ups/' + ID,
		addCode: CODES_DICTIONARY + '/follow-ups',
		getAvailable: (type) => `${CODES_DICTIONARY}/follow-ups/free-code/${type}`,
	},
	PROSPECTS_TYPE: {
		getCodes: CODES_DICTIONARY + '/prospects/list',
		getCode: (ID) => CODES_DICTIONARY + '/prospects/' + ID,
		addCode: CODES_DICTIONARY + '/prospects/save',
	},
	REFERRAL_TYPE: {
		getCodes: CODES_DICTIONARY + '/referral-type',
		getCode: (ID) => CODES_DICTIONARY + '/referral-type/' + ID,
		addCode: CODES_DICTIONARY + '/referral-type',
	},
	POST_AWARD: {
		getCodes: CODES_DICTIONARY + '/post-award',
		getCode: (ID) => CODES_DICTIONARY + '/post-award/' + ID,
		addCode: CODES_DICTIONARY + '/post-award',
	},
	FORMS_SET: {
		getCodes: CODES_DICTIONARY + '/forms-set',
		getCode: (ID) => CODES_DICTIONARY + '/forms-set/' + ID,
		addCode: CODES_DICTIONARY + '/forms-set',
	},
	POLICYHOLDER: {
		getCodes: CODES_DICTIONARY + '/policyholder',
		getCode: (ID) => CODES_DICTIONARY + '/policyholder/' + ID,
		addCode: CODES_DICTIONARY + '/policyholder',
	},
	CLOSED: {
		getCodes: CODES_DICTIONARY + '/closed',
		getCode: (ID) => CODES_DICTIONARY + '/closed/' + ID,
		getAvalibleCode: (type) => CODES_DICTIONARY + `/closed/free-code/${type}`,
		isExist: (code) => CODES_DICTIONARY + `/closed/exist/${code}`,
		getTypes: CODES_DICTIONARY + '/closed/types',
		addCode: CODES_DICTIONARY + '/closed',
	},
	IMPAIRMENT: {
		getCodes: CODES_DICTIONARY + '/impairment',
		findLike: (value) => CODES_DICTIONARY + `/impairment/like/search/?search=${value}&page=${0}&size=${20}`,
		findByDescription: (value) => {
			return CODES_DICTIONARY + `/impairment/search/description/?search=${value}&page=${0}&size=${20}`;
		},
		getCode: (ID) => CODES_DICTIONARY + '/impairment/' + ID,
		addCode: CODES_DICTIONARY + '/impairment',
		fromFile: CODES_DICTIONARY + '/impairment/from-file',
	}
};
export const REFERRAL_CONFIG = {
	CLAIMANT: {
		create: CLAIMANT + '/create',
		getClaimants: CLAIMANT,
		getList: CLAIMANT + '/list',
		getInfo: (sslfId) => `${CLAIMANT}/${sslfId}/info`,
		getInfoForPdf: (sslfId) => `${CLAIMANT}/${sslfId}/infoForPdf`,
		getNewSslgId: (referredBy) => CLAIMANT + '/get-sslgId/' + referredBy,
		addClaimant: CLAIMANT + '/add',
		getClaimantLevelDetails: (sslgId) => CLAIMANT + '/' + sslgId + '/level',
		getAllCatalogItems: (sslgId) => CLAIMANT + '/' + sslgId + '/catalog',
		policyHolderCodes: (sslgId) => CLAIMANT + '/' + sslgId + '/policy-holder'
	},
	REFERRAL: {
		// getAll: REST + '/referral' + '/list',
		getById: (sslgId) => REFERRAL(sslgId) + '/item',
		setAcquisition: (sslgId) => REFERRAL(sslgId) + '/acquisition',
		// setSummary: (sslgId) => REFERRAL(sslgId) + '/summary', //@deprecated

		SSARules: (sslgId) => REFERRAL(sslgId) + '/ssa',
		SSLGStaff: (sslgId) => REFERRAL(sslgId) + '/staff',
		SSLGStaffForUser: (sslgId,userName) => REFERRAL(sslgId) + '/staff/'+userName,
		DisablingConditions: (sslgId) => REFERRAL(sslgId) + '/impairment',
		Income: (sslgId) => REFERRAL(sslgId) + '/ssdi',
		Dependents: (sslgId) => REFERRAL(sslgId) + '/dependents',
		ClaimantFlag: (sslgId) => REFERRAL(sslgId) + '/claimant-flag',
		setClaimantFlagIncome: (sslgId) => REFERRAL(sslgId) + '/set-flag-income',
		setClaimantOtherIncome: (sslgId) => REFERRAL(sslgId) + '/set-other-income',
		getClaimantFlagIncome: (sslgId) => REFERRAL(sslgId) + '/get-flag-income',
		getClaimantOtherIncome: (sslgId) => REFERRAL(sslgId) + '/get-other-income'
	},
	STATUS: {
		getById: (sslgId) => STATUS(sslgId),
		getStatusDetails: (sslgId) => `${STATUS(sslgId)}/details`,
		setIntake: (sslgId) => `${STATUS(sslgId)}/intake`,
		//saveIntake: (sslgId) => `${STATUS(sslgId)}/intake`,
		setLevelDetails: (sslgId) => `${STATUS(sslgId)}/level-details`,
		setTerminated: (sslgId) => `${STATUS(sslgId)}/terminated`,
		setHearingDetails: (sslgId) => `${STATUS(sslgId)}/hearing-details`,
		setHearingSchedule: (sslgId) => `${STATUS(sslgId)}/hearing-schedule`,
		setRemanded: (sslgId, remandedId) => `${STATUS(sslgId)}/remanded/${remandedId}`,
		getCeExam: (sslgId) => `${STATUS(sslgId)}/ce-exam`,
		setCeExam: (sslgId) => `${STATUS(sslgId)}/ce-exam`,
		getClosedCodes: (sslgId) => `${STATUS(sslgId)}/closed-codes`,
		isCanTerminate: (sslgId) => `${STATUS(sslgId)}/check-terminate`,
		getClaimantStatusDetails: (sslgId) => `${STATUS(sslgId)}/claimant-status`
	},
	NOTES: {
		getAll: (sslgId, type) => CLAIMANT + '/' + sslgId + '/notes' + (type && type != null && type !== 'null' ? ('/' + type) : ''),
		save: (sslgId) => CLAIMANT + '/' + sslgId + '/notes',
		getById: (sslgId, ID) => CLAIMANT + '/' + sslgId + '/note/' + ID
	},
	FOLLOW_UPS: {
		getById: (sslgId, id) => `${CLAIMANT}/${sslgId}/follow-ups/${id}`,
		getPending: (sslgId) => CLAIMANT + '/' + sslgId + '/follow-ups/pending',
		getPendingAndStatus: (sslgId,userName) => CLAIMANT + '/' + sslgId + '/' + userName + '/follow-ups/pending-status',
		addPending: (sslgId) => CLAIMANT + '/' + sslgId + '/follow-ups/pending',
		getCompleted: (sslgId) => CLAIMANT + '/' + sslgId + '/follow-ups/completed',
		setCompleted: (sslgId) => CLAIMANT + '/' + sslgId + '/follow-ups/completed',

		getCodes: (sslgId) => CLAIMANT + '/' + sslgId + '/follow-ups/codes',
	},
};
export const ARCHIVE_CONFIG = {
	getClosed: `${ARCHIVE}/closed-cases`,
	setArchived: `${ARCHIVE}/archive-cases`,
};
export const MANAGER_USER_CONFIG = {
	add: MANAGER_USER + '/add',
	getAll: (role) => MANAGER_USER + '/' + role + '/list',
	getById: (ID) => MANAGER_USER + '/' + ID,
	setSocial: (ID) => MANAGER_USER + '/' + ID + '/set-social',
	updateLoginInfo: (ID) => MANAGER_USER + '/' + ID + '/update-login-info',
	setPersonal: (ID) => MANAGER_USER + '/' + ID + '/set-personal',
	setContact: (ID) => MANAGER_USER + '/' + ID + '/set-contact',
	setCareer: (ID) => MANAGER_USER + '/' + ID + '/set-career',
};

export const CLIENT_USER_CONFIG = {
	add: CLIENT_USER + '/add',
	getAll: CLIENT_USER + '/list',
	forgotPassword: CLIENT_USER + '/forgot-password',
	getById: (ID) => CLIENT_USER + '/' + ID,
	findBasicData: (ID) => CLIENT_USER + '/user-basic/' + ID,
	findSocialData: (ID) => CLIENT_USER + '/' + ID + '/social',
	updateLoginInfo: (ID) => CLIENT_USER + '/' + ID + '/update-login-info',
	setSocial: (ID) => CLIENT_USER + '/' + ID + '/set-social',
	setClientBasic: (ID) => CLIENT_USER + '/' + ID + '/set-basic',
	setClientContact: (ID) => CLIENT_USER + '/' + ID + '/set-contact',
	setClientAddress: (ID) => CLIENT_USER + '/' + ID + '/set-address',
	getClientRemarks: (ID) => CLIENT_USER + '/' + ID + '/remarks/remark',
	setClientRemarks: (ID) => CLIENT_USER + '/' + ID + '/set-remarks',
	getClientCompBenefits: (ID) => CLIENT_USER + '/' + ID + '/comp-benefits/find',
	setClientCompBenefits: (ID) => CLIENT_USER + '/' + ID + '/set-comp-benefits',
};
export const SYSTEM_USER_CONFIG = {
	add: SYSTEM_USER + '/add',
	getAll: SYSTEM_USER + '/list',
	getById: (ID) => SYSTEM_USER + '/' + ID,
	setSocial: (ID) => SYSTEM_USER + '/' + ID + '/set-social',
	updateLoginInfo: (ID) => SYSTEM_USER + '/' + ID + '/update-login-info',
	setPersonal: (ID) => SYSTEM_USER + '/' + ID + '/set-personal',
	setContact: (ID) => SYSTEM_USER + '/' + ID + '/set-contact',
	selfEdit: {
		getById: (ID) => SYSTEM_USER + '/self-edit/' + ID,
		setSocial: (ID) => SYSTEM_USER + '/self-edit/' + ID + '/set-social',
		setPersonal: (ID) => SYSTEM_USER + '/self-edit/' + ID + '/set-personal',
		setContact: (ID) => SYSTEM_USER + '/self-edit/' + ID + '/set-contact',
	}
};
export const INSURERS_CONFIG = {
	COMPANIES: {
		getAll: COMPANIES + '/list',
		addCompany: COMPANIES + '/add',
		byId: (ID) => COMPANIES + '/' + ID,
		uploadLogo: (ID) => `${COMPANIES}/${ID}/upload-logo`,
		removeLogo: (ID) => `${COMPANIES}/${ID}/remove-logo`,
		setContact: (ID) => COMPANIES + '/set-contact' + '/' + ID,
		setNote: (ID) => COMPANIES + '/set-note' + '/' + ID,
		setSocial: (ID) => COMPANIES + '/set-social' + '/' + ID,
		setBasic: (ID) => COMPANIES + '/set-basic' + '/' + ID,
		setWebSite: (ID) => COMPANIES + '/set-website' + '/' + ID,
	},
	OFFICES: {
		getAll: COMPANIES + '/offices',
		getAllByCompanyId: (companyId) => COMPANIES + '/' + companyId + '/office/list',
		addOffice: (ID) => COMPANIES + '/' + ID + '/office/add',
		byId: (ID) => COMPANIES + '/office/' + ID,
		setContact: (ID) => COMPANIES + '/office/' + ID + '/set-contact',
		setNote: (ID) => COMPANIES + '/office/' + ID + '/set-note',
		setSocial: (ID) => COMPANIES + '/office/' + ID + '/set-social',
		setBasic: (ID) => COMPANIES + '/office/' + ID + '/set-basic',
		setWebAndLtd: (ID) => COMPANIES + '/office/' + ID + '/set-web-and-ltd',
		getWebAndLtd: (ID) => COMPANIES + '/office/' + ID + '/get-web-and-ltd',
	},
	// ADJUSTERS: undefined,
};

export const MEDICAL_CONFIG = {
	visit: {
		getVisits: (sslgId) => MEDICAL_VISITS(sslgId) + '/all-visits/' + sslgId, // +
		getVisit: (sslgId, ID) => MEDICAL_VISITS(sslgId) + '/visit/' + ID, // +
		getLastMedicalConditions: (sslgId, visitId) => MEDICAL_VISITS(sslgId) + '/visit-conditions/' + visitId, // +
		saveDoctorVisit: (sslgId) => MEDICAL_VISITS(sslgId) + '/doctor-visit', // +
		saveHospitalVisit: (sslgId) => MEDICAL_VISITS(sslgId) + '/hospital-visit', // +
		saveOtherVisit: (sslgId) => MEDICAL_VISITS(sslgId) + '/other-visit', // +
		getMedicalTests: (sslgId, visitId) => MEDICAL_VISITS(sslgId) + '/visit-medical-tests/' + visitId, // +
		setMedicalTests: (sslgId, visitId) => MEDICAL_VISITS(sslgId) + '/medical-test/' + visitId, // +
		setMedications: (sslgId, visitId) => MEDICAL_VISITS(sslgId) + '/medication/' + visitId, // +
		getMedications: (sslgId, visitId) => MEDICAL_VISITS(sslgId) + '/medication/' + visitId, // +
		getVisitsSummaries: (sslgId) => MEDICAL_VISITS(sslgId) + '/visit-reports', // +
		getVisitNotifications: (sslgId) => MEDICAL_VISITS(sslgId) + '/visit-notifications',
		getAlertNotifications: (sslgId) => MEDICAL_VISITS(sslgId) + '/alert-notifications',
		setVisitReaded: (sslgId) => MEDICAL_VISITS(sslgId) + '/visit-readed',
		saveStepTwo: (sslgId, visitId) => MEDICAL_VISITS(sslgId) + '/step-two/' + visitId, // +
		setMedicalCondition: (sslgId, visitId) => MEDICAL_VISITS(sslgId) + '/medical-condition/' + visitId, // +
		saveDoctorNextVisit: (sslgId, visitId) => MEDICAL_VISITS(sslgId) + '/doctor-next-visit/' + visitId, // +
	},
	getAllMedicationForClaim: (sslgId) => MEDICAL_VISITS(sslgId) + '/medications', // +
	changeMedicationStatus: (sslgId) => MEDICAL_VISITS(sslgId) + '/medications/change-status', // +
	addMedication: (sslgId) => MEDICAL_VISITS(sslgId) + '/medication/add', // +

	catalog_items: {
		getAllDoctors: (sslgId) => `${MEDICAL_CATALOG_ITEMS(sslgId)}/all-doctors`, // +
		getDoctorById: (sslgId, ID) => `${MEDICAL_CATALOG_ITEMS(sslgId)}/doctor/${ID}`,
		setDoctor: (sslgId) => `${MEDICAL_CATALOG_ITEMS(sslgId)}/set-doctor`, // +

		getAllHospitals: (sslgId) => `${MEDICAL_CATALOG_ITEMS(sslgId)}/all-hospitals`,
		getHospitalById: (sslgId, ID) => `${MEDICAL_CATALOG_ITEMS(sslgId)}/hospital/${ID}`,
		setHospital: (sslgId) => `${MEDICAL_CATALOG_ITEMS(sslgId)}/set-hospital`,

		getAllOthers: (sslgId) => `${MEDICAL_CATALOG_ITEMS(sslgId)}/all-others`,
		getOtherById: (sslgId, ID) => `${MEDICAL_CATALOG_ITEMS(sslgId)}/other/${ID}`,
		setOther: (sslgId) => `${MEDICAL_CATALOG_ITEMS(sslgId)}/set-other`,


		getSourceInfo: (sslgId, ID) => `${MEDICAL_CATALOG_ITEMS(sslgId)}/info/${ID}`,
		getCatalogItemById: (catalogItemId, sslgId) => `${MEDICAL_CATALOG_ITEMS(sslgId)}/${catalogItemId}`,
		getCatalogItemBySourceId: (catalogItemId, sslgId) => `${MEDICAL_CATALOG_ITEMS(sslgId)}/source/${catalogItemId}`,

		getMedicalTreatmentSourcesReport: (sslgId) => `${MEDICAL_CATALOG_ITEMS(sslgId)}/print`,
	}

};

export const MESSAGING_CONFIG = {
	getEmails: (sslgId) => `${MESSAGES(sslgId)}/emails`,
	getTexts: (sslgId) => `${MESSAGES(sslgId)}/sms`,
	sendEmail:  `${CLAIMANT}/messages/email/create`,
	sendText: `${CLAIMANT}/messages/sms/create`,
};

export const TIME_ZONE_CONFIG = {
	getList: REST + '/setting/time-zone/list'
};

export const DASHBOARD_CONFIG = {
	header: {
		cardInfo: DASHBOARD + '/card-info',
		adjusterCardInfo: DASHBOARD + '/adjuster-card-info',
		claimantHistory: DASHBOARD + '/claimant-history',
		info: DASHBOARD + '/info',
	}
};
export const MEDICAL_SOURCE_CONFIG = {
	getClaimantDoctors: (sslgId) =>MEDICAL_SOURCE + '/'+sslgId+'/claimant-doctors',
	getAllDoctors: MEDICAL_SOURCE + '/doctors',
	getDoctorById: (ID) => MEDICAL_SOURCE + '/doctor/' + ID,
	setDoctor: MEDICAL_SOURCE + '/doctor',
	saveTreatingSourcesDoctor: (sslgId)=>MEDICAL_SOURCE + '/'+sslgId+'/medical-treating-sources/save-doctor',
	addTreatingSourcesDoctor: (sslgId)=>MEDICAL_SOURCE + '/'+sslgId+'/medical-treating-sources/add-doctor',
	addTreatingSourcesDoctorDates: (sslgId)=>MEDICAL_SOURCE + '/'+sslgId+'/medical-treating-sources/add-doctor-dates',
	saveTreatingSourcesHospital: (sslgId)=>MEDICAL_SOURCE + '/'+sslgId+'/medical-treating-sources/save-hospital',
	addTreatingSourcesHospital: (sslgId)=>MEDICAL_SOURCE + '/'+sslgId+'/medical-treating-sources/add-hospital',
	addHospitalVisit: (sslgId)=>MEDICAL_SOURCE + '/'+sslgId+'/medical-treating-sources/add-hospital-visit',
	saveTreatingSourcesOther: (sslgId)=>MEDICAL_SOURCE + '/'+sslgId+'/medical-treating-sources/save-other',
	addTreatingSourcesOther: (sslgId)=>MEDICAL_SOURCE + '/'+sslgId+'/medical-treating-sources/add-other',
	saveTreatingSourcesRequests: (sslgId)=>MEDICAL_SOURCE + '/'+sslgId+'/medical-treating-sources/save-requests',
	treatingSourcesRequestsList: (sslgId)=>MEDICAL_SOURCE + '/'+sslgId+'/medical-treating-sources/requests',
	treatingSourcesPaymentsList: (sslgId)=>MEDICAL_SOURCE + '/'+sslgId+'/medical-treating-sources/payments',
	deleteSourceRequest: ()=>MEDICAL_SOURCE + '/medical-treating-sources/delete-request',
	deleteSourcePayment: ()=>MEDICAL_SOURCE + '/medical-treating-sources/delete-payment',
	treatingSourcesFindRequest: ()=>MEDICAL_SOURCE + '/medical-treating-sources/find-request',
	treatingSourcesLastUserToRecords: (sourceId)=>MEDICAL_SOURCE + '/'+sourceId+'/medical-treating-sources/find-last-user-to-records',
	treatingSourcesFindPayment: ()=>MEDICAL_SOURCE + '/medical-treating-sources/find-payment',
	saveTreatingSourcesRequest: (sslgId,id,sourceId)=>MEDICAL_SOURCE + '/'+sslgId+'/'+id+'/'+sourceId+'/medical-treating-sources/save-request',
	saveTreatingSourcesPayment: (sslgId,id,sourceId)=>MEDICAL_SOURCE + '/'+sslgId+'/'+id+'/'+sourceId+'/medical-treating-sources/save-payment',


	getAllHospitals: MEDICAL_SOURCE + '/hospitals',
	getHospitalById: (ID) => MEDICAL_SOURCE + '/hospital/' + ID,
	setHospital: MEDICAL_SOURCE + '/hospital',

	getAllOthers: MEDICAL_SOURCE + '/others',
	getOtherById: (ID) => MEDICAL_SOURCE + '/other/' + ID,
	setOther: MEDICAL_SOURCE + '/other',
	deleteSource: MEDICAL_SOURCE + '/delete-source',
	deleteSources: MEDICAL_SOURCE + '/delete-sources',
	changeStatusSource: MEDICAL_SOURCE + '/change-status-source',
};
export const PERMISSIONS_CONFIG = {
	users: (type) => PERMISSIONS + '/users/' + type,
	claimant: {
		permissions: (sslgId: string) => PERMISSIONS + '/claimant/' + sslgId,
		isHasPermissions: (sslgId: string) => PERMISSIONS + '/claimant/' + sslgId + '/has-permission',
		permit: (sslgId: string, userName: string) => PERMISSIONS + '/claimant/' + sslgId + '/permit/' + userName,
		forbid: (sslgId: string, userName: string) => PERMISSIONS + '/claimant/' + sslgId + '/forbid/' + userName,
		claimants: PERMISSIONS + '/claimants'
	}
};
export const LTD_INFO_CONFIG = {
	getLtd: REST + '/ltd-info',
	setLtd: REST + '/ltd-info',
};
export const ALG_INFO_CONFIG = {
	getAll: `${REST}/alg/get-all`,
	previousOff: `${REST}/alg/previous-off`,
	hearingOff: `${REST}/alg/hearing-off`,
	getType: `${REST}/alg/alg-type`,
	getCollege: `${REST}/alg/alg-college`,
	getById: (algId) => `${REST}/alg/get-by-id/${algId}`,
	createAlg: `${REST}/alg/new`
};
export const POST_AWARD_CONFIG = {
	cora_details: {
		primary: (sslgId) => `${POST_AWARD(sslgId)}/cora/primary`,
		dependent: (sslgId) => `${POST_AWARD(sslgId)}/cora/dependent`,
		enrolment: (sslgId) => `${POST_AWARD(sslgId)}/cora/enrolment`,
		summary: (sslgId) => `${POST_AWARD(sslgId)}/cora/summary`,
		meters: (sslgId) => `${POST_AWARD(sslgId)}/cora/meters`,
	},
	award_details: {
		overview: (sslgId) => `${POST_AWARD(sslgId)}/detail/overview`,
		primary: (sslgId) => `${POST_AWARD(sslgId)}/detail/primary`,
		dependent: (sslgId) => `${POST_AWARD(sslgId)}/detail/dependent`,
	},
	detail: {
		getAwardDetail: (sslgId) => `${POST_AWARD(sslgId)}/detail`,
		setAwardDetail: (sslgId) => `${POST_AWARD(sslgId)}/detail`,

		getCoraDetail: (sslgId) => `${POST_AWARD(sslgId)}/cora`,
		setCoraDetail: (sslgId) => `${POST_AWARD(sslgId)}/cora`,
	},
	auth: {
		auth: (sslgId) => `${POST_AWARD(sslgId)}/cora/auth`,
		sent: (sslgId) => `${POST_AWARD(sslgId)}/cora/auth/sent`,
		receive: (sslgId) => `${POST_AWARD(sslgId)}/cora/auth/receive`,
		clear: (sslgId) => `${POST_AWARD(sslgId)}/cora/auth/clear`,
		setText: (sslgId) => `${POST_AWARD(sslgId)}/cora/auth/text`
	}
};
export const MEDICAL_REQUEST_CONFIG = {
	addRequest: (sslgId) => MEDICAL_REQUEST(sslgId) + '/add',
	setRequestDetails: (sslgId, ID) => MEDICAL_REQUEST(sslgId) + '/' + ID,
	requests: (sslgId) => MEDICAL_REQUEST(sslgId) + '/item',
	getRequest: (sslgId, ID) => MEDICAL_REQUEST(sslgId) + '/' + ID,
	sendRequestEmails: (sslgId, ID) => MEDICAL_REQUEST(sslgId) + '/send-emails/' + ID,
	getSourceInfo: (sslgId, sourceId) => MEDICAL_REQUEST(sslgId) + '/source-details/' + sourceId,
	saveAdditional: (sslgId, requestId) => MEDICAL_REQUEST(sslgId) + '/' + requestId + '/additional-details',
	getAdditional: (sslgId, requestId) => MEDICAL_REQUEST(sslgId) + '/' + requestId + '/additional-details', // @not used
	getFeeDetails: (sslgId, requestId) => MEDICAL_REQUEST(sslgId) + '/' + requestId + '/fee-details',
	saveFeeDetails: (sslgId, requestId) => MEDICAL_REQUEST(sslgId) + '/' + requestId + '/fee-details',
};
export const MEDICAL_RECORDS_CONFIG = {
	getVisitsInformation: (sslgId, catalogItemId) => `${MEDICAL_RECORDS(sslgId)}/visit-info/${catalogItemId}`,
	getRequestsByTypeAll: (sslgId) => `${MEDICAL_RECORDS(sslgId)}/requests-by-type`,
	getRequestsByTypeOne: (sslgId, requestType) => `${MEDICAL_RECORDS(sslgId)}/requests-by-type/${requestType}`,
	createRequest: (sslgId) => `${MEDICAL_RECORDS(sslgId)}/create-request`,
	downloadDocument: (sslgId, documentId) => `${MEDICAL_RECORDS(sslgId)}/download-document/${documentId}`,
	getRequestById: (sslgId, requestId) => `${MEDICAL_RECORDS(sslgId)}/request-by-id/${requestId}`,
	getUploadedDocument: (sslgId, documentId) => `${MEDICAL_RECORDS(sslgId)}/uploaded-document/${documentId}`,
	uploadFile: (sslgId, requestId) => `${MEDICAL_RECORDS(sslgId)}/upload-file/${requestId}`,
	getDocTypes: (sslgId) => `${MEDICAL_RECORDS(sslgId)}/get-doc-types`,
	getDocumentById: (sslgId, documentId) => `${MEDICAL_RECORDS(sslgId)}/doc-by-id/${documentId}`,
	updateDocument: (sslgId, docId) => `${MEDICAL_RECORDS(sslgId)}/update-document/${docId}`,
	deleteDocument: (sslgId, docId) => `${MEDICAL_RECORDS(sslgId)}/delete-document/${docId}`,
	changeDocumentStatus: (sslgId, docId) => `${MEDICAL_RECORDS(sslgId)}/change-doc-status/${docId}`,
	sendDocument: (sslgId, docId) => `${MEDICAL_RECORDS(sslgId)}/send-document/${docId}`,

	getSSADocument: (sslgId) => `${MEDICAL_RECORDS_SSA(sslgId)}/ssa-records`,
	uploadSSAFile: (sslgId) => `${MEDICAL_RECORDS_SSA(sslgId)}/upload-ssa-file`,
	getDocSSATypes: (sslgId) => `${MEDICAL_RECORDS_SSA(sslgId)}/get-ssa-types`,
	downloadSSADocument: (sslgId, docId) => `${MEDICAL_RECORDS_SSA(sslgId)}/download-ssa-doc/${docId}`,
	changeSSADocumentStatus: (sslgId, docId) => `${MEDICAL_RECORDS_SSA(sslgId)}/change-ssa-status/${docId}`,
	getDocumentSsaById: (sslgId, docId) => `${MEDICAL_RECORDS_SSA(sslgId)}/get-ssa-by-id/${docId}`,
	updateDocumentSsa: (sslgId, docId) => `${MEDICAL_RECORDS_SSA(sslgId)}/update-ssa-by-id/${docId}`,
};
export const VERSION_CONFIG = {
	getVersions: `${REST}/versions`,
	getLastVersion: `${REST}/versions/current`,
	getVersionById: (versionId) => `${REST}/versions/${versionId}`,
	saveVersionById: (versionId) => `${REST}/versions/${versionId}/edit`,
	createVersion: `${REST}/versions/new`
};

export const CLAIMANT_FIELD_OFFICE_CONFIG = {
	findByUserName: (sslgId) => `${FIELD_OFFICE(sslgId)}/find`,
	update: (sslgId) => `${FIELD_OFFICE(sslgId)}`,
};

/*export const FIELD_OFFICE_CONFIG = {
	findByUserName: (sslgId) => `${FIELD_OFFICE(sslgId)}/find`,
	get: (sslgId) => `${FIELD_OFFICE(sslgId)}/office`,
	update: (sslgId) => `${FIELD_OFFICE(sslgId)}`,
	//find: (sslgId, zip) => `${FIELD_OFFICE(sslgId)}/search/zip/${zip}`,
};*/

export const FIELD_OFFICES_CONFIG = {
	findByUserName: (sslgId) => `${FIELD_OFFICE(sslgId)}/find`,
	get: (id) => `${FIELD_OFFICES}/`+id,
	saveOffice: `${FIELD_OFFICES}`,
	getAll: `${FIELD_OFFICES}`,
	getFieldData: (sslgId) => `${FIELD_OFFICE(sslgId)}/find-data`,
};

export const CLAIMANT_HEARING_OFFICE_CONFIG = {
	get: (sslgId) => `${CLAIMANT_HEARING_OFFICE(sslgId)}`,
	update: (sslgId) => `${CLAIMANT_HEARING_OFFICE(sslgId)}`,
	getData: (sslgId) => `${CLAIMANT_HEARING_OFFICE(sslgId)}/find-data`,
};

export const HEARING_OFFICE_CONFIG = {
	getAll: `${HEARING_OFFICE}`,
	getById: (ID) => `${HEARING_OFFICE}/${ID}`,
	save: `${HEARING_OFFICE}`,
	update: (ID) => `${HEARING_OFFICE}/${ID}`
};

export const CLAIMANT_DDS_OFFICE_CONFIG = {
	get: (sslgId) => `${CLAIMANT_DDS_OFFICE(sslgId)}`,
	update: (sslgId) => `${CLAIMANT_DDS_OFFICE(sslgId)}`,
};
export const DDS_OFFICE_CONFIG = {
	getAll: `${DDS_OFFICE}`,
	saveOffice: `${DDS_OFFICE}`,
	get: (officeId) => `${DDS_OFFICE}/${officeId}`,
	update: (officeId) => `${DDS_OFFICE}/${officeId}`,
};
export const CASE_ACCESS_HISTORY = {
	history: (sslgId) => CASE_ACCESS(sslgId) + '/history',
	lastAccessed: (sslgId) => CASE_ACCESS(sslgId) + '/last'
};

export const AUDIT_LOG_CONFIG = {
	sendLog: (sslgId) => AUDIT_LOG(sslgId),
	auditLogs: (sslgId) => AUDIT_LOG(sslgId),
	auditLogInfo: (sslgId, auditLogId) => `${AUDIT_LOG(sslgId)}/${auditLogId}`,
	reSendAuditLog: (sslgId, auditLogId) => `${AUDIT_LOG(sslgId)}/${auditLogId}`,
	readNotification: (sslgId, notifiedUserId) => `${AUDIT_LOG(sslgId)}/read/${notifiedUserId}`,
};

export const GLOBAL_SAVE_CONFIG = {
	changeStatus: GLOBAL_SAVE+'/change-status',
	getForm: GLOBAL_SAVE+'/find-form',
	saveFormData: GLOBAL_SAVE+'/data/save',
	saveLastClaimant: GLOBAL_SAVE+'/data/save-last-claimant',
	save: GLOBAL_SAVE+'/save',
	clearLastClaimant: GLOBAL_SAVE+'/clear-last-claimant',
	getLastClaimantInfo: GLOBAL_SAVE+'/last-claimant-info',
};

export const DIARIES_CONFIG = {
	getList: DIARIES+'/list'
};

export const DEVICES_CONFIG = {
	add: DEVICES+'/add',
	saveFirebaseToken: DEVICES+'/save-firebase-token',
};

export const NOTIFICATIONS_CONFIG = {
	quantity: (sslgId) => NOTIFICATIONS+'/'+sslgId+'/quantity',
	getAlerts: (sslgId) => NOTIFICATIONS+'/'+sslgId+'/alerts',
	setWatched: NOTIFICATIONS+'/watched'
};

export const BOARDS_CONFIG = {
	find: () => BOARDS+'/find',
	saveBoard: () => BOARDS+'/save-board',

};

export const MULTI_MEDIA_CONFIG = {
	getGroupList: `/api/media/groups/list`,
	createGroup: `/api/media/groups/create`,
	editGroup: `/api/media/groups/change-title`,
	deleteGroup: `/api/media/groups/delete`,
	setGroupOrder: `/api/media/groups/save-order`,

	getFilesList: `/api/media/files/list`,
	addFile: `/api/media/files/create`,
	editFile: `/api/media/files/change-title`,
	deleteFile: `/api/media/files/delete`,
	setFilesOrder: `/api/media/files/save-order`,
	getClaimantMedia: '/api/media/files/claimants/list',
	allowClaimantMedia: '/api/media/files/claimants/save-allowed',
	setClaimantMediaPlayed: '/api/media/files/claimants/played',
};

export const PROMOTIONS_CONFIG = {
	getList: PROMOTIONS_USERS + '/list',
	getCategories: PROMOTIONS_USERS + '/categories',
	getCategory: PROMOTIONS_USERS + '/category',
	changeStatus: PROMOTIONS_USERS + '/change-status',
	claimantCreated: PROMOTIONS_USERS + '/claimant',
	updateChatAnswers: PROMOTIONS_USERS + '/update-answers',
	sendMessage: PROMOTIONS_USERS + '/message/send'
}

export const JOBS_CONFIG = {
	getList: JOBS + '/previous/list',
	getItemInfo: JOBS + '/previous/info',
	saveItem: JOBS + '/previous/save',
	deleteJob: JOBS + '/previous/delete',
	LAST: {
		Description: (sslgId) => LAST_JOB(sslgId) + '/description',
		Details: (sslgId) => LAST_JOB(sslgId) + '/details',
		AdditionalInfo: (sslgId) => LAST_JOB(sslgId) + '/additional-info',
		DescriptionSave: (sslgId) => LAST_JOB(sslgId) + '/description/save',
		DetailsSave: (sslgId) => LAST_JOB(sslgId) + '/details/save',
		AdditionalInfoSave: (sslgId) => LAST_JOB(sslgId) + '/additional-info/save',
	},
}
