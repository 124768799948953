import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {IrsTableService} from "./irs-table.service";
import {CdkTableModule} from "@angular/cdk/table";
import {CellId} from "./cellId";
import {IrsDynamicTableComponent} from "./irs-dynamic-table/irs-dynamic-table.component";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';

import {NgxMaskModule} from 'ngx-mask';
import { IrisTableTabsComponent } from './iris-table-tabs/iris-table-tabs.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatRadioModule} from "@angular/material";
import {NgDatepickerModule} from "../new-datepicker/module/ng-datepicker.module";

@NgModule({
	declarations: [
		IrsDynamicTableComponent,
		CellId,
		IrisTableTabsComponent
	],
	imports: [
		CommonModule,
		CdkTableModule,
		NgxMaskModule.forRoot(),
		MatFormFieldModule,
		MatSelectModule,
		FormsModule,
		ReactiveFormsModule,
		MatRadioModule,
		NgDatepickerModule
	],
	exports: [
		IrsDynamicTableComponent,
		CellId
	],
	entryComponents: [
		IrsDynamicTableComponent
	],
	providers: [IrsTableService]
})
export class IrsTableModule {

}
