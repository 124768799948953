<app-frame (neadClose)="closeMedications()"
		   [title]="title"
		   [showCloseButton]="false"
		   [class.no-frame]="true"
		   autocomplete="off">
  <form [formGroup]="formGroup" class="form-horizontal" novalidate>
    <dynamic-material-form (blur)="onBlur($event)"
                           (change)="onChange($event)"
                           (focus)="onFocus($event)"
                           [group]="formGroup"
                           [layout]="formLayout"
                           [model]="formModel">
		<ng-template modelId="showMedications" align="START">
			<label class="form-label form-label_full-width form-label_mb">
				Did this Doctor prescribe any medications for you?
			</label>
		</ng-template>
		<ng-template modelId="medications"  let-context="context" let-index="index"  align="END">
			<ng-container *ngIf="index % 2">
				<app-form-actions-content class="custom-btn col-3 remove-btn auto-f-basis" *appCanAccess="['EXECUTIVE_STAFF']">
					<!--				<button *ngIf = "showRemoveBtn(context, index)" (click)="remove(context, index)" class="close-btn">-->
					<!--					<span></span>-->
					<!--					<span></span>-->
					<!--				</button>-->
					<!--				<button class="close-btn">-->
					<!--					<span></span>-->
					<!--					<span></span>-->
					<!--				</button>-->
					<form-button (clickOnButton)="removeDouble(index)"
								 [style.display]="isRemoveHidden() ? 'none' : 'inline-block'"
								 style="padding-top: 1em"
								 [buttonType]="getButtonType()"
								 *appCanAccess="['EXECUTIVE_STAFF','CLIENT']">Remove</form-button>
				</app-form-actions-content>
			</ng-container>
		</ng-template>
    </dynamic-material-form>
  </form>
	<ng-container *ngIf="(this.globalSaveService.getStatusTrue() || isClaimant()) && isMedicationsVisible">
		<app-form-actions-content class="no-mt" *appCanAccess="['EXECUTIVE_STAFF','CLIENT']">
			<form-button (clickOnButton)="insertDouble()">Add</form-button>
<!--			<form-button (clickOnButton)="removeDouble()"-->
<!--						 [style.display]="isRemoveHidden() ? 'none' : 'inline-block'"-->
<!--						 [buttonType]="getButtonType()"-->
<!--						 *appCanAccess="['EXECUTIVE_STAFF','CLIENT']">Remove</form-button>-->
		</app-form-actions-content>
	</ng-container>
	<app-form-actions-content *appCanAccess="['EXECUTIVE_STAFF','CLIENT']">
		<form-button style="margin-right: auto"
					 class="form-button-back"
					 *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" (clickOnButton)="backMedications()">Back</form-button>
		<form-button *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" (clickOnButton)="saveMedication()">Finish</form-button>
	</app-form-actions-content>
</app-frame>
