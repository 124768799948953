<div [class.is-edit-mode]="isEditMode">
    <section class="table-section">
        <div class="mobile-table-action" *ngIf="isClaimant() || isExecutive()">
            <button (click)="isEditMode ? saveChanges() : onEditMode()"
                    *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()"
                    class="table-button table-button_add table-button_edit"
                    title="Switch Edit Mode">
                <ng-container *ngIf="!isEditMode">EDIT</ng-container>
                <ng-container *ngIf="isEditMode">SAVE</ng-container>
                <svg class="table-button__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 490.383 490.383" style="enable-background:new 0 0 490.383 490.383; visibility: hidden; width: 0" xml:space="preserve">
                        <path d="M468.2,170.891H93c-9.7,0-17.9,6.2-21,15.2l-70.8,227.8c-4.3,14,6.2,28.4,21,28.4h375.2c9.7,0,17.9-6.2,21-15.2
                    l71.2-227.5C493.4,185.291,482.9,170.891,468.2,170.891z M342.6,331.091h-73.1v73.1h-49v-73.1h-73.1v-49h73.1v-73.1h49v73.1h73.1
                    V331.091z"/>
                    <path d="M53.3,180.291c5.4-17.5,21.4-29.2,39.7-29.2h327.8v-26.1c0-12.1-9.7-21.8-21.8-21.8H220.5c-15.2,0-27.6-12.4-27.6-27.6
                    s-12.5-27.5-27.6-27.5H21.8c-12,0-21.8,10.1-21.8,22.1v280L53.3,180.291z"/>
                    </svg>
            </button>
            <button *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" (click)="addMedication()" class="table-button table-button_add" title="Add New Doctor">Add

                <svg class="table-button__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 490.383 490.383" style="enable-background:new 0 0 490.383 490.383;" xml:space="preserve">
                <path d="M468.2,170.891H93c-9.7,0-17.9,6.2-21,15.2l-70.8,227.8c-4.3,14,6.2,28.4,21,28.4h375.2c9.7,0,17.9-6.2,21-15.2
								l71.2-227.5C493.4,185.291,482.9,170.891,468.2,170.891z M342.6,331.091h-73.1v73.1h-49v-73.1h-73.1v-49h73.1v-73.1h49v73.1h73.1
								V331.091z"/>
                    <path d="M53.3,180.291c5.4-17.5,21.4-29.2,39.7-29.2h327.8v-26.1c0-12.1-9.7-21.8-21.8-21.8H220.5c-15.2,0-27.6-12.4-27.6-27.6
								s-12.5-27.5-27.6-27.5H21.8c-12,0-21.8,10.1-21.8,22.1v280L53.3,180.291z"/>
			</svg>
            </button>
        </div>
        <div class="controls-hide-mobile" *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" style="margin-top: -1em;">
            <div class="table-button_add">
                <button (click)="isEditMode ? saveChanges() : onEditMode()"
                            class="table-button table-button_add table-button_edit"
                            *appCanAccess="['EXECUTIVE_STAFF','CLIENT']" title="Switch Edit Mode">
                    <ng-container *ngIf="!isEditMode">EDIT</ng-container>
                    <ng-container *ngIf="isEditMode">SAVE</ng-container>
                    <svg class="table-button__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             viewBox="0 0 490.383 490.383" style="enable-background:new 0 0 490.383 490.383; visibility: hidden; width: 0" xml:space="preserve">
                        <path d="M468.2,170.891H93c-9.7,0-17.9,6.2-21,15.2l-70.8,227.8c-4.3,14,6.2,28.4,21,28.4h375.2c9.7,0,17.9-6.2,21-15.2
                    l71.2-227.5C493.4,185.291,482.9,170.891,468.2,170.891z M342.6,331.091h-73.1v73.1h-49v-73.1h-73.1v-49h73.1v-73.1h49v73.1h73.1
                    V331.091z"/>
                        <path d="M53.3,180.291c5.4-17.5,21.4-29.2,39.7-29.2h327.8v-26.1c0-12.1-9.7-21.8-21.8-21.8H220.5c-15.2,0-27.6-12.4-27.6-27.6
                    s-12.5-27.5-27.6-27.5H21.8c-12,0-21.8,10.1-21.8,22.1v280L53.3,180.291z"/>
                    </svg>
                </button>

                <button (click)="addMedication()" class="table-button table-button_add" *appCanAccess="['EXECUTIVE_STAFF','CLIENT']" title="Add New Medication">ADD
                    <svg class="table-button__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 490.383 490.383" style="enable-background:new 0 0 490.383 490.383;" xml:space="preserve">
                    <path d="M468.2,170.891H93c-9.7,0-17.9,6.2-21,15.2l-70.8,227.8c-4.3,14,6.2,28.4,21,28.4h375.2c9.7,0,17.9-6.2,21-15.2
                    l71.2-227.5C493.4,185.291,482.9,170.891,468.2,170.891z M342.6,331.091h-73.1v73.1h-49v-73.1h-73.1v-49h73.1v-73.1h49v73.1h73.1
                    V331.091z"/>
                        <path d="M53.3,180.291c5.4-17.5,21.4-29.2,39.7-29.2h327.8v-26.1c0-12.1-9.7-21.8-21.8-21.8H220.5c-15.2,0-27.6-12.4-27.6-27.6
                    s-12.5-27.5-27.6-27.5H21.8c-12,0-21.8,10.1-21.8,22.1v280L53.3,180.291z"/>
                </svg>
                </button>
            </div>

        </div>
    </section>

    <ul class="medication-types" *ngFor="let type of getTypes(); trackBy: identify">
        <li class="medication-types__item">
            <h3 class="medication-types__title" (click)="showRow(type)">
                <span class="medication-types__text">{{type}}</span>

                <span class="acco-title__indicator" [class.acco-title__indicator_active]="canShowRow(type)">
                <svg class="acco-title__icon">
                  <use xlink:href="../../../../assets/img/sprite.svg#close"></use>
                </svg>
            </span>
            </h3>
            <form [formGroup]="formGroup">
                <ul class="medications form-fieldset" *ngIf="canShowRow(type)">
                    <li class="medications__item medication"
                        [class.is-doctor]="isDoctorType(type)"
                        [class.is-hospital]="isHospitalType(type)"
                        [class.is-other]="isOtherType(type)"
                        *ngFor="let visitMedications of getMedications(type); trackBy: identify2">
                        <div class="medication__content">
                            <p class="medication__heading">
                                {{visitMedications[0].catalogItemName}}
                                <!--                        {{getClaimName(visitMedications)}}-->
                            </p>
                            <div class="preparations" [class.preparations--first]="!index" *ngFor="let claimMedications of visitMedications.medicationsList; index as index">
                                <div  class="preparations__col preparations__col--row preparations__col--big preparations__status preparations__status--desk"
                                               [cdkDropListGroupDisabled]="!isEditMode" cdkDropListGroup>
                                    <div class="preparations__col preparations__col--big preparations__col--flex-stretch">
                                    <div class="preparations__label" *ngIf="!index">Discontinued Medications</div>
                                    <!--                                        <div-->
                                    <!--                                                cdkDropList-->
                                    <!--                                                #stopList="cdkDropList"-->
                                    <!--                                                [cdkDropListData]="claimMedications.inactiveList"-->
                                    <!--                                                [cdkDropListConnectedTo]="[activeList]"-->
                                    <!--                                                class="preparations__list preparations__col&#45;&#45;flex"-->
                                    <!--                                                (cdkDropListDropped)="dropSegments($event, claimMedications, visitMedications)">-->
                                    <!--                                            <div class="preparation" cdkDrag-->
                                    <!--                                                 [ngClass]="medication.period && medication.period.takingMedication ? 'preparation_active' : 'preparation_disable' "-->
                                    <!--                                                 *ngFor="let medication of claimMedications.inactiveList">-->
                                    <!--                                                <p class="preparation__text name" [title]="medication.medicationName">{{medication.medicationName}}</p>-->
                                    <!--                                                <p class="preparation__text">({{ getEndDate(medication)}})</p>-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <div class="form-element preparations__col--flex"
                                         cdkDropList
                                         #inactiveList="cdkDropList"
                                         [cdkDropListData]="claimMedications.inactiveList"
                                         [cdkDropListConnectedTo]="[activeList]"
                                         (cdkDropListDropped)="dropSegments($event, claimMedications)">
                                        <div class="mat-form-field-wrapper"
                                             cdkDrag
                                             *ngFor="let medication of claimMedications.inactiveList">
                                            <div class="mat-input-element" style="border: none; padding: 0; background: transparent">
                                                <div class="mat-input-element preparation"
                                                     [ngClass]="isEditMode ? 'preparation_active' : 'preparation_disable' ">
                                                    <p class="preparation__text name" [title]="medication.medicationName">{{medication.medicationName}}</p>
                                                    <p class="preparation__text">
                                                        <ng-container *ngIf="getDate(medication)">
                                                            ({{ getDate(medication)}})
                                                        </ng-container>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                    <div class="preparations__col preparations__col--big  preparations__col--flex-stretch">
                                    <div class="preparations__label" *ngIf="!index">Active Medications</div>
                                    <!--                                        <div-->
                                    <!--                                                cdkDropList-->
                                    <!--                                                #activeList="cdkDropList"-->
                                    <!--                                                [cdkDropListData]="claimMedications.activeList"-->
                                    <!--                                                [cdkDropListConnectedTo]="[stopList]"-->
                                    <!--                                                class="preparations__list preparations__col&#45;&#45;flex"-->
                                    <!--                                                (cdkDropListDropped)="dropSegments($event, claimMedications, visitMedications)">-->
                                    <!--                                            <div class="preparation" cdkDrag-->
                                    <!--                                                 [ngClass]="medication.period && medication.period.takingMedication ? 'preparation_active' : 'preparation_disable' "-->
                                    <!--                                                 *ngFor="let medication of claimMedications.activeList">-->
                                    <!--                                                <p class="preparation__text name" [title]="medication.medicationName">{{medication.medicationName}}</p>-->
                                    <!--                                                <p class="preparation__text">-->
                                    <!--                                                    <ng-container *ngIf="getStartDate(medication)">-->
                                    <!--                                                        ({{ getStartDate(medication)}})-->
                                    <!--                                                    </ng-container>-->
                                    <!--                                                </p>-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <div class="form-element preparations__col--flex"
                                         cdkDropList
                                         #activeList="cdkDropList"
                                         [cdkDropListData]="claimMedications.activeList"
                                         [cdkDropListConnectedTo]="[inactiveList]"
                                         (cdkDropListDropped)="dropSegments($event, claimMedications)">
                                        <div class="mat-form-field-wrapper">
                                            <div class="mat-input-element" style="border: none; padding: 0; background: transparent">
                                                <div class="mat-input-element preparation"
                                                     cdkDrag
                                                     *ngFor="let medication of claimMedications.activeList"
                                                     [ngClass]="isEditMode ? 'preparation_active' : 'preparation_disable' ">
                                                    <p class="preparation__text name" [title]="medication.medicationName">{{medication.medicationName}}</p>
                                                    <p class="preparation__text">
                                                        <ng-container *ngIf="getDate(medication)">
                                                            ({{ getDate(medication)}})
                                                        </ng-container>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>

                                <div  class="preparations__col preparations__col--row preparations__col--big preparations__status preparations__status--mobile">
                                    <div class="mat-form-field-wrapper">
                                        <div style="font-weight: bold; margin-bottom: .5em; margin-top: .5em">
                                            <ng-container *ngIf="claimMedications.activeList[0]">
                                                {{claimMedications.activeList[0].medicationName}}
                                            </ng-container>
                                            <ng-container *ngIf="claimMedications.inactiveList[0]">
                                                {{claimMedications.inactiveList[0].medicationName}}
                                            </ng-container>
                                        </div>
                                        <div style="display: flex; align-items: center; width: 100%;">
                                            <div
                                                    [class.mat-radio-checked]="!!claimMedications.inactiveList[0]"
                                                    class="mat-radio-button mat-accent ng-star-inserted" tabindex="-1">
                                                <label class="mat-radio-label" for="mat-radio-{{claimMedications.inactiveList[0] ? claimMedications.inactiveList[0].catalogItemId : claimMedications.activeList[0].catalogItemId}}-{{index}}-2-input">
                                                    <div class="mat-radio-container">
                                                        <input class="mat-radio-input cdk-visually-hidden" type="radio"
                                                               [disabled]="!isEditMode"
                                                               [value]="!!claimMedications.inactiveList[0]"
                                                               id="mat-radio-{{claimMedications.inactiveList[0] ? claimMedications.inactiveList[0].catalogItemId : claimMedications.activeList[0].catalogItemId}}-{{index}}-2-input"
                                                               tabindex="0" name="approximateDates_ongoing">
                                                    </div>
                                                    <div class="mat-radio-label-content" (click)="changeActiveMedication(claimMedications, false, $event)">
                                                        <span>Discontinued</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div
                                                    [class.mat-radio-checked]="!!claimMedications.activeList[0]"
                                                    style="margin-right: auto;"
                                                    class="mat-radio-button mat-accent ng-star-inserted" tabindex="-1">
                                                <label class="mat-radio-label" for="mat-radio-{{claimMedications.inactiveList[0] ? claimMedications.inactiveList[0].catalogItemId : claimMedications.activeList[0].catalogItemId}}-{{index}}-1-input">
                                                    <div class="mat-radio-container">
                                                        <input class="mat-radio-input cdk-visually-hidden" type="radio"
                                                               [disabled]="!isEditMode"
                                                               [value]="!!claimMedications.activeList[0]"
                                                               id="mat-radio-{{claimMedications.inactiveList[0] ? claimMedications.inactiveList[0].catalogItemId : claimMedications.activeList[0].catalogItemId}}-{{index}}-1-input"
                                                               tabindex="0"
                                                               name="satus">
                                                    </div>
                                                    <div class="mat-radio-label-content" (click)="changeActiveMedication(claimMedications, true, $event)">
                                                        <span>Active</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <p class="preparation__text" style="margin-left: 1em; margin-right: 1em">
                                                <ng-container *ngIf="getDate(claimMedications.inactiveList[0] ? claimMedications.inactiveList[0] : claimMedications.activeList[0])">
                                                    ({{ getDate(claimMedications.inactiveList[0] ? claimMedications.inactiveList[0] : claimMedications.activeList[0])}})
                                                </ng-container>
                                            </p>
                                        </div>


<!--                                        <div class="mat-input-element" style="border: none; padding: 0; background: transparent">-->
<!--                                            <div class="mat-input-element preparation"-->
<!--                                                 [ngClass]="isEditMode ? 'preparation_active' : 'preparation_disable' ">-->
<!--                                                <p class="preparation__text name" [title]="medication.medicationName">{{medication.medicationName}}</p>-->
<!--                                                <p class="preparation__text">-->
<!--                                                    <ng-container *ngIf="getEndDate(medication)">-->
<!--                                                        ({{ getEndDate(medication)}})-->
<!--                                                    </ng-container>-->
<!--                                                </p>-->
<!--                                            </div>-->
<!--                                        </div>-->
                                    </div>
                                </div>

                                <div class="preparations__col preparations__col--flex" [class.mobile-hidden]="!claimMedications.status">
                                    <div class="preparations__label preparations__label--mobile">Reason For Medications</div>
                                    <div class="form-element preparations__col--flex">
                                        <div class="mat-form-field-wrapper"
                                             [ngStyle]="{visibility: claimMedications.status ? 'visible' : 'hidden'}">
                                            <div class="mat-form-field-flex">
                                                <div class="mat-form-field-infix">
                                                    <mat-form-field>
                                                        <div class="mat-form-field-infix mat-form-field-infix--no-label">
                                                            <mat-form-field>
                                                                <input [(ngModel)]="claimMedications.reason"
                                                                       [ngModelOptions]="{standalone: true}"
                                                                       [disabled]="!isEditMode || (claimMedications.period && claimMedications.period.endDate)"
                                                                       maxlength="25"
                                                                       matInput>
                                                            </mat-form-field>
                                                        </div>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="preparations__col preparations__col--flex" [class.mobile-hidden]="!claimMedications.status">
                                    <div class="preparations__label preparations__label--mobile">Side Effects</div>
                                    <div class="form-element preparations__col--flex">
                                        <div class="mat-form-field-wrapper"
                                             [ngStyle]="{visibility: claimMedications.status ? 'visible' : 'hidden'}">
                                            <div class="mat-form-field-flex">
                                                <div class="mat-form-field-infix">
                                                    <mat-form-field>
                                                        <div class="mat-form-field-infix mat-form-field-infix--no-label">
                                                            <mat-form-field>
                                                                <input [(ngModel)]="claimMedications.sideEffects"
                                                                       [ngModelOptions]="{standalone: true}"
                                                                       [disabled]="!isEditMode || (claimMedications.period && claimMedications.period.endDate)"
                                                                       maxlength="25"
                                                                       matInput>
                                                            </mat-form-field>
                                                        </div>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </form>
        </li>
    </ul>
    <app-medical-treating-reports [sslgId]="sslgId"></app-medical-treating-reports>
</div>

