<div class="iris-table-tabs i-table">
    <nav mat-tab-nav-bar>
        <div class="mat-tab-links">
            <a [class.mat-tab-label-active]="link.id === activeId"
               [title]="link.title"
               (click)="changeActiveTab(link.id)"
               class="mat-tab-link"
               *ngFor="let link of tabs">
                <b>
                    {{link.title}}
                </b>
            </a>

            <button (click)="addMessage()" class="table-button table-button_add" [class.is-active]="isMessagingTab()">Add
                <svg class="table-button__icon" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                    <g>
                        <path d="M123.1,123.1V85.4c0-20.8,16.9-37.7,37.7-37.7h753.8C901.5,25.2,867.1,10,839.2,10H160.8c-41.6,0-75.4,33.8-75.4,75.4v829.2c0,41.6,33.7,75.4,75.4,75.4h37.7V160.8h-37.7C140,160.8,123.1,143.9,123.1,123.1z"/><path d="M500,160.8H236.2V990h603.1c41.6,0,75.4-33.8,75.4-75.4V160.8H763.8H500z M763.8,575.4H386.9v-37.7h376.9V575.4z M763.8,462.3H386.9v-37.7h376.9V462.3z M763.8,349.2H386.9v-37.7h376.9V349.2z"/>
                    </g>
                </svg>
            </button>
        </div>

        <div class="content form-fieldset" *ngIf="!isMessagingTab()">
            <div class="content__row content__row--header">
                <div class="content__col">question</div>
                <div class="content__col">answer</div>
            </div>
            <form action="">
                <div class="content__row" *ngFor="let item of content; trackBy: identifyQuestion">
                    <div class="content__col" [class.content__col--full]="isTextMessageType(item.fieldType)" [innerHTML]="item.questionTitle"></div>

<!--                    <div *ngIf="isTextType(item.type)" class="content__col" [innerHTML]="item.answer"></div>-->
<!--                    <div *ngIf="isDollarType(item.type)" class="content__col" [innerHTML]="item.answer | currency : 'USD' : 'symbol'"></div>-->
<!--                    <div *ngIf="isDateType(item.type)" class="content__col" [innerHTML]="item.answer | date : DATA_MASKS.date"></div>-->
<!--                    <div *ngIf="isPhoneType(item.type)" class="content__col" [innerHTML]="item.answer | mask : DATA_MASKS.phone"></div>-->

                    <div class="content__col" [class.content__col--flex]="item.fieldTitle" *ngIf="isNumberType(item.fieldType)">
                        <input type="text"
                               [mask]="getNumberMask(item.answerLength)"
                               [maxLength]="item.answerLength"
                               (change)="onInputChange($event, item)"
                               class="mat-input-element"
                               [name]="'number' + item.questionId"
                               [(ngModel)]="item.answer">
                        <div class="content__sufix" *ngIf="item.fieldTitle">
                            {{item.fieldTitle}}
                        </div>
                    </div>

                    <div class="content__col" *ngIf="isPhoneType(item.fieldType)">
                        <input type="tel"
                               mask="(000)000-0000"
                               [name]="'tel' + item.questionId"
                               [(ngModel)]="item.answer"
                               class="mat-input-element"
                               (change)="onInputChange($event, item)">
                    </div>

                    <div class="content__col" *ngIf="isDollarType(item.fieldType)">
                        <input type="text"
                               mask="separator.2"
                               thousandSeparator=","
                               class="mat-input-element"
                               prefix="$"
                               [name]="'dollar' + item.questionId"
                               [dropSpecialCharacters]="true"
                               [(ngModel)]="item.answer"
                               (change)="onInputChange($event, item)">
                    </div>

                    <div class="content__col" *ngIf="isTextType(item.fieldType)">
                        <input type="text"
                               [value]="item.answer ? item.answer : ''"
                               class="mat-input-element"
                               (change)="onInputChange($event, item)">
                    </div>

                    <div class="content__col" *ngIf="isTextareaType(item.fieldType)">
                        <textarea name=""
                                  id=""
                                  rows="3"
                                  class="mat-input-element"
                                  (change)="onInputChange($event, item)">{{item.answer}}</textarea>
                    </div>

                    <div class="content__col" *ngIf="isDateType(item.fieldType)">
                        <app-ng-datepicker
                                (datePick)="datePick($event, item)" (clearDate)="datePick(undefined, item)">

                            <input matInput #inp
                                   type="text"
                                   class="mat-input-element"
                                   disabled="true"
                                   [value]="item.answer"
                            />
                        </app-ng-datepicker>
                    </div>

                    <div class="content__col" *ngIf="isRadioType(item.fieldType)">
                        <mat-radio-group #matRadioGroup
                                         [value]="getSelectValue(item)"
                                         (change)="onRadioChange($event, item)">

                            <mat-radio-button *ngFor="let option of item.choices"
                                              [checked]="isRadioChecked(item, option)"
                                              [value]="option.value"><span [innerHTML]="option.label"></span></mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="content__col" *ngIf="isSelectType(item.fieldType)">
                        <mat-form-field appearance="fill">
                            <mat-select class="mat-input-element" (selectionChange)="onSelectChange($event, item)"
                                        [value]="getSelectValue(item)">
                                <mat-option *ngFor="let choice of item.choices" [value]="choice" [innerHTML]="choice.label">
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="content__col" *ngIf="isTextListType(item.fieldType)">
                        <div class="answer-list">
                            <div class="answer-list__item" *ngFor="let answer of item.answerList; trackBy: identifyAnswer">
                                <input #answerListItemInput
                                       [attr.data-question-id]="item.questionId"
                                       type="text"
                                       class="mat-input-element"
                                       [value]="answer.title"
                                       (change)="onAnswerListItemInputChange($event, item, answer)">
                                <button class="answer-list__btn close-btn"
                                         type="button" (click)="removeAnswerListItem(item, answer.id, answer.tempId)">
                                    <span></span>
                                    <span></span>
                                </button>
                            </div>
                            <button class="answer-list__btn answer-list__btn--single form-action"
                                    type="button"
                                    (click)="addAnswerListItem(item)"
                                    [disabled]="disableAddAnswerListItem(item.questionId)">Add</button>
                        </div>
                    </div>
                </div>
                <button *ngIf="content.length"
                        type="button"
                        (click)="saveChanges()"
                        class="content__save-btn form-action">
                    <svg class="save-action__icon">
                        <use [attr.xlink:href]="'../../../../assets/img/sprite.svg#save'"></use>
                    </svg>
                    Save
                </button>
            </form>
        </div>

        <div class="content table-content" *ngIf="isMessagingTab()">
            <div class="content__row content__row--header table-content__row">
                <div class="content__col table-content__col">Date</div>
                <div class="content__col table-content__col">Code</div>
                <div class="content__col table-content__col">Subject</div>
                <div class="content__col table-content__col">Description</div>
            </div>

            <div class="content__row table-content__row table-content__row--centered" *ngIf="!content || !content.length">
                No Messages
            </div>

            <div class="content__row table-content__row" *ngFor="let item of content; trackBy: identifyQuestion">
                <div class="content__col table-content__col">
                    {{item.created | date: DATA_MASKS.dateShortYear}}
                </div>

                <div class="content__col table-content__col">
                    {{item.code}}
                </div>

                <div class="content__col table-content__col">
                    {{item.subject}}
                </div>

                <div class="content__col table-content__col">
                    {{item.custom_text}}
                </div>
            </div>

        </div>

    </nav>
</div>

