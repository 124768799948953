import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
	DynamicFormControlLayout,
	DynamicFormControlModel,
	DynamicFormLayout, DynamicFormService
} from "@ng-dynamic-forms/core";
import {DATE_PICKER} from "../../../../../../../@util/dynamic-form/dynamic-controlls";
import {FormValueBuilder} from "../../../../../partial/forms/form-tamplate";
import {FormGroup} from "@angular/forms";
import {MedicalService} from "../../../../../../@core/medical.service";
import {DynamicForm} from "../../../../../../../@util/dynamic-form/dynamic-form";
import {DynamicFormGroupModel, DynamicTextAreaModel} from "../../../../../../../@util/ui-material/form-controls";
import {GlobalSaveService} from "../../../../../../@core/global-save.service";
import {UserInfoService} from "../../../../../../@core/user-info.service";

@Component({
	selector: 'app-hospitals-visit-notes',
	templateUrl: './hospitals-visit-notes.component.html',
	styleUrls: ['./hospitals-visit-notes.component.scss']
})
export class HospitalsVisitNotesComponent extends DynamicForm implements OnInit {
	frameTitle = 'Visit Notes';

	@Input()
	sslgId: any;

	@Output()
	backForm: EventEmitter<any> = new EventEmitter();
	@Output()
	nextForm: EventEmitter<any> = new EventEmitter();
	@Input() isAnotherVisit: boolean = false;


	formGroup: FormGroup;
	formModel: DynamicFormControlModel[];

	formLayout: DynamicFormLayout = {
		doctor: <DynamicFormControlLayout>{
			element: {
				host: 'col-12'
			}
		},
		hospital: <DynamicFormControlLayout>{
			element: {
				host: 'col-12',
				control: 'row',
				children: 'col-lg-6 col-12'
			}
		},
		other: <DynamicFormControlLayout>{
			element: {
				host: 'col-12',
				control: 'row'
			}
		},

		treatments: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},
		discussion: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},
		docResponse: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},

		hospitalizationReason: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},
		medicalConditions: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},
		treatmentDetails: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},
		treatedDoctor: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},

		visitReason3: <DynamicFormControlLayout>{
			element: {
				host: 'col-12',
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},
		treatmentDetails1: <DynamicFormControlLayout>{
			element: {
				host: 'col-12',
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},
		nextVisit1: <DynamicFormControlLayout>{
			element: {
				label: 'form-label',
				host: 'col-12 col-lg-4'
			}
		},

	};

	formDoctor = {
		treatments: DynamicTextAreaModel({
			id: 'treatments',
			label: 'What treatment did you receive on this visit?',
			maxLength: 512,editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		}),
		discussion: DynamicTextAreaModel({
			id: 'discussion',
			label: 'What did you discuss with your doctor?',
			maxLength: 512,editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		}),
		docResponse: DynamicTextAreaModel({id: 'docResponse', label: "Doctor's Response?", maxLength: 512,editableFor: ["EXECUTIVE_STAFF", "CLIENT"],}),
	};

	formHospital = {
		hospitalizationReason: DynamicTextAreaModel({
			id: 'hospitalizationReason',
			label: 'Reason for Hospitalization',
			maxLength: 512,editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		}),
		medicalConditions: DynamicTextAreaModel({
			id: 'medicalConditions',
			label: 'Medical Conditions or Diagnoses',
			maxLength: 512,editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		}),
		treatmentDetails: DynamicTextAreaModel({
			id: 'treatmentDetails',
			label: 'Details of Treatment',
			maxLength: 512,editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		}),
		treatedDoctor: DynamicTextAreaModel({id: 'treatedDoctor', label: 'If you know the names of the doctors who treated you, list them here', maxLength: 512,editableFor: ["EXECUTIVE_STAFF", "CLIENT"],}),
	};

	formOther = {
		visitReason: DynamicTextAreaModel({id: 'visitReason3', label: 'Reason for Visit', maxLength: 512,editableFor: ["EXECUTIVE_STAFF", "CLIENT"],}),
		treatmentDetails: DynamicTextAreaModel({
			id: 'treatmentDetails1',
			label: 'Details of Treatment',
			maxLength: 512,editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		}),
		nextVisit: DATE_PICKER({id: 'nextVisit1', label: 'Next Visit',editableFor: ["EXECUTIVE_STAFF", "CLIENT"],}),
	};

	form = {
		hospital: DynamicFormGroupModel({
			id: 'hospital',
			group: FormValueBuilder.buildFormModel(this.formHospital),editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
		})
	};

	constructor(private medicalService: MedicalService,
							public globalSaveService: GlobalSaveService,
							private userInfoService: UserInfoService,
							private formService: DynamicFormService) {
		super();
		this.formModel = FormValueBuilder.buildFormModel(this.form);
		this.formGroup = formService.createFormGroup(this.formModel);
	}

	ngOnInit() {
		FormValueBuilder.resetToDefault(this.form);
		this.formGroup.valueChanges.subscribe(j => {
			this.formStateChanged.emit({key: this.formKey, value: FormValueBuilder.isDirty(this.form)});
		})
	}

	saveVisitNotes() {
		const rez = FormValueBuilder.getData(this.formHospital);
		// await this.medicalService.saveStepTwo(this.sslgId, this._visitId, rez).toPromise();

		console.log(rez);
		return rez;
	}

	ngOnDestroy(): void {
		this.formStateChanged.next({value: false, key: this.formKey});
	}

	isClaimant() {
		if(this.userInfoService.getCurrentUserRole() == 'CLIENT'){
			return true;
		}

		return false;
	}

	nextHospitalsVisitNotes() {
		this.nextForm.emit(this.saveVisitNotes());
	}

	backHospitalsVisitNotes() {
		this.backForm.emit(this.saveVisitNotes());
	}

}
