import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {REFERRAL_CONFIG} from '../@config/api';
import {map} from 'rxjs/operators';
import {AdvisorsService} from './advisors.service';
import {ClientsService} from './clients.service';
import {User} from '../@models/system-managment/user';
import {AdjustersService} from './adjusters.service';
import {Acquisition} from '../@models/referral/acquisition';
import {Summary} from '../@models/referral/summary';
import {Claimant} from '../@models/system-managment/claimant';
import {Note} from '../@models/notes/note';
import {FollowUp} from '../@models/follow-up/follow-up';
import {CodeItem} from '../@models/follow-up/code-item';
import {SystemUsersService} from "./system-users.service";
import {StatusService} from "./status.service";
import {Subject} from "rxjs";
import {UserInfoService} from "./user-info.service";
import {DateService} from "./functions/date.service";

@Injectable({
	providedIn: 'root'
})
export class ReferralService {
	public current: Claimant;
	private claim = new Subject<Claimant>();
	updateClaimInfo = new Subject<number>();
	isClaimHeaderAttention = new Subject<any>();
	private config = REFERRAL_CONFIG;

	constructor(
			private http: HttpClient,
			private adjusterService: AdjustersService,
			private systemUserService: SystemUsersService,
			private advisorService: AdvisorsService,
			private clientService: ClientsService,
			private statusService: StatusService,
			private dateService: DateService,
			private userInfoService: UserInfoService) {
	}

	getList(type) {
		return this.http.post<any>(this.config.CLAIMANT.getList, {
			userName: this.getUserName(),
			type: type
		});

		//let param = new HttpParams();
		//return this.http.get<Claimant[]>(this.config.CLAIMANT.getClaimants, {params: param});
	}

	getAll(archived?) {
		let param = new HttpParams();
		if (archived != undefined) {
			param = param.append('archived', archived);
		}
		return this.http.get<Claimant[]>(this.config.CLAIMANT.getClaimants, {params: param});
	}

	getClaimantLevelDetails(sslgId: string) {
		return this.http.get<Claimant>(this.config.CLAIMANT.getClaimantLevelDetails(sslgId));
	}

	getById(sslgId) {
		return this.http.get<any>(this.config.REFERRAL.getById(sslgId));
	}

	addNew(val: Acquisition) {
		return this.http.post<any>(this.config.CLAIMANT.addClaimant, val);
	}

	setAcquisition(sslgId, val: Acquisition) {
		return this.http.post(this.config.REFERRAL.setAcquisition(sslgId), val);
	}

	// setSummary(sslgId, val: Summary) { //@deprecated
	// 	return this.http.post(this.config.REFERRAL.setSummary(sslgId), val);
	// }

	getClients() {
		return this.clientService.getUsersList().pipe(map((data: User[]) => {
			return data.map(j => {
				let name = 'Fill Client Name';
				if (j.clientBasic && j.clientBasic.firstName && j.clientBasic.lastName) {
					name = j.clientBasic.firstName + ' ' + j.clientBasic.lastName;
				}
				return {
					value: j.username,
					label: name
				};
			});
		}));
	}

	async getReferrals() {
		const rez = await this.advisorService.getUsersList().pipe(map((data: User[]) => this.mapUser(data))).toPromise();
		const managers = await this.getManagers().toPromise();
		rez.push(...managers);
		return rez.sort((a, b) => {
			if (a.label < b.label) { return -1; }
			if (a.label > b.label) { return 1; }
			return 0;
		});
	}

	getManagers() {
		return this.adjusterService.getUsersList().pipe(map((data: User[]) => this.mapUser(data).sort((a, b) => {
			if (a.label < b.label) { return -1; }
			if (a.label > b.label) { return 1; }
			return 0;
		})));
	}

	private mapUser(data: User[]) {
		return data.map(j => {
			return {
				value: j.username,
				label: !(j.personal && j.personal.firstName && j.personal.lastName) ?
					'Fill Name' : j.personal.firstName + ' ' + j.personal.lastName
			};
		});
	}

	private mapUserStaff(data: User[]) {
		return data.map(j => {
			let thisLabel = '';
			if (!(j.personal && j.personal.firstName && j.personal.lastName)) {
				thisLabel = 'Fill Name';
			} else {
				thisLabel = j.personal.firstName + ' ' + j.personal.lastName;
			}
			if (j.personal && j.personal.title) {
				thisLabel = thisLabel + ', ' + j.personal.title;

			} else {
				thisLabel = thisLabel + ' ';
			}
			if (!j.contact || !j.contact.email) {
				thisLabel = thisLabel + '';
			} else {
				thisLabel = thisLabel + ' ~ ' + j.contact.email;
			}
			return {
				value: j.username,
				label: thisLabel
			};
		});
	}

	getCurrent() {
		return this.claim;
	}

	setCurrent(param: Claimant) {
		this.current = param;
		this.claim.next(param);
	}

	getNotes(sslgId: string, type: string | null) {
		const url = this.config.NOTES.getAll(sslgId, type);
		return this.http.get<Note[]>(url);
	}

	getNoteById(sslgId, id) {
		return this.http.get<Note>(this.config.NOTES.getById(sslgId, id));
	}

	saveNote(sslgId, data) {
		return this.http.post<Note>(this.config.NOTES.save(sslgId), data);
	}

	addFollowUp(sslgId: any, data: any) {
		return this.http.post<FollowUp>(this.config.FOLLOW_UPS.addPending(sslgId), data);
	}

	getFollowUpsPending(sslgId: any) {
		return this.http.get<FollowUp[]>(this.config.FOLLOW_UPS.getPending(sslgId));
	}

	getStatusAndFollowUpsPending(sslgId: any) {
		let userName = this.getUserName();

		return this.http.get<FollowUp[]>(this.config.FOLLOW_UPS.getPendingAndStatus(sslgId,userName));
	}

	setFollowUpCompleted(data: FollowUp) {
		return this.http.post<CodeItem>(this.config.FOLLOW_UPS.setCompleted(data.sslgId), data);
	}

	getFollowUpsCompleted(sslgId: any) {
		return this.http.get<FollowUp[]>(this.config.FOLLOW_UPS.getCompleted(sslgId));
	}

	getExecutiveStaff() {
		return this.systemUserService.getUsersList(null).pipe(map((data: User[]) => this.mapUser(data)));
	}

	getExecutiveStaffUsers() {
		return this.systemUserService.getUsersList(null)//.pipe(map((data: User[]) => this.mapUserStaff(data)));
	}

	getFollowUp(sslgId, followUpId) {
		return this.http.get<FollowUp>(this.config.FOLLOW_UPS.getById(sslgId, followUpId));
	}

	createClaimant(data: any) {
		return this.http.post<any>(this.config.CLAIMANT.create, data);
	}

	getNewSslgId(referredBy: any) {
		return this.http.get(this.config.CLAIMANT.getNewSslgId(referredBy), {responseType: 'text'});
	}

	saveSSARules(sslgId, data: any) {
		return this.http.post<any>(this.config.REFERRAL.SSARules(sslgId), data);
	}

	saveSSLGStaff(sslgId, data: any) {
		return this.http.post<any>(this.config.REFERRAL.SSLGStaff(sslgId), data);
	}

	saveDisablingConditions(sslgId, disCond: any, disCondClaim: any) {
		return this.http.post<any>(this.config.REFERRAL.DisablingConditions(sslgId), {
			disablingConditions: disCond,
			disablingConditionsClaimant: disCondClaim
		});
	}

	saveIncome(sslgId, data: any) {
		return this.http.post<any>(this.config.REFERRAL.Income(sslgId), data);
	}

	getSSARules(sslgId) {
		return this.http.get<any>(this.config.REFERRAL.SSARules(sslgId));
	}

	getSSLGStaff(sslgId) {
		return this.http.get<any>(this.config.REFERRAL.SSLGStaff(sslgId));
	}

	getSSLGStaffForUser(sslgId) {
		return this.http.get<any>(this.config.REFERRAL.SSLGStaffForUser(sslgId,this.getUserName()));
	}

	getDisablingConditions(sslgId) {
		return this.http.get<any>(this.config.REFERRAL.DisablingConditions(sslgId));
	}

	getIncome(sslgId) {
		return this.http.get<any>(this.config.REFERRAL.Income(sslgId));
	}

	getPeriod(param?: { startDate?: String, endDate?: String }) {
		let req = param;
		if (!req) {
			req = {startDate: null, endDate: null};
		}

		req.startDate = this.dateService.getDateWithoutHours(req.startDate);
		req.endDate = this.dateService.getDateWithoutHours(req.endDate);

		return this.http.post<any>('/api/service/date-time/period', req);
	}

	getPolicyholderCodes(sslgId: string) {
		return this.http.get<any>(this.config.CLAIMANT.policyHolderCodes(sslgId));
	}

	saveDependents(sslgId: string, data: any) {
		return this.http.post<any>(this.config.REFERRAL.Dependents(sslgId), data);
	}

	getDependents(sslgId: string) {
		return this.http.get<any>(this.config.REFERRAL.Dependents(sslgId));
	}

	getClaimantFlag(sslgId: string) {
		return this.http.get<any>(this.config.REFERRAL.ClaimantFlag(sslgId));
	}

	setClaimantFlag(sslgId: string, data: any) {
		return this.http.post<any>(this.config.REFERRAL.ClaimantFlag(sslgId), data);
	}

	getClaimantInfo(sslgId) {
		return this.http.get<any>(this.config.CLAIMANT.getInfo(sslgId));
	}

	getClaimantPdfInfo(sslgId) {
		return this.http.get<any>(this.config.CLAIMANT.getInfoForPdf(sslgId));
	}

	getFollowUpsCodes(sslgId) {
		return this.http.get<any>(this.config.FOLLOW_UPS.getCodes(sslgId));
	}

	setClaimantFlagIncome(sslgId: string, data: any) {
		return this.http.post<any>(this.config.REFERRAL.setClaimantFlagIncome(sslgId), data);
	}

	setClaimantOtherIncome(sslgId: string, data: any) {
		return this.http.post<any>(this.config.REFERRAL.setClaimantOtherIncome(sslgId), data);
	}

	getClaimantFlagIncome(sslgId: string) {
		return this.http.get<any>(this.config.REFERRAL.getClaimantFlagIncome(sslgId));
	}

	getClaimantOtherIncome(sslgId: string) {
		return this.http.get<any>(this.config.REFERRAL.getClaimantOtherIncome(sslgId));
	}

	getUserName(){
		return this.userInfoService.getCurrentUserName();
	}

	updateClaimHeaderAttention(sslgId) {
		this.getClaimantLevelDetails(sslgId).subscribe(val => {
			this.isClaimHeaderAttention.next(val.attention);
		});
	}
}
