export const PROMOTIONS_TYPES = {
  active: 1,
  inactive: 2,
  blocked: 3,
  archived: 4,
  accepted: 100
};

export const PROMOTIONS_CHAT_TYPES = {
  initial: 'initial',
  appeal: 'appeal',
  application: 'application',
};
